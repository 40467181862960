import React from 'react';

import { Circle, StyledCard as Card, Milestone } from './styles';

export default ({ right, children, background, ...props }) => (
  <Milestone rightMD={right} width3of3 marginTop marginBottom padding>
    <Circle background={background} {...props} />
    <Card widthXS9of11 widthLG5of11 rightCard={right} background={background} {...props}>
      {children}
    </Card>
  </Milestone>
);
