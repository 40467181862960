import {
  SUBMIT_RECEIVED,
  SUBMIT_REQUEST_FAILED,
  SUBMIT_REQUEST_FINISHED,
  SUBMIT_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  ok: false,
  error: null
};

export const submitFormRequested = state => ({ ...state, querying: true });

export const submitFormReceived = (state, action) => ({
  ...state,
  ok: action.payload.ok,
  error: null
});

export const submitFormRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  ok: false
});

export const submitFormRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [SUBMIT_REQUESTED]: submitFormRequested,
  [SUBMIT_RECEIVED]: submitFormReceived,
  [SUBMIT_REQUEST_FAILED]: submitFormRequestFailed,
  [SUBMIT_REQUEST_FINISHED]: submitFormRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
