import styled from 'styled-components';

export default styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  min-width: 150px;
  justify-content: space-between;

  a {
    margin: 0 ${({ theme }) => theme.sizeSmall};
    text-decoration: none;
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  div:first-of-type a {
    &:first-child {
      margin-right: -${({ theme }) => theme.sizeSmall};
      i:last-child {
        margin-left: calc(-${({ theme }) => theme.size} - 3px);
      }
    }
  }

  div:last-of-type a {
    &:last-child {
      margin-left: -${({ theme }) => theme.sizeSmall};
      i:first-child {
        margin-right: calc(-${({ theme }) => theme.size} - 3px);
      }
    }
  }

  i {
    font-size: calc(${({ theme }) => theme.size} + 3px);
  }

  span {
    margin-top: -${({ theme }) => theme.lineThick};
  }
`;
