import styled from 'styled-components';
import { Button, Block } from 'lucentum';
import { DetailedNews } from '../../components/';
export { ModalStyled } from '../events/styles';

export const StyledDetailedNews = styled(DetailedNews)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    margin-top: ${({ theme }) => theme.size};

    & > div span {
      margin-bottom: ${({ theme }) => theme.sizeSmall};
    }
  }
`;

export const BlockStyled = styled(Block)`
  margin: auto ${({ theme }) => theme.sizeSmaller};
`;

export const ButtonStyles = styled(Button)`
  @media (${({ theme }) => theme.breakpointMaxXS}) {
    width: 145px;
    height: 39px;
    margin-bottom: 20px;
  }
  width: 95%;
  height: 39px;
  margin-bottom: 20px;
`;

export const ButtonStyled = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizeSmaller};
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizeMedium};
`;
