import styled from 'styled-components';
import { P as Paragraph, Block as BlockLucentum } from 'lucentum';
import { them } from '../../commonStyles';

export const P = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.sizeSmall};
`;

export const Span = styled.span`
  margin-top: ${({ theme }) => theme.sizeSmall};
  color: ${({ theme }) => theme.colorGrayLight};
`;

export const DetailedNewsContainer = styled(BlockLucentum)`
  @media (${them('breakpointMaxMD')}) {
    padding: 0;
  }
`;
