import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Page from '../../components/page';
import EcpsComponent from '../../components/ecps';
import {
  Ecp as EcpPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { FormattedMessage } from 'react-intl';
import { Content, Button, Dropdown } from 'lucentum';

export default class Ecps extends Component {
  render = () => {
    const {
      ecps,
      active,
      cookieState,
      changeCookie,
      pinEcp,
      isUserLoged
    } = this.props;
    return (
      <Page active={active} className="page--ecps">
        <Content>
          <Breadcrumbs
            src={[
              {
                text: <FormattedMessage id="portlet.section.home" />,
                url: '#/'
              },
              {
                text: <FormattedMessage id="portlet.section.ecps" />,
                url: '#/ecps'
              }
            ]}
          />
        </Content>

        <EcpsComponent
          ecps={ecps.ecps}
          querying={ecps.querying}
          error={ecps.error}
          cookieState={cookieState}
          createCookie={changeCookie}
          pinEcp={pinEcp}
          isUserLoged={isUserLoged}
        />
      </Page>
    );
  };

  componentDidMount = () => {
    this.props.getEcps();
    this.props.createCookie();
  };

  static propTypes = {
    ecps: ReducerPropType({
      ecps: PropTypes.arrayOf(EcpPropType)
    }).isRequired,
    active: PropTypes.string.isRequired
  };
}
