import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Block, Content, Spinner } from 'lucentum';
import { shuffle, order } from '../../utils/functions';

import {
  Event as EventPropType,
  News as NewsPropType,
  Member as MemberPropType,
  Course as CoursePropType,
  Reducer as ReducerPropType,
  Tool as ToolPropType,
  Ecp as EcpPropType,
  Practice as PracticePropType
} from '../../propTypes';
import {
  EventsWidget,
  NewsWidget,
  Page,
  Newsletter,
  MapComponent,
  ToolsWidget,
  // EcpsWidget,
  PracticesWidget,
  Courses,
  HeroCarousel,
  HeroCarouselMobile
} from '../../components';
import {
  IconStyled,
  NavLink,
  NavSpan,
  Navigation,
  WidgetsContainer,
  CoursesContainer
} from './styles';

export const NavigationLink = ({ page }) => (
  <NavLink className="responsive-menu" href={`#/${page}`}>
    <NavSpan>
      <FormattedMessage id={`portlet.section.${page}`} />
    </NavSpan>
    <IconStyled chevronRight />
  </NavLink>
);

export const NavLinks = () => (
  <Navigation>
    {/*<NavigationLink page="ecps" />*/}
    <NavigationLink page="tools" />
    <NavigationLink page="practices" />
    <NavigationLink page="news" />
    <NavigationLink page="events" />
  </Navigation>
);

export default class extends Component {
  render = () => {
    const {
      heroCarousel,
      featuredEvents,
      featuredNews,
      featuredTools,
      // featuredEcps,
      featuredNewsletter,
      members,
      active,
      featuredCourses,
      featuredPractices,
      pinTool,
      pinPractice,
      pinEcp,
      subscribedNewsletter,
      handleSubscribeNewsletter,
      userQuerying,
      isUserLoged,
      gettingHomeData,
      showLastNewsletter
    } = this.props;
    // console.log('props', this.props)
    return (
      <Page active={active} className="page--home">
        {gettingHomeData ? (
          <Block center>
            <Spinner show row size={10} />
          </Block>
        ) : (
          <Block>
            {/* {hero && (
              <Block>
                <Block hiddenXS visibleLG>
                  <Link to={hero.url}>
                    <Hero
                      padding
                      img={hero.imageUrl}
                      imgMobile={hero.imageMobileUrl}
                    >
                      <Content>
                        <Block>
                          <H2>{hero.title}</H2>
                        </Block>
                      </Content>
                    </Hero>
                  </Link>
                </Block>

                <Block hiddenLG>
                  <Hero
                    padding
                    img={hero.imageUrl}
                    imgMobile={hero.imageMobileUrl}
                  >
                    <Content>
                      <Block>
                        <H2>{hero.title}</H2>
                      </Block>
                    </Content>
                    <Block className="hero__show-members-link" hiddenLG>
                      <Link to="#/members" hiddenLG>
                        <FormattedMessage id="portlet.hero.more" />
                      </Link>
                    </Block>
                  </Hero>
                </Block>
              </Block>
            )} */}
            {heroCarousel && (
              <Content>
                <Block hiddenXS visibleLG>
                    <HeroCarousel
                      sliderData={heroCarousel}
                    />                   
                </Block>

                <Block hiddenLG>
                  <HeroCarouselMobile
                    sliderData={heroCarousel}
                  />    
                </Block>
              </Content>
            )}
            <Content>
              {/*<WidgetsContainer>*/}
                {/*<EcpsWidget*/}
                  {/*featuredEcps={featuredEcps.featuredEcps}*/}
                  {/*querying={featuredEcps.querying}*/}
                  {/*error={featuredEcps.error}*/}
                  {/*pinEcp={pinEcp}*/}
                  {/*isUserLoged={isUserLoged}*/}
                {/*/>*/}
              {/*</WidgetsContainer>*/}
              <WidgetsContainer>
                <ToolsWidget
                  featuredTools={featuredTools.featuredTools}
                  querying={featuredTools.querying}
                  error={featuredTools.error}
                  pinTool={pinTool}
                  isUserLoged={isUserLoged}
                />
              </WidgetsContainer>
              <WidgetsContainer>
                <PracticesWidget
                  featuredPractices={featuredPractices.featuredPractices}
                  querying={featuredPractices.querying}
                  error={featuredPractices.error}
                  pinPractice={pinPractice}
                  isUserLoged={isUserLoged}
                />
              </WidgetsContainer>
              <WidgetsContainer>
                <NewsWidget
                  news={featuredNews.featuredNews}
                  querying={featuredNews.querying}
                  error={featuredNews.error}
                />
                {/* <Block right marginTop={2}>
                  <Newsletter
                    featuredNewsletter={featuredNewsletter.featuredNewsletter}
                    querying={userQuerying}
                    subscribedNewsletter={subscribedNewsletter}
                    handleSubscribeNewsletter={handleSubscribeNewsletter}
                    showLastNewsletter={showLastNewsletter}
                  />
                </Block> */}
              </WidgetsContainer>
            </Content>
            <Content>
              <CoursesContainer>
                <Courses
                  featuredCourses={featuredCourses.featuredCourses}
                  coursesURL={featuredCourses.coursesURL}
                  querying={featuredCourses.querying}
                  error={featuredCourses.error}
                />
              </CoursesContainer>
              <WidgetsContainer width1of3>
                <EventsWidget
                  events={featuredEvents.featuredEvents}
                  querying={featuredEvents.querying}
                  error={featuredEvents.error}
                />
              </WidgetsContainer>
            </Content>
            <Content>
              <MapComponent
                members={members.members}
                querying={members.querying}
                error={members.error}
              />
            </Content>
            <Content>
              <Block hiddenLG>
                <NavLinks />
              </Block>
            </Content>
          </Block>
        )}
      </Page>
    );
  };

  componentDidMount = () => this.props.getHomeData();

  static propTypes = {
    featuredEvents: ReducerPropType({
      featuredEvents: PropTypes.arrayOf(EventPropType)
    }).isRequired,
    featuredNews: ReducerPropType({
      featuredNews: PropTypes.arrayOf(NewsPropType)
    }).isRequired,
    members: ReducerPropType({
      members: MemberPropType
    }).isRequired,
    featuredCourses: ReducerPropType({
      featuredCourses: PropTypes.arrayOf(CoursePropType)
    }).isRequired,
    featuredTools: ReducerPropType({
      featuredTools: PropTypes.arrayOf(ToolPropType)
    }).isRequired,
    // featuredEcps: ReducerPropType({
    //   featuredEcps: PropTypes.arrayOf(EcpPropType)
    // }).isRequired,
    featuredPractices: ReducerPropType({
      featuredPractices: PropTypes.arrayOf(PracticePropType)
    }).isRequired,
    active: PropTypes.string.isRequired,
    pinTool: PropTypes.func.isRequired,
    // pinEcp: PropTypes.func.isRequired,
    pinPractice: PropTypes.func.isRequired,
    subscribedNewsletter: ReducerPropType({
      subscribedNewsletter: PropTypes.bool
    }).isRequired,
    handleSubscribeNewsletter: PropTypes.func.isRequired
  };
}
