import styled from 'styled-components';
import { Icon, Link, Navigation as navigation, Block } from 'lucentum';

import { them } from '../../commonStyles';
import { Hero as HeroCG } from '../../components';

export const Hero = styled(HeroCG)`
  & > div:last-child {
    z-index: 5;
  }

  h2 {
    color: ${them('colorBlack')};
  }

  @media (${them('breakpointMaxMD')}) {
    text-align: center;
    h2 {
      color: ${them('colorWhite')};
      font-size: ${them('fontSizeMedium')};
      width: 70%;
      margin: auto;
    }
    .hero__show-members-link {
      margin-top: 1em;
    }
    a {
      color: ${them('colorWhite')};
      font-size: ${them('fontSizeSmaller')};
      text-decoration: underline;
    }
  }

  @media (${them('breakpointMaxXS')}) {
    text-align: left;
    h2 {
      color: ${them('colorWhite')};
      font-size: ${them('fontSizeMedium')};
      width: 70%;
      margin: auto 0;
    }
    .hero__show-members-link {
      text-align: right;
    }
    a {
      color: ${them('colorWhite')};
      font-size: ${them('fontSizeSmaller')};
      text-decoration: underline;
    }
  }
`;

export const IconStyled = styled(Icon)`
  font-size: 1.25rem;
`;
export const NavLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizeLarge};
  border-bottom: 1px solid #bebebe;
  color: ${({ theme }) => theme.colorMenuGray};
  height: 3.75rem;
  width: calc(100% - 1rem);
  &:hover,
  &:active {
    text-decoration: none;
  }
  &.responsive-menu {
    padding: 0;
    border-bottom: 1px solid #bebebe;
    font-weight: unset;
  }
`;

export const NavSpan = styled.span`
  text-align: initial;
  flex: 1 1;
  margin-left: 0.625rem;
  display: flex;
  justify-content: space-between;
`;

export const Navigation = styled(navigation)`
  flex-direction: column;
`;

export const WidgetsContainer = styled(Block).attrs({
  visibleLG: true,
  hiddenXS: true
})`
  float: none;
  margin: 0;
  margin-top: 1.5em;
  margin-right: 3em;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const CoursesContainer = styled(Block).attrs({
  visibleLG: true,
  hiddenXS: true
})`
  float: none;
  margin: 0;
  margin-top: 1.5em;
  margin-right: 3em;
  width: calc(66% + 3em);

  &:last-of-type {
    margin-right: 0;
  }
`;
