import styled from 'styled-components';
import { Block, H2, Button as ButtonLucentum } from 'lucentum';
import { them } from '../../commonStyles';

export const BlockResponsive = styled(Block)`
  @media (${them('breakpointMaxMD')}) {
    margin: 0;
    width: 100%;
    padding: ${({ theme }) => theme.size};
  }
`;

export const BlockIcon = styled(Block)`
  @media (${them('breakpointMaxMD')}) {
    text-align: center;
  }
`;

export const BlockLogo = styled(Block)`
  @media (${them('breakpointMaxXS')}) {
    max-width: 100%;
    height: auto;
    text-align: center;
    padding: 0;
    width: auto;
  }
  @media (${them('breakpointXL')}) {
    max-width: 100%;
    height: auto;
    text-align: center;
    padding: 0;
    width: auto;
  }
  img {
    width: 100%;
    height: auto;
  }
`;

export const BlockTimeline = styled(Block)`
  @media (${them('breakpointMaxMD')}) {
    margin-top: ${them('sizeDouble')};
    margin-bottom: ${them('sizeDouble')};
  }
  > div:first-child {
    align-items: flex-end;
    margin: 0 ${them('size')};
    > h1 {
      line-height: 1;
    }
  }
`;

export const Title = styled(H2)`
  font-weight: bold;
  margin: ${({ theme }) => theme.sizeSmall} auto ${({ theme }) => theme.size}
    auto;
  @media (${them('breakpointMaxMD')}) {
    font-size: ${({ theme }) => theme.fontSizeMedium};
    margin: 0;
  }
`;

export const Button = styled(ButtonLucentum)`
  &.office--contact--button {
    text-align: left;
    word-break: break-word;
  }
`;
