import {
  FEATURED_NEWS_REQUEST_SUCCEED,
  FEATURED_NEWS_REQUEST_FAILED,
  FEATURED_NEWS_REQUEST_FINISHED,
  FEATURED_NEWS_REQUEST_STARTED
} from './types';
import api from '../../utils/api';

export const getFeaturedNewsRequested = () => ({
  type: FEATURED_NEWS_REQUEST_STARTED,
  payload: null
});

export const getFeaturedNewsSucceed = featuredNews => ({
  type: FEATURED_NEWS_REQUEST_SUCCEED,
  payload: { featuredNews }
});

export const getFeaturedNewsFailed = er => ({
  type: FEATURED_NEWS_REQUEST_FAILED,
  payload: { error: er }
});

export const getFeaturedNewsFinished = () => ({
  type: FEATURED_NEWS_REQUEST_FINISHED,
  payload: null
});

export const getFeaturedNews = () => async dispatch => {
  dispatch(getFeaturedNewsRequested());
  try {
    const { data: news } = await api.getFeaturedNews();
    dispatch(getFeaturedNewsSucceed(news));
  } catch (error) {
    dispatch(getFeaturedNewsFailed(error.response.data));
  } finally {
    dispatch(getFeaturedNewsFinished());
  }
};
