import styled from 'styled-components';
import { ErrorMessage as ErrorMess } from '../textField/styles';
import { inputMixin } from '../textField/styles';

export const TextAreaStyled = styled.div`
  align-items: center;
  display: flex;
  flex: 1 auto;
  position: relative;
  & > textarea {
    ${props => inputMixin(props)}
  }
`;

export const ErrorMessage = styled(ErrorMess)`
  margin-left: 20px;
  max-width: 320px;
  border-radius: 2px;
  display: ${props => (props.showError ? 'inline-block' : 'none')};
  textarea:not(:focus):invalid + & {
    display: inline-block;
  }
`;
