import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import {
  PIN_PRACTICE_FAILED,
  PIN_PRACTICE_FINISHED,
  PIN_PRACTICE_RECEIVED,
  PIN_PRACTICE_REQUESTED,
  PRACTICE_RECEIVED,
  PRACTICE_REQUEST_FAILED,
  PRACTICE_REQUEST_FINISHED,
  PRACTICE_REQUESTED
} from './types';
import api from '../../utils/api';
import { pinPractice as pinPracticeAtPracticesReducer } from '../practices/action-creators';
import { pinPractice as pinPracticeAtFeaturedPracticesReducer } from '../featuredPractices/action-creators';
import { buildMilestone } from '../shared';

const buildPeriod = year => ({ type: 'Period', label: year });

const buildYears = implementations =>
  implementations.reduce((prev, item) => {
    const year = moment(item.date).year();
    const period = buildPeriod(year);
    if (!prev.length) {
      prev = [period];
    } else if (prev[prev.length - 1].label > year) {
      prev = [...prev, period];
    }
    return prev;
  }, []);

const buildApprovalDateItem = dateApproved => ({
  title: <FormattedMessage id="portlet.action.timelines.practice_approved" />,
  date: dateApproved,
  dates: moment(dateApproved).format('DD/MM/YYYY'),
  projectId: 1
});

const buildTimeline = implementations =>
  buildYears(implementations).reduce((prev, item) => {
    const year = item.label;
    const filteredImplementations = implementations.filter(
      implementation => moment(implementation.date).year() === year
    );
    const milestones = filteredImplementations.map(item =>
      buildMilestone(item)
    );
    return [...prev, ...milestones, item];
  }, []);

export const parsePracticeImplementations = practice => {
  const approvalDateItem = buildApprovalDateItem(practice.dateApproved);
  const parsedImplementations = practice.dateApproved > 0 ? [...practice.implementations, approvalDateItem] : [...practice.implementations];

  const sortedImplementations = parsedImplementations
    .map((item, index) => ({ item, index }))
    .sort((a, b) =>
      b.item.date - a.item.date === 0
        ? a.index - b.index
        : b.item.date - a.item.date
    )
    .map(({ item }) => item);

  return buildTimeline(sortedImplementations);
};

export const getPracticeRequested = () => ({
  type: PRACTICE_REQUESTED,
  payload: null
});

export const getPracticeSucceed = practice => ({
  type: PRACTICE_RECEIVED,
  payload: { practice }
});

export const getPracticeFailed = er => ({
  type: PRACTICE_REQUEST_FAILED,
  payload: { error: er }
});

export const getPracticeFinished = () => ({
  type: PRACTICE_REQUEST_FINISHED,
  payload: null
});

export const pinPracticeRequested = () => ({
  type: PIN_PRACTICE_REQUESTED,
  payload: null
});
export const pinPracticeSucceed = () => ({
  type: PIN_PRACTICE_RECEIVED,
  payload: null
});
export const pinPracticeFailed = er => ({
  type: PIN_PRACTICE_FAILED,
  payload: { error: er }
});
export const pinPracticeFinished = () => ({
  type: PIN_PRACTICE_FINISHED,
  payload: null
});

export const getPractice = practiceId => async dispatch => {
  dispatch(getPracticeRequested());
  try {
    const { data: practice } = await api.getPractice(practiceId);
    practice.timeline = Array.isArray(practice.implementations)
      ? parsePracticeImplementations(practice)
      : [];
    dispatch(getPracticeSucceed(practice));
  } catch (error) {
    dispatch(getPracticeFailed(error.response.data));
  } finally {
    dispatch(getPracticeFinished());
  }
};

export const pinPractice = practiceId => async dispatch => {
  dispatch(pinPracticeRequested());
  try {
    await api.pinPractice(practiceId);
    dispatch(pinPracticeSucceed());
    dispatch(pinPracticeAtPracticesReducer(practiceId));
    dispatch(pinPracticeAtFeaturedPracticesReducer(practiceId));
  } catch (error) {
    dispatch(pinPracticeFailed(error.response.data));
  } finally {
    dispatch(pinPracticeFinished());
  }
};
