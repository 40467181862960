import {
  AUTH_TOKEN_FAILED,
  AUTH_TOKEN_FINISHED,
  AUTH_TOKEN_RECEIVED,
  AUTH_TOKEN_REQUESTED
} from './types';

export const INITIAL_STATE = {
  querying: false,
  authToken: null,
  error: null
};

const reducer = {
  [AUTH_TOKEN_REQUESTED]: state => ({ ...state, querying: true }),
  [AUTH_TOKEN_RECEIVED]: (state, action) => ({
    ...state,
    authToken: action.payload.authToken,
    error: null
  }),
  [AUTH_TOKEN_FAILED]: (state, action) => ({
    ...state,
    error: action.payload.error,
    authToken: null
  }),
  [AUTH_TOKEN_FINISHED]: state => ({ ...state, querying: false })
};

export default (state = INITIAL_STATE, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
