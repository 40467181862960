import { Block } from 'lucentum';
import styled from 'styled-components';

export const lastBlock = `
  > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    > div {
      position: relative;
      height: 100%;
      top: 0;
    }
  }
`;

export const PracticesBlock = styled(Block)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    > div {
      border: none;
    }
    h1 {
      font-size: ${({ theme }) => theme.fontSizeLarge};
      color: ${({ theme }) => theme.colorGray};
    }

    > div:nth-child(2) {
      > div {
        padding-right: 0;
      }
    }
    padding-bottom: ${({ theme }) => theme.size};
  }

  ${lastBlock}
  > div:first-child {
    align-items: flex-end;
    height: auto;
    > h1 {
      line-height: 1;
    }
  }
`;

export const Description = styled(Block)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    margin-top: ${({ theme }) => theme.sizeSmall};
    p {
      margin-top: 0;
    }
  }
`;
