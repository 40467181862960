import api from '../../utils/api';
import { EUIPOS_CATEGORY, NON_EU_IPOS_CATEGORY } from '../../utils/constants';
import {
  OFFICE_RECEIVED,
  OFFICE_REQUEST_FAILED,
  OFFICE_REQUEST_FINISHED,
  OFFICE_REQUESTED,
  CLEAR_OFFICE
} from './types';
import { parseEuipoFeaturedImplementations } from './parsers/euipo';
import { parseNotEuipoImplementations } from './parsers/no-euipo';

export const getOfficeRequested = () => ({
  type: OFFICE_REQUESTED,
  payload: null
});

export const getOfficeSucceed = office => ({
  type: OFFICE_RECEIVED,
  payload: { office }
});

export const getOfficeFailed = er => ({
  type: OFFICE_REQUEST_FAILED,
  payload: { error: er }
});

export const getOfficeFinished = () => ({
  type: OFFICE_REQUEST_FINISHED,
  payload: null
});

export const clearOffice = () => ({
  type: CLEAR_OFFICE,
  payload: null
});

export const getOffice = officeId => async dispatch => {
  dispatch(getOfficeRequested());
  try {
    const { data: office } = await api.getOffice(officeId);
    const { data: homeData } = await api.getHomeData();
    let featuredImplementations = []
    if(homeData && homeData.featuredImplementations) {
      featuredImplementations = homeData.featuredImplementations.filter(item => item.officeId == officeId)
    }
    office.featuredImplementations = featuredImplementations
    
    if (office.category === EUIPOS_CATEGORY || office.category === NON_EU_IPOS_CATEGORY) {
      office.timeline = Array.isArray(office.featuredImplementations)
        ? parseEuipoFeaturedImplementations(office)
        : null;
    } else {
      office.timeline = Array.isArray(office.workingGroupParticipation)
        ? parseNotEuipoImplementations(office)
        : null;
    }
    dispatch(getOfficeSucceed(office));
  } catch (error) {
    dispatch(getOfficeFailed(error));
  } finally {
    dispatch(getOfficeFinished());
  }
};
