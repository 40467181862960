import {
  ECPS_RECEIVED,
  ECPS_REQUEST_FAILED,
  ECPS_REQUEST_FINISHED,
  ECPS_REQUESTED,
  CREATE_ECPS_COOKIE_STATE,
  ECPS_PIN_ECP_SUCCEED,
  ECPS_PIN_ECP_FAILED
} from './types';

const INITIAL_STATE = {
  querying: false,
  ecps: null,
  error: null,
  cookieState: false
};

export const ecpsRequested = state => ({ ...state, querying: true });

export const ecpsReceived = (state, action) => ({
  ...state,
  ecps: action.payload.ecps.map(ecp => ({
    ...ecp,
    infoLink: `#/ecps/${ecp.id}`
  })),
  error: null
});

export const ecpsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  ecps: null
});

export const setCookieState = (state, action) => ({
  ...state,
  cookieState: action.payload.cookieState
});

const ecpsRequestFinished = state => ({ ...state, querying: false });

export const pinEcpSucceed = (state, { payload: { ecpId } }) => ({
  ...state,
  ecps: Array.isArray(state.ecps)
    ? state.ecps.map(ecp => ({
        ...ecp,
        favorite: !!(ecp.id === ecpId ? !ecp.favorite : ecp.favorite)
      }))
    : state.ecps
});

export const pinEcpFailed = (state, { payload: { error } }) => ({
  ...state,
  ecps: null,
  error: error
});

const reducersMap = {
  [ECPS_REQUESTED]: ecpsRequested,
  [ECPS_RECEIVED]: ecpsReceived,
  [ECPS_REQUEST_FAILED]: ecpsRequestFailed,
  [ECPS_REQUEST_FINISHED]: ecpsRequestFinished,
  [CREATE_ECPS_COOKIE_STATE]: setCookieState,
  [ECPS_PIN_ECP_SUCCEED]: pinEcpSucceed,
  [ECPS_PIN_ECP_FAILED]: pinEcpFailed
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
