import {
  SEARCH_RESULTS_RECEIVED,
  SEARCH_RESULTS_REQUEST_FAILED,
  SEARCH_RESULTS_REQUEST_FINISHED,
  SEARCH_RESULTS_REQUESTED
} from './types';

import api from '../../utils/api';

export const ITEMS_PER_PAGE = 5;

export const getSearchResultsRequested = () => ({
  type: SEARCH_RESULTS_REQUESTED,
  payload: null
});

export const getSearchResultsSucceed = (
  searchResults,
  numResults,
  currentPage
) => ({
  type: SEARCH_RESULTS_RECEIVED,
  payload: { searchResults, currentPage, numResults }
});

export const getSearchResultsFailed = er => ({
  type: SEARCH_RESULTS_REQUEST_FAILED,
  payload: { error: er }
});

export const getSearchResultsFinished = () => ({
  type: SEARCH_RESULTS_REQUEST_FINISHED,
  payload: null
});

export const getSearchResults = (
  searchTerm = '',
  searchType = '',
  currentPage = 1
) => async dispatch => {
  dispatch(getSearchResultsRequested());
  try {
    const {
      data: { searchResults, numResults }
    } = await api.getSearchResults(
      searchTerm || '',
      searchType || 'All',
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE
    );
    dispatch(getSearchResultsSucceed(searchResults, numResults, currentPage));
  } catch (error) {
    dispatch(getSearchResultsFailed(error.response.data));
  } finally {
    dispatch(getSearchResultsFinished());
  }
};
