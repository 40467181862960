import {
  OFFICES_RECEIVED,
  OFFICES_REQUEST_FAILED,
  OFFICES_REQUEST_FINISHED,
  OFFICES_REQUESTED
} from './types';
import api from '../../utils/api';

export const getOfficesRequested = () => ({
  type: OFFICES_REQUESTED,
  payload: null
});

export const getOfficesSucceed = offices => ({
  type: OFFICES_RECEIVED,
  payload: { offices }
});

export const getOfficesFailed = er => ({
  type: OFFICES_REQUEST_FAILED,
  payload: { error: er }
});

export const getOfficesFinished = () => ({
  type: OFFICES_REQUEST_FINISHED,
  payload: null
});

export const getOffices = () => async dispatch => {
  dispatch(getOfficesRequested());
  try {
    const { data: offices } = await api.getOffices();
    dispatch(getOfficesSucceed(offices));
  } catch (error) {
    dispatch(getOfficesFailed(error.response.data));
  } finally {
    dispatch(getOfficesFinished());
  }
};
