import cookie from 'js-cookie';

let doNotTrack=false;

const loadTrackingScript = trackingScriptUrl => {
  
    const scriptPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script')
      const firstScript = document.getElementsByTagName('script')[0]
      script.type = 'text/javascript'
      script.async = true
      script.defer = true
      script.src = trackingScriptUrl
      firstScript.parentNode.insertBefore(script, firstScript)
      script.onload = resolve
      script.onerror = reject
    })
    return scriptPromise
  }
  
  export const setupTrackingScript = async settings => {
    window._paq = window._paq || []
    let instance = window._paq
  
    const scriptUrl = `${settings.trackerURL}matomo.js`
    const trackerUrl = `${settings.trackerURL}matomo.php`
   
    
    instance.push(['setCookieDomain', settings.cookieDomain])
    instance.push(['setDoNotTrack', doNotTrack]);
    if (doNotTrack) {
      instance.push(['disableCookies']);
    }
    instance.push(['trackPageView'])
    instance.push(['setTrackerUrl', trackerUrl])
    instance.push(['setSiteId', settings.siteId])
    instance.push(['setVisitorCookieTimeout',31556952])
   
    try {
      await loadTrackingScript(scriptUrl)
      return true
    } catch (error) {
      console.error('Matomo could not be loaded')
      return false
    }
  }

let client = null
let isClientLoaded = false
let previousPageUrl = ''
let previousSiteId = ''

const init = async (matomoSettings = {}) => {
  let settings = { ...matomoSettings, siteId: Number(matomoSettings.siteId) }

  doNotTrack = cookie.get('data_protection_cookie') == "reject";
  
  console.log("Cookie data_protection_cookie: " + cookie.get('data_protection_cookie') + ". Setting doNotTrack to " + doNotTrack);

  if (settings.disabled) {
    return
  }
  
  if (!client) {
   
    const isMatomoLoaded = await setupTrackingScript(settings)
    if (isMatomoLoaded) {
      client = window._paq
      isClientLoaded = true
      previousSiteId = settings.siteId
     }
  } else {

    client.push(['setDoNotTrack', doNotTrack])
    
    if (doNotTrack) {
      client.push(['disableCookies']);
    }
    
    if (settings.siteId !== previousSiteId) {
      client.push(['setSiteId', settings.siteId])
    }
  }
 
}


export const trackRouting = (location, title) => {
  if(isClientLoaded){
    client = window._paq
    const host = window.location.origin
   
    const currentPageUrl = location.path || `${location.pathname}${location.search}`
    if (currentPageUrl === previousPageUrl) {
      return
    }

    if (previousPageUrl) {
      client.push(['setReferrerUrl', `${previousPageUrl}`])
    }
    client.push(['setCustomUrl', `${host}/#${currentPageUrl}`])
    client.push(['setDocumentTitle', `${title}`]);
    client.push(['trackPageView'])

    previousPageUrl = currentPageUrl
  }
} 

export const TrackingService = {
  init,
  trackRouting
}
