import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'lucentum';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import 'react-select/dist/react-select.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles';
import store from './redux/store';
import App from './components/app/';

import theme from './theme';
import boot from './boot';

require('moment/locale/es');
require('moment/locale/en-gb');

boot(store);

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={navigator.language}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);
