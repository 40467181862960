import {
  SET_COOKIES_LAW_COOKIE_SUCCEED,
  SHOW_COOKIE_LAW_BANNER
} from './types';

const INITIAL_STATE = {
  accepted: false,
  bannerContent: null,
  cookieName: null,
  cookieValue: null
};

const reducerMap = {
  [SET_COOKIES_LAW_COOKIE_SUCCEED]: state => ({ ...state, accepted: true }),
  [SHOW_COOKIE_LAW_BANNER]: (state, { payload }) => ({
    ...state,
    bannerContent: payload.cookiesBanner.bannerContent,
    cookieName: payload.cookiesBanner.cookieName,
    cookieValue: payload.cookiesBanner.cookieValue
  })
};

export default (state = INITIAL_STATE, action) =>
  reducerMap[action.type] ? reducerMap[action.type](state, action) : state;
