import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Block, Content, Spinner, Notification } from 'lucentum';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { AdvancedCard } from '../';
import {
  Tool as ToolPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import ErrorMessage from '../error-message';
import { ToolsList, Description } from './styles';
import {
  StyledTitle,
  BlockResponsive,
  CardsContainer
} from '../../commonStyles';
import {
  MAX_PINS,
  TOOL_PIN_TOOLTIP_PINNED,
  TOOL_PIN_TOOLTIP_UNPINNED,
  TOOL_PIN_TOOLTIP_UNPINNED_MAX
} from '../../utils/constants';

export default class Tools extends Component {
  static buildCategories = tools =>
    tools.reduce((prev, tool) => ({ ...prev, [tool.category]: [] }), {});

  static buildToolsCategoryTree = tools =>
    tools.reduce((prev, tool) => {
      prev[tool.category].push(tool);
      return prev;
    }, Tools.buildCategories(tools));

  handleCloseNotification = () => {
    this.props.createCookie();
  };

  canPin = tools =>
    !!(
      this.props.isUserLoged &&
      tools.filter(practice => practice.favorite).length < MAX_PINS
    );

  isFavorite = tool =>
    tool.favorite ? 'yes' : this.canPin(this.props.tools) ? 'no' : null;

  tooltipMessage = tool =>
    this.isFavorite(tool) === 'yes'
      ? TOOL_PIN_TOOLTIP_PINNED
      : this.isFavorite(tool) === 'no'
      ? TOOL_PIN_TOOLTIP_UNPINNED
      : '';
      

  render = () => {
    const { tools, querying, error, isUserLoged } = this.props;

    const categorizedTools =
      querying || error || !tools
        ? []
        : this.constructor.buildToolsCategoryTree(tools);

    return (
      <Content>
        {querying && (
          <Block center>
            <Spinner show row size={10} />
          </Block>
        )}
        {this.shouldShowErrorMessage() && <ErrorMessage />}
        {this.shouldShowTools() && (
          <BlockResponsive>
            <Block hiddenXS visibleLG marginBottom>
              <StyledTitle>
                <FormattedMessage id="portlet.section.tools" />
              </StyledTitle>
            </Block>
            <Description marginTop>
              <FormattedHTMLMessage
                id="porlet.tools.description"
                tagName="p"
              />
            </Description>
            <Block hiddenXS visibleLG marginBottom>
              {!this.props.cookieState && (
                <Notification onClose={this.handleCloseNotification}>
                  {/*<FormattedHTMLMessage id="portlet.tools.notification" />*/}
                  <FormattedHTMLMessage id="porlet.home.tooltip.info_tools" />
                </Notification>
              )}
            </Block>
            {Object.keys(categorizedTools).map((category, i) =>
              this.getCategorizedTool(
                i,
                category,
                categorizedTools,
                isUserLoged
              )
            )}
          </BlockResponsive>
        )}
      </Content>
    );
  };

  shouldShowTools = () => !this.props.querying && this.props.tools;

  shouldShowErrorMessage = () => !this.props.querying && this.props.error;

  getCategorizedTool = (i, category, categorizedTools, isUserLoged) => (
    <Content key={i}>
      <ToolsList className="category-title" title={category} marginBottom={2}>
        {categorizedTools[category].map(tool => (
          <CardsContainer key={tool.id} widthLG1of3>
            <AdvancedCard
              {...tool}
              className={`tool--${tool.key}`}
              favorite={this.isFavorite(tool)}
              onFavorite={() => {
                isUserLoged && this.props.pinTool(tool.id);
                !this.props.cookieState && this.handleCloseNotification();
              }}
              tooltipMessage={this.tooltipMessage(tool)}
            />
          </CardsContainer>
        ))}
      </ToolsList>
    </Content>
  );

  static propTypes = {
    ...ReducerPropType({
      tools: PropTypes.arrayOf(ToolPropType)
    }).isRequired
  };
}
