import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  Block,
  Content,
  TextField,
  Button,
  Spinner,
  H3,
  P,
  Detail,
  Link
} from 'lucentum';
import PropTypes from 'prop-types';

import Select from '../select';
import {
  SearchResult as SearchResultPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import ErrorMessage from '../error-message';
import { ITEMS_PER_PAGE } from '../../redux/search/action-creators';
import { StyledTitle } from '../../commonStyles/';
import { SearchBlock, SearchTools } from './styles';

const getURLByType = (type, id) =>
  type === 'Office'
    ? `#/members/${id}`
    : type === 'News'
    ? `#/${type.toLowerCase()}/${id}`
    : `#/${type.toLowerCase()}s/${id}`;

export const SearchResultUrl = ({ searchType, itemId, children }) => (
  <Link to={getURLByType(searchType, itemId)}>{children}</Link>
);

const SearchResult = ({ title, description, url, type, typeId }) => (
  <Block marginTop={1} marginBottom={1} className="search-result">
    <H3 dangerouslySetInnerHTML={{ __html: title }} />
    <P dangerouslySetInnerHTML={{ __html: description }} />
    <Detail>
      {url ? (
        <Link to={url}>{url}</Link>
      ) : (
        <SearchResultUrl searchType={type} itemId={typeId}>
          <FormattedMessage id="portlet.action.more_info" />
        </SearchResultUrl>
      )}
    </Detail>
  </Block>
);
SearchResult.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.string.isRequired,
  typeId: PropTypes.number.isRequired
};

class Search extends Component {
  state = {
    searchTerm: this.props.searchTerm || '',
    searchType: this.props.searchType || 'All'
  };

  static NO_RESULTS = <FormattedMessage id="empty.result.message" />;

  firstResultNum = () => (this.props.currentPage - 1) * ITEMS_PER_PAGE + 1;

  lastResultNum = () =>
    this.props.currentPage === this.props.numPages
      ? this.props.numResults
      : this.props.currentPage * ITEMS_PER_PAGE;

  updateSearchTerm = ({ target: { value: searchTerm } }) => {
    this.setState({ searchTerm });
  };

  updateSearchType = ({ key: searchType }) => {
    this.setState({ searchType });
  };

  handleClick = () => {
    this.props.getSearchResults(this.state.searchTerm, this.state.searchType);
  };

  data = [
    {
      id: 1,
      text: this.props.intl.formatMessage({ id: 'portlet.action.search.all' }),
      key: 'All'
    },
    {
      id: 2,
      text: this.props.intl.formatMessage({ id: 'portlet.section.members' }),
      key: 'Members'
    },
    {
      id: 3,
      text: this.props.intl.formatMessage({ id: 'portlet.section.tools' }),
      key: 'Tools'
    },
    {
      id: 4,
      text: this.props.intl.formatMessage({ id: 'portlet.section.practices' }),
      key: 'Practices'
    },
    {
      id: 5,
      text: this.props.intl.formatMessage({ id: 'portlet.section.news' }),
      key: 'News'
    },
    {
      id: 6,
      text: this.props.intl.formatMessage({ id: 'portlet.section.events' }),
      key: 'Events'
    },
    {
      id: 7,
      text: this.props.intl.formatMessage({
        id: 'entity.WorkingGroup'
      }),
      key: 'WorkingGroup'
    },
    {
      id: 8,
      text: this.props.intl.formatMessage({
        id: 'portlet.search.type.document'
      }),
      key: 'Document'
    }
  ];

  render = () => {
    const { searchResults, numResults, numPages } = this.props;
    return (
      <Content>
        <SearchBlock>
          <Block>
            <Block hiddenXS visibleLG marginTop={2}>
              <StyledTitle>
                <FormattedMessage id="portlet.section.search" />
              </StyledTitle>
            </Block>
            <SearchTools>
              <Block>
                <Select
                  src={this.data}
                  defaultValue={this.state.searchType}
                  value={this.state.searchType}
                  onChange={this.updateSearchType}
                  valueKey="key"
                  onBlurResetsInput
                />
              </Block>
              <Block>
                <FormattedMessage
                  id="portlet.search.placeholder"
                  values={{
                    type: this.data.filter(
                      item => item.key === this.state.searchType
                    )[0].text
                  }}
                >
                  {placeholder => (
                    <TextField
                      className="search-input"
                      defaultValue={this.state.searchTerm}
                      onChange={this.updateSearchTerm}
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
              </Block>
              <Block paddingLeft>
                <Button
                  className="search-button"
                  primary
                  icon="search"
                  onClick={this.handleClick}
                />
              </Block>
            </SearchTools>
          </Block>
          <Block marginTop={1} className="results-list">
            {this.shouldShowSpinner() && (
              <Block center>
                <Spinner show row size={10} />
              </Block>
            )}
            {this.shouldShowErrorMessage() && <ErrorMessage />}
            {this.shouldShowResults() && (
              <Block>
                <Block marginBottom={1}>
                  <FormattedHTMLMessage
                    id="portlet.search.pagination_info"
                    values={{
                      number_results: numResults,
                      from: this.firstResultNum(),
                      to: this.lastResultNum(),
                      total_pages: numPages
                    }}
                  />
                </Block>
                {searchResults.map((Item, i) => (
                  <SearchResult key={i} {...Item} />
                ))}
              </Block>
            )}
            {this.shouldShowNoResultsMessage() && (
              <P>{this.constructor.NO_RESULTS}</P>
            )}
          </Block>
        </SearchBlock>
      </Content>
    );
  };

  shouldShowErrorMessage = () => !this.props.querying && this.props.error;

  shouldShowNoResultsMessage = () =>
    !this.props.querying &&
    Array.isArray(this.props.searchResults) &&
    !this.props.searchResults.length;

  shouldShowResults = () =>
    !this.props.querying &&
    Array.isArray(this.props.searchResults) &&
    this.props.searchResults.length > 0;

  shouldShowSpinner = () => this.props.querying;
}

Search.propTypes = {
  ...ReducerPropType({
    searchResults: PropTypes.arrayOf(SearchResultPropType)
  }).isRequired,
  getSearchResults: PropTypes.func.isRequired,
  searchTerm: PropTypes.string
};

export default Search;
