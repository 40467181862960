import {
  ECP_RECEIVED,
  ECP_REQUEST_FAILED,
  ECP_REQUEST_FINISHED,
  ECP_REQUESTED,
  PIN_ECP_REQUESTED,
  PIN_ECP_FINISHED,
  PIN_ECP_FAILED,
  PIN_ECP_RECEIVED
} from './types';

const INITIAL_STATE = {
  querying: false,
  ecp: null,
  error: null
};

export const ecpRequested = state => ({ ...state, querying: true });
export const ecpReceived = (state, action) => ({
  ...state,
  ecp: action.payload.ecp,
  error: null
});

export const ecpRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  ecp: null
});

export const ecpRequestFinished = state => ({ ...state, querying: false });

export const pinEcpRequested = state => ({
  ...state,
  querying: true,
  error: null
});

export const pinEcpRequestReceived = state => ({
  ...state,
  ok: true
});

export const pinEcpRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error
});

export const pinEcpRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [ECP_REQUESTED]: ecpRequested,
  [ECP_RECEIVED]: ecpReceived,
  [ECP_REQUEST_FAILED]: ecpRequestFailed,
  [ECP_REQUEST_FINISHED]: ecpRequestFinished,
  [PIN_ECP_REQUESTED]: pinEcpRequested,
  [PIN_ECP_RECEIVED]: pinEcpRequestReceived,
  [PIN_ECP_FAILED]: pinEcpRequestFailed,
  [PIN_ECP_FINISHED]: pinEcpRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
