export const alert = '"\\e900"';
export const arrowDown = '"\\e901"';
export const arrowLeft = '"\\e902"';
export const arrowRight = '"\\e903"';
export const arrowUp = '"\\e904"';
export const audioFile = '"\\e905"';
export const audio = '"\\e906"';
export const backward = '"\\e907"';
export const bullet = '"\\e908"';
export const calendar = '"\\e909"';
export const cameraOutline = '"\\e90a"';
export const camera = '"\\e90b"';
export const cancelCheckoutFile = '"\\e90c"';
export const chart = '"\\e90d"';
export const checkCircle = '"\\e90e"';
export const check = '"\\e90f"';
export const checkinFile = '"\\e910"';
export const checkoutFile = '"\\e911"';
export const chevronDownSmall = '"\\e912"';
export const chevronDown = '"\\e913"';
export const chevronLeft = '"\\e914"';
export const chevronRight = '"\\e915"';
export const chevronUp = '"\\e916"';
export const close = '"\\e917"';
export const code = '"\\e918"';
export const compare = '"\\e919"';
export const copy = '"\\e91a"';
export const csv = '"\\e91b"';
export const remove = '"\\e91c"';
export const docCode = '"\\e91d"';
export const doc = '"\\e91e"';
export const download = '"\\e91f"';
export const dropArrow = '"\\e920"';
export const edit = '"\\e921"';
export const favoriteOutline = '"\\e922"';
export const favorite = '"\\e923"';
export const fb = '"\\e924"';
export const file = '"\\e925"';
export const filters = '"\\e926"';
export const firstpage = '"\\e927"';
export const folderFiles = '"\\e928"';
export const folder = '"\\e929"';
export const fontResize = '"\\e92a"';
export const forward = '"\\e92b"';
export const gg = '"\\e92c"';
export const gridLarge = '"\\e92d"';
export const gridSmall = '"\\e92e"';
export const help = '"\\e92f"';
export const inside = '"\\e930"';
export const information = '"\\e931"';
export const iPOffices = '"\\e932"';
export const lastpage = '"\\e933"';
export const link = '"\\e934"';
export const list = '"\\e935"';
export const listthin = '"\\e936"';
export const logout = '"\\e937"';
export const mail = '"\\e938"';
export const menu = '"\\e939"';
export const minusCircle = '"\\e93a"';
export const minus = '"\\e93b"';
export const more = '"\\e93c"';
export const moveFile = '"\\e93d"';
export const multiply = '"\\e93e"';
export const news = '"\\e93f"';
export const next = '"\\e940"';
export const notification = '"\\e941"';
export const pause = '"\\e942"';
export const pdf = '"\\e943"';
export const phone = '"\\e944"';
export const picture = '"\\e945"';
export const pinOutline = '"\\e946"';
export const pin = '"\\e947"';
export const play = '"\\e948"';
export const plusCircle = '"\\e949"';
export const plus = '"\\e94a"';
export const ppt = '"\\e94b"';
export const previous = '"\\e94c"';
export const print = '"\\e94d"';
export const save = '"\\e94e"';
export const searchHistory = '"\\e94f"';
export const searchMinus = '"\\e950"';
export const searchPlus = '"\\e951"';
export const search = '"\\e952"';
export const send = '"\\e953"';
export const setting = '"\\e954"';
export const share = '"\\e955"';
export const stats = '"\\e956"';
export const stop = '"\\e957"';
export const tag = '"\\e958"';
export const tw = '"\\e959"';
export const upload = '"\\e95a"';
export const user = '"\\e95b"';
export const videoFile = '"\\e95c"';
export const video = '"\\e95d"';
export const warning = '"\\e95e"';
export const xls = '"\\e95f"';
export const zip = '"\\e960"';
export const office = '"\\e961"';
