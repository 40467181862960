import React from 'react';
import { upperCaseFirst } from './component';
import { Block, Select, Icon } from './styles';

const getLanguageName = lang => lang.split('(')[0];

const getLanguageCode = code => code.split('_')[0];

export default ({ src, onLanguage, currentLang = 'en', ...props }) => (
  <Block {...props}>
    <Select
      onChange={e => onLanguage({ key: e.target.value })}
      defaultValue={currentLang}
    >
      {src.map(({ lang, code }) => (
        <option key={code} value={code}>
          {upperCaseFirst(getLanguageName(lang))}({getLanguageCode(code)})
        </option>
      ))}
    </Select>
    <Icon chevronDown />
  </Block>
);
