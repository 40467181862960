import {
  ACTIVE_LANGUAGE_REQUESTED,
  ACTIVE_LANGUAGE_SUCCEED,
  ACTIVE_LANGUAGE_FAILED,
  ACTIVE_LANGUAGE_FINISHED,
  CHANGE_LANGUAGE_REQUESTED,
  CHANGE_LANGUAGE_SUCCEED,
  CHANGE_LANGUAGE_FAILED,
  CHANGE_LANGUAGE_FINISHED,
  LANGUAGES_RECEIVED,
  LANGUAGES_REQUEST_FAILED,
  LANGUAGES_REQUEST_FINISHED,
  LANGUAGES_REQUESTED
} from './types';
import api from '../../utils/api';

export const getLanguagesRequested = () => ({
  type: LANGUAGES_REQUESTED,
  payload: null
});

export const getLanguagesSucceed = languages => ({
  type: LANGUAGES_RECEIVED,
  payload: { languages }
});

export const getLanguagesFailed = er => ({
  type: LANGUAGES_REQUEST_FAILED,
  payload: { error: er }
});

export const getLanguagesFinished = () => ({
  type: LANGUAGES_REQUEST_FINISHED,
  payload: null
});

export const getLanguages = () => async dispatch => {
  dispatch(getLanguagesRequested());
  try {
    const { data: languages } = await api.getLanguages();
    dispatch(getLanguagesSucceed(languages));
  } catch (error) {
    dispatch(getLanguagesFailed(error.response.data));
  } finally {
    dispatch(getLanguagesFinished());
  }
};

export const changeLanguageRequested = () => ({
  type: CHANGE_LANGUAGE_REQUESTED,
  payload: null
});

export const changeLanguageSucceed = languageCode => ({
  type: CHANGE_LANGUAGE_SUCCEED,
  payload: { languageCode }
});

export const changeLanguageFailed = error => ({
  type: CHANGE_LANGUAGE_FAILED,
  payload: { error }
});

export const changeLanguageFinished = () => ({
  type: CHANGE_LANGUAGE_FINISHED,
  payload: null
});

export const changeLanguage = languageCode => async dispatch => {
  dispatch(changeLanguageRequested());
  try {
    await api.changeLanguage(languageCode);
    api.setGlobalParameter({ languageId: languageCode });
    dispatch(changeLanguageSucceed(languageCode));
  } catch (error) {
    dispatch(changeLanguageFailed(error.response.data));
  } finally {
    dispatch(changeLanguageFinished());
  }
};

export const activeLanguageRequested = () => ({
  type: ACTIVE_LANGUAGE_REQUESTED,
  payload: null
});

export const activeLanguageSucceed = languageCode => ({
  type: ACTIVE_LANGUAGE_SUCCEED,
  payload: { languageCode }
});

export const activeLanguageFailed = error => ({
  type: ACTIVE_LANGUAGE_FAILED,
  payload: { error }
});

export const activeLanguageFinished = () => ({
  type: ACTIVE_LANGUAGE_FINISHED,
  payload: null
});

export const getActiveLanguage = () => async dispatch => {
  dispatch(activeLanguageRequested());
  try {
    const { data: activeLanguage } = await api.getActiveLanguage();
    api.setGlobalParameter({ languageId: activeLanguage.trim() });
    dispatch(activeLanguageSucceed(activeLanguage.trim()));
  } catch (error) {
    dispatch(activeLanguageFailed(error.response.data));
  } finally {
    dispatch(activeLanguageFinished());
  }
};
