import styled from 'styled-components';
import { Notification as NotificationLucentum } from 'lucentum';

import { them } from '../../commonStyles';

export const Notification = styled(NotificationLucentum)`
  button {
    ${({ onClose }) => !onClose && 'display: none'};
  }
  @media (${them('breakpointMaxSM')}) {
    &,
    button {
      padding-left: 0;
      padding-right: 0;
    }

    & > div:first-child {
      display: flex;
      & > div:first-child {
        display: flex;
        flex: 1;
        justify-content: center;
      }
      & > div:last-child {
        flex: 5;
      }
    }
  }
`;
