import moment from 'moment/moment';

const buildPeriod = year => ({ type: 'Period', label: year });

export const buildEuipoMilestone = milestone =>
  milestone.practice
    ? {
      type: 'Milestone',
      id: milestone.practiceId,
      right: true,
      title: milestone.practice.title,
      text: milestone.practice.shortDescription,
      button: {
        title: 'portlet.action.download',
        href: milestone.practice.commonCommunicationSource
      },
      moreInfo: {
        // title: 'More Info',
        title: "portlet.action.more_info",
        href: `#/practices/${milestone.practice.practiceId}`
      }
    }
    : {
      type: 'Milestone',
      id: milestone.toolId,
      right: false,
      title: milestone.tool.logo ? '' : milestone.tool.title,
      text: milestone.tool.shortDescription,
      ...(milestone.tool.logo
        ? {
          image: {
            src: milestone.tool.logo,
            big: true
          }
        }
        : null),
      ...(milestone.tool.url
        ? {
          button: {
            // title: 'Launch',
            title: "portlet.action.launch",
            href: milestone.tool.url
          }
        }
        : null),
      moreInfo: {
        // title: 'More Info',
        title: "portlet.action.more_info",
        href: `#/tools/${milestone.tool.toolId}`
      }
    };

export const buildEuipoFeaturedMilestone = milestone =>
  milestone.practice
    ? {
      type: 'Milestone',
      id: milestone.practiceId,
      right: true,
      title: milestone.practiseTitle,
      text: milestone.descriptionOfImplementation,
      button: {
        title: 'portlet.action.download',
        href: milestone.practiceCommonCommunicationSource
      },
      moreInfo: {
        // title: 'More Info',
        title: "portlet.action.more_info",
        href: `#/practices/${milestone.practiceId}`
      }
    }
    : {
      type: 'Milestone',
      id: milestone.toolId,
      right: false,
      title: milestone.toolLogo ? '' : milestone.toolTitle,
      text: milestone.descriptionOfImplementation,
      ...(milestone.tool.logo
        ? {
          image: {
            src: milestone.toolLogo,
            big: true
          }
        }
        : null),
      ...(milestone.toolUrl
        ? {
          button: {
            // title: 'Launch',
            title: "portlet.action.launch",
            href: milestone.toolUrl
          }
        }
        : null),
      moreInfo: {
        // title: 'More Info',
        title: "portlet.action.more_info",
        href: `#/tools/${milestone.toolId}`
      }
    };

const orderByDate = (a, b) => b.date - a.date;

const buildYears = office =>
  office.featuredImplementations.reduce((prev, item) => {
    const year = moment(item.date).year();
    const period = buildPeriod(year);
    if (!prev.length) {
      prev = [period];
    } else if (!prev.some(prevPeriod => prevPeriod.label == period.label)) {
      prev = [...prev, period];
    }
    return prev;
  }, []);

export const parseEuipoImplementations = office => {
  office.implementations.sort(orderByDate);
  return buildYears(office).reduce((prev, item) => {
    const year = item.label;
    const implementations = office.implementations.filter(
      implementation =>
        moment(implementation.date).year() === year &&
        (implementation.practice || implementation.tool)
    );
    const milestones = implementations.map(item => buildEuipoMilestone(item));
    return [...prev, ...milestones, item];
  }, []);
};

export const parseEuipoFeaturedImplementations = office => {
  console.log('initial', office);
  office.featuredImplementations.sort(orderByDate);
  console.log('sorted', office);
  return buildYears(office).reduce((prev, item) => {
    const year = item.label;
    const implementations = office.featuredImplementations.filter(
      implementation =>
        moment(implementation.date).year() === year &&
        (implementation.practiceId && implementation.practiceTitle !== "" || implementation.toolId && implementation.toolTitle !== "" || implementation.ecpId)
      // ( implementation.ecpId)
    );
    console.log('filteredImplementations', implementations);

    const milestones = [];
    implementations.forEach(implementation => {
      if (implementation.practiceId && implementation.practiceTitle !== "") {
        let objPractise = {
          type: 'Milestone',
          id: implementation.practiceId,
          right: true,
          title: implementation.practiceTitle,
          text: implementation.descriptionOfImplementation,
          date: moment(implementation.date).format("DD/MM/YYYY"),
          button: {
            title: 'portlet.action.download',
            href: implementation.practiceCommonCommunicationSource
          },
          moreInfo: {
            // title: 'More Info',
            title: "portlet.action.more_info",
            href: `#/practices/${implementation.practiceId}`
          }
        }
        milestones.push(objPractise)
      }
      if (implementation.toolId && implementation.toolTitle !== "") {
        let objTool = {
          type: 'Milestone',
          id: implementation.toolId,
          right: false,
          title: implementation.toolLogo ? '' : implementation.toolTitle,
          text: implementation.descriptionOfImplementation,
          date: moment(implementation.date).format("DD/MM/YYYY"),
          ...(implementation.toolLogo
            ? {
              image: {
                src: implementation.toolLogo,
                big: true
              }
            }
            : null),
          ...(implementation.toolUrl
            ? {
              button: {
                // title: 'Launch',
                title: "portlet.action.launch",
                href: implementation.toolUrl
                //href: "portlet.action.more_info"
              }
            }
            : null),
          moreInfo: {
            // title: 'More Info',
            title: "portlet.action.more_info",
            href: `#/tools/${implementation.toolId}`
          }
        }
        milestones.push(objTool);
      }
      if (implementation.ecpId && implementation.ecpTitle !== "") {

        let objEcp = {
          type: 'Milestone',
          id: implementation.ecpId,
          title: implementation.ecpTitle,
          text: implementation.descriptionOfImplementation,
          background: '#e5f7f9',
          moreInfo: {
            title: "portlet.action.more_info",
            href: `#/ecps/${implementation.ecpId}`
          }
        }
        milestones.push(objEcp);
      }
    })

    return [...prev, ...milestones, item];
  }, []);
};
