export const LANGUAGES_REQUESTED = 'LANGUAGES_REQUESTED';
export const LANGUAGES_RECEIVED = 'LANGUAGES_RECEIVED';
export const LANGUAGES_REQUEST_FAILED = 'LANGUAGES_REQUEST_FAILED';
export const LANGUAGES_REQUEST_FINISHED = 'LANGUAGES_REQUEST_FINISHED';

export const CHANGE_LANGUAGE_REQUESTED = 'CHANGE_LANGUAGE_REQUESTED';
export const CHANGE_LANGUAGE_SUCCEED = 'CHANGE_LANGUAGE_SUCCEED';
export const CHANGE_LANGUAGE_FAILED = 'CHANGE_LANGUAGE_FAILED';
export const CHANGE_LANGUAGE_FINISHED = 'CHANGE_LANGUAGE_FINISHED';

export const ACTIVE_LANGUAGE_REQUESTED = 'ACTIVE_LANGUAGE_REQUESTED';
export const ACTIVE_LANGUAGE_SUCCEED = 'ACTIVE_LANGUAGE_SUCCEED';
export const ACTIVE_LANGUAGE_FAILED = 'ACTIVE_LANGUAGE_FAILED';
export const ACTIVE_LANGUAGE_FINISHED = 'ACTIVE_LANGUAGE_FINISHED';
