import {
  SEARCH_RESULTS_RECEIVED,
  SEARCH_RESULTS_REQUEST_FAILED,
  SEARCH_RESULTS_REQUEST_FINISHED,
  SEARCH_RESULTS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  searchResults: null,
  error: null,
  numResults: null,
  currentPage: 1
};

export const searchResultsRequested = state => ({ ...state, querying: true });

export const searchResultsReceived = (state, action) => ({
  ...state,
  searchResults: action.payload.searchResults.map(searchItem => ({
    href: `#/events/${searchItem.id}`,
    ...searchItem
  })),
  numResults: action.payload.numResults,
  currentPage: action.payload.currentPage,
  error: null
});

export const searchResultsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  searchResults: null,
  numResults: null,
  currentPage: 1
});

export const searchResultsRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [SEARCH_RESULTS_REQUESTED]: searchResultsRequested,
  [SEARCH_RESULTS_RECEIVED]: searchResultsReceived,
  [SEARCH_RESULTS_REQUEST_FAILED]: searchResultsRequestFailed,
  [SEARCH_RESULTS_REQUEST_FINISHED]: searchResultsRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
