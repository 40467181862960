import React from 'react';
import { Content, Block, Spinner } from 'lucentum';
import { BlockResponsive } from '../../commonStyles/';
import { ContentBlock, StyledDate, StyledTitle } from './styles';
import HtmlDisplay from '../html-display';

import ErrorMessage from '../error-message';
import {
  Event as EventPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { EventDetail } from '../../propTypes/event';
import { fromUnixToLocalString } from '../../utils/functions';

const Detail = ({ begin, end = null }) => (
  <span>
    {end && end !== begin
      ? `${fromUnixToLocalString(begin)} - ${fromUnixToLocalString(end)}`
      : fromUnixToLocalString(begin)}
  </span>
);

Detail.propTypes = EventDetail;
Detail.defaultProps = {
  end: null
};

const Event = ({ event, querying, error }) => (
  <Content>
    {querying && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {!querying && error && <ErrorMessage />}
    {!querying && event && (
      <BlockResponsive>
        <Block>
          <StyledTitle>{event.title}</StyledTitle>
        </Block>
        <ContentBlock paddingTop>
          <StyledDate className="event__date">
            <Detail begin={event.beginDate} end={event.endDate} />
          </StyledDate>
          <Block hiddenXS visibleSM paddingTop>
            {event.location}
          </Block>
          <HtmlDisplay>{event.description}</HtmlDisplay>
        </ContentBlock>
      </BlockResponsive>
    )}
  </Content>
);

Event.propTypes = ReducerPropType({
  event: EventPropType
}).isRequired;

export default Event;
