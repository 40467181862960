import axios from 'axios';
import config from '../config';
import moment from 'moment';

class API {
  constructor() {
    this.http = axios;
    this.http.defaults.app = {};
    const that = this;
    this.http.interceptors.request.use(
      config => {
        if (that.sessionHasExpired()) {
          if (process.env.REACT_APP_ENVIRONMENT.includes('development')) {
            window.location.reload();
          } else {
            window.location = `${window.location.protocol}//${
              window.location.host
            }/network/web/guest?p_url_publicwebsite=${window.location.toString()}`;
          }
        }
        that.setSessionTimeout(
          that.http.defaults.app.timeoutDuration || 5
        );
        return config;
      },
      function(error) {
        return Promise.reject(error);
      }
    );

    this.http.interceptors.response.use(
      function(response) {
        return !response.data.exception
          ? response
          : Promise.reject({ response });
      },
      function(error) {
        return Promise.reject(error);
      }
    );
  }

  sessionHasExpired = (now = new Date()) =>
    this.http.defaults.app.timeout &&
    moment(now).isAfter(
      moment(this.http.defaults.app.timeout)
    );

  buildUrl = url => `${config.API.URL}${url}`;
  buildServiceUrl = url => `${config.NETWORK_ENDPOINT}${url}`;

  setGlobalParameter = param =>
    (this.http.defaults.params = {
      ...this.http.defaults.params,
      ...param
    });

  setSessionTimeout = (timeout, now = new Date()) => {
    this.http.defaults.app = {
      ...(this.http.defaults.app || {}),
      timeout: moment(now)
        .add(timeout, 'minutes')
        .toDate(),
      timeoutDuration: timeout
    };
  };

  getAuthToken = () =>
    this.http.get(this.buildServiceUrl(config.P_AUTH_ENDPOINT), {
      params: { languageId: null, p_auth: null }
    });

  changeLanguage = languageCode =>
    this.http.get(
      this.buildServiceUrl(
        `home?p_p_id=82&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_82_struts_action=/language/view&_82_redirect=/network/&_82_languageId=${languageCode}`
      ),
      { params: { languageId: null } }
    );

  getActiveLanguage = () =>
    this.http.get(
      this.buildServiceUrl(
        'navigation_language.public-website-services-portlet.jsp'
      ),
      {
        params: { languageId: null }
      }
    );

  getFeaturedCourses = () =>
    this.http.get(this.buildUrl('.course/featuredCourses'));

  getHomeData = () => this.http.get(this.buildUrl('.global/getHomeData'));
  getEventsHomeData = () =>
    this.http.get(this.buildUrl('.event/getEventHomeData'), {
      params: { nEvents: 5 }
    });
  getEventsFilters = () => this.http.get(this.buildUrl('.event/eventsFilters'));
  getPaginatedEvents = (start, end, filter) =>
    this.http.get(this.buildUrl('.event/get-all-events-paginated'), {
      params: { start, end, filter }
    });
  getEvent = id =>
    this.http.get(this.buildUrl(`.event/getEvent/event-id/${id}`));
  getFeaturedEvents = () =>
    this.http.get(this.buildUrl('.event/featuredEvents'));

  contactSubmit = ({
    name,
    surname,
    organisation,
    topic,
    email,
    file,
    message,
    checked
  } = {}) => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('organisation', organisation);
    formData.append('topic', topic);
    formData.append('email', email);
    formData.append('file', file);
    formData.append('message', message);
    formData.append('checked', checked);

    return this.http.post(this.buildUrl('.global/contact-us'), formData, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    });
  };
  getSearchResults = (searchTerm, searchType, start, end) =>
    this.http.get(this.buildUrl('.global/get-all-search-result-paginated'), {
      params: { searchTerm, searchType, start, end }
    });
  getLoggedUser = () => this.http.get(this.buildUrl('.global/get-loged-user'));
  getTopicsContactUs = () =>
    this.http.get(this.buildUrl('.global/getTopicsContactUs'));
  getLanguages = () => this.http.get(this.buildUrl('.global/languages'));

  getMembers = () => this.http.get(this.buildUrl('.members/members'));

  getNewsHomeData = () =>
    this.http.get(this.buildUrl('.newsitem/getNewsHomeData'), {
      params: { nNews: 10 }
    });
  getNewsItem = id =>
    this.http.get(this.buildUrl(`.newsitem/getNews/news-id/${id}`));
  getNews = (start, end, filter) =>
    this.http.get(this.buildUrl('.newsitem/get-all-news-paginated'), {
      params: { start, end, filter }
    });
  getFeaturedNews = () =>
    this.http.get(this.buildUrl('.newsitem/featuredNews'));

  getFeaturedNewsletter = () =>
    this.http.get(this.buildUrl('.newsletter/featuredNewsletters'));
  getNewsletters = () =>
    this.http.get(this.buildUrl('.newsletter/newsletters'));
  subscribeToNewsletter = email => {
    const formData = new FormData();
    formData.append('email', email);
    return this.http.post(
      this.buildUrl(`.newsletter/subscribeToNewsletter`),
      formData
    );
  };

  getOffices = () => this.http.get(this.buildUrl('.office/offices'));
  getOffice = officeId =>
    this.http.get(this.buildUrl(`.office/getOffice/office-id/${officeId}`));

  getFeaturedPractices = () =>
    this.http.get(this.buildUrl('.practice/featuredPractices'));
  getPractices = () => this.http.get(this.buildUrl('.practice/practices'));
  getPractice = practiceId =>
    this.http.get(
      this.buildUrl(`.practice/getPractice/practice-id/${practiceId}`)
    );
  pinPractice = id =>
    this.http.put(this.buildUrl(`.practice/setFeatured/practice-id/${id}`));

  getTool = id => this.http.get(this.buildUrl(`.tool/getTool/tool-id/${id}`));
  getFeaturedTools = () => this.http.get(this.buildUrl('.tool/featuredTools'));
  getTools = () => this.http.get(this.buildUrl('.tool/tools'));
  pinTool = id =>
    this.http.put(this.buildUrl(`.tool/setFeatured/tool-id/${id}`));


  getImplementation = id => this.http.get(this.buildUrl(`.implementation/getImplementation/implementation-id/${id}`));
  getFeaturedImplementations = () => this.http.get(this.buildUrl('.implementation/featuredImplementations'));
  getImplementations = () => this.http.get(this.buildUrl('.implementation/implementations'));
  pinImplementation = id =>
    this.http.put(this.buildUrl(`.implementation/setFeatured/implementation-id/${id}`));

  getEcp = id => this.http.get(this.buildUrl(`.ecp/getEcp/ecp-id/${id}`));
  getFeaturedEcps = () => this.http.get(this.buildUrl('.ecp/featuredEcps'));
  getEcps = () => this.http.get(this.buildUrl('.ecp/ecps'));
  pinEcp = id =>
    this.http.put(this.buildUrl(`.ecp/setFeatured/ecp-id/${id}`));

  getHero = () => this.http.get(this.buildUrl('.banner/getHero'));
  getHeroCarousel = () => this.http.get(this.buildUrl('.banner/getHeroCarousel'));

  getCommonPractices = () =>
    this.http.get(this.buildUrl('.practice/commonPractice'));

  getCookiesBanner = languageCode =>
    this.http.get(
      this.buildServiceUrl(
        `delegate/cg-webcontent-services/gateway-pages?getName=wc-data-protection-json&language=${languageCode}`
      ),
      { params: { p_auth: null, languageId: null } }
    );

  getMatomoProperties = () =>
    this.http.get(this.buildUrl('.global/getMatomoProperties'));
}
export default new API();
