import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Block, Link, Spinner, H1, P } from 'lucentum';
import Tooltip from '../tooltip';
import {
  BlockTitle,
  InfoIcon,
  TooltipFieldset
} from '../../commonStyles/homeTooltipStyles';

import { AdvancedCard } from '../';
import ErrorMessage from '../error-message';
import {
  Reducer as ReducerPropType,
  Tool as ToolPropType
} from '../../propTypes';
import {
  MAX_PINS,
  TOOL_PIN_TOOLTIP_PINNED,
  TOOL_PIN_TOOLTIP_UNPINNED,
  TOOL_PIN_TOOLTIP_UNPINNED_MAX
} from '../../utils/constants';

export default class ToolsWidget extends Component {
  canPin = tools =>
    !!(
      this.props.isUserLoged &&
      tools.filter(({ favorite }) => favorite).length < MAX_PINS
    );

  isFavorite = ({ favorite }) =>
    favorite ? 'yes' : this.canPin(this.props.featuredTools) ? 'no' : null;

  tooltipMessage = tool =>
    this.isFavorite(tool) === 'yes'
      ? TOOL_PIN_TOOLTIP_PINNED
      : this.isFavorite(tool) === 'no'
        ? TOOL_PIN_TOOLTIP_UNPINNED
        : TOOL_PIN_TOOLTIP_UNPINNED_MAX;

  render() {
    const { featuredTools, querying, error, pinTool, isUserLoged } = this.props;
    return (
      <Block>
        <BlockTitle>
          <H1>
            <FormattedMessage id="portlet.section.tools" />
          </H1>
          <TooltipFieldset>
            <div data-tip data-for="tools-icon">
              <InfoIcon information />
            </div>
            <Tooltip id="tools-icon">
              <P>
                {/*<FormattedMessage id="portlet.home.tooltip.info_tools" />*/}
                <FormattedHTMLMessage id="porlet.home.tooltip.info_tools" />
              </P>
            </Tooltip>
          </TooltipFieldset>
        </BlockTitle>
        {querying && (
          <Block center>
            <Spinner show row size={3} />
          </Block>
        )}
        {!querying && error && <ErrorMessage />}
        {!querying && featuredTools && (
          <Block marginTop={0.86}>
            {featuredTools.map((tool, i) => (
              <AdvancedCard
                {...tool}
                key={i}
                infoLink={`#/tools/${tool.id}`}
                favorite={this.isFavorite(tool)}
                onFavorite={() => isUserLoged && pinTool(tool.id)}
                tooltipMessage={this.tooltipMessage(tool)}
              />
            ))}
            <Block right>
              <Link to="#/tools">
                <FormattedMessage id="portlet.action.tools.show_all" />
              </Link>
            </Block>
          </Block>
        )}
      </Block>
    );
  }

  static propTypes = {
    ...ReducerPropType({
      featuredTools: PropTypes.arrayOf(ToolPropType)
    }).isRequired,
    pinTool: PropTypes.func.isRequired
  };
}
