import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Page from '../../components/page';
import ContactFormComponent from '../../components/contact-form';
import ErrorMessage from '../../components/error-message';
import { Reducer as ReducerPropType } from '../../propTypes/';

class ContactForm extends Component {
  render = () => {
    return !this.props.contactUsTopics.error ? (
      <Page active={this.props.active} languages={this.props.languages}>
        <ContactFormComponent
          querying={this.props.contactUsTopicsQuering}
          handleSubmit={this.props.handleSubmit}
          submitting={this.props.contactForm.querying}
          submitError={this.props.contactForm.error}
          contactUsTopics={this.props.contactUsTopics.contactUsTopics}
        />
      </Page>
    ) : (
      <ErrorMessage />
    );
  };

  componentDidMount = () => {
    this.props.getContactUsTopics();
  };
}

ContactForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  contactForm: ReducerPropType({
    ok: PropTypes.bool
  })
};

export default ContactForm;
