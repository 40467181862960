import {
  AUTH_TOKEN_FAILED,
  AUTH_TOKEN_FINISHED,
  AUTH_TOKEN_RECEIVED,
  AUTH_TOKEN_REQUESTED
} from './types';

import api from '../../utils/api';

export const authTokenRequested = () => ({
  type: AUTH_TOKEN_REQUESTED,
  payload: null
});

export const authTokenReceived = authToken => ({
  type: AUTH_TOKEN_RECEIVED,
  payload: { authToken }
});

export const authTokenFailed = error => ({
  type: AUTH_TOKEN_FAILED,
  payload: { error }
});

export const authTokenFinished = () => ({
  type: AUTH_TOKEN_FINISHED,
  payload: null
});

export const getAuthToken = () => async dispatch => {
  dispatch(authTokenRequested());
  try {
    const {
      data: { p_auth, timeOut }
    } = await api.getAuthToken();
    api.setGlobalParameter({ p_auth });
    api.setSessionTimeout(timeOut);
    dispatch(authTokenReceived(p_auth.trim()));

  } catch (error) {
    dispatch(authTokenFailed(error.response ? error.response.data : error));
  } finally {
    dispatch(authTokenFinished());
  }
};
