import { connect } from 'react-redux';

import App from './component';
import { getLabels } from '../../redux/labels/action-creators';
import { getMatomoProperties } from '../../redux/matomo/action-creators';
import { getLoggedUser } from '../../redux/user/action-creators';
import {
  getLanguages,
  getActiveLanguage
} from '../../redux/languages/action-creators';
import { applyFontSizeFromCookie } from '../../redux/user/action-creators';
import { loadCookieLawState } from '../../redux/cookies/action-creators';

export const mapStateToProps = state => ({
  gettingLabels: state.labels.querying,
  authToken: !state.authToken.querying && state.authToken.authToken,
  changingLanguage: state.languages.changingLanguage,
  gettingActiveLanguage: state.languages.gettingActiveLanguage,
  activeLanguage: state.languages.activeLanguageCode,
  fetchDataFailed: !!(state.authToken.error || state.labels.error)
});

export const mapDispatchToProps = dispatch => ({
  getLabels: () => dispatch(getLabels()),
  getLoggedUser: () => dispatch(getLoggedUser()),
  getLanguages: () => dispatch(getLanguages()),
  applyFontSizeFromCookie: () => dispatch(applyFontSizeFromCookie()),
  loadCookieLawState: () => dispatch(loadCookieLawState()),
  getActiveLanguage: () => dispatch(getActiveLanguage()),
  getMatomoProperties: () => dispatch(getMatomoProperties())
});

const mergeProps = ({ ...stateProps }, { ...actionProps }, { ...ownProps }) => ({
  ...stateProps,
  ...ownProps,
  ...actionProps,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(App);
