import {
  PRACTICE_RECEIVED,
  PRACTICE_REQUEST_FAILED,
  PRACTICE_REQUEST_FINISHED,
  PRACTICE_REQUESTED,
  PIN_PRACTICE_REQUESTED,
  PIN_PRACTICE_FINISHED,
  PIN_PRACTICE_FAILED,
  PIN_PRACTICE_RECEIVED
} from './types';

const INITIAL_STATE = {
  querying: false,
  practice: null,
  error: null
};

export const practiceRequested = state => ({ ...state, querying: true });
export const practiceReceived = (state, action) => ({
  ...state,
  practice: {
    id: action.payload.practice.practiceId,
    ...action.payload.practice
  },
  error: null
});

export const practiceRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  practice: null
});
export const practiceRequestFinished = state => ({ ...state, querying: false });

export const pinPracticeRequested = state => ({
  ...state,
  querying: true,
  error: null
});
export const pinPracticeReceived = (state, action) => ({
  ...state,
  ok: true,
  error: null
});

export const pinPracticeRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  ok: false
});
export const pinPracticeRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [PRACTICE_REQUESTED]: practiceRequested,
  [PRACTICE_RECEIVED]: practiceReceived,
  [PRACTICE_REQUEST_FAILED]: practiceRequestFailed,
  [PRACTICE_REQUEST_FINISHED]: practiceRequestFinished,
  [PIN_PRACTICE_REQUESTED]: pinPracticeRequested,
  [PIN_PRACTICE_FINISHED]: pinPracticeRequestFinished,
  [PIN_PRACTICE_FAILED]: pinPracticeRequestFailed,
  [PIN_PRACTICE_RECEIVED]: pinPracticeReceived
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
