import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Block, Content, Image, Spinner, Button } from 'lucentum';

import HtmlDisplay from '../html-display';
import Timeline from '../timeline';
import AdvancedCard from '../advanced-card';
import ErrorMessage from '../error-message';
import {
  ResponsiveTitle,
  BlockResponsive,
  BlockTimeline,
  VideoBlock
} from '../../commonStyles';
import {
  Ecp as EcpPropType,
  Reducer as ReducerPropType
} from '../../propTypes';

const hasSomeTimelineItems = timeline =>
  !!(Array.isArray(timeline) && timeline.length);

export const LogoOrTitle = ({ ecp }) => (
  <Block hiddenLG>
    {ecp.logo ? (
      <Image src={ecp.logo} style={{ width: '100%' }} />
    ) : (
      <ResponsiveTitle>{ecp.title}</ResponsiveTitle>
    )}
  </Block>
);

const shouldShowSpinner = querying => querying;

const shouldShowErrorMessage = (querying, error) => !querying && error;

const shouldShowEcp = (querying, ecp) => !querying && ecp;

const shouldShowLaunchButton = url => url;

const shouldShowEcpCard = url => url;

export const EcpAttachments = ({ ecp }) => (
  <Block widthLG35of100 hiddenXS visibleLG>
    
    { ecp.implementations && ecp.implementations.map(({tool, practice}) => { return  (
      <div>
        {tool &&
        <AdvancedCard
          id={tool.id}
          logo={tool.logo}
          title={tool.title}
          url={tool.url}
          infoLink={`#/tools/${tool.id}`}
          className="heightAuto"
        />}
        {practice && 
        <AdvancedCard
          id={practice.id}
          //logo={tool.logo}
          title={practice.title}
          downloadUrl={practice.commonCommunicationSource}
          className="heightAuto"
          infoLink={`#/practices/${practice.id}`}
        />}
      </div>
      
    )})}
    {ecp.documents &&
    ecp.documents.map(({ title, downloadUrl }, i) => (
        <AdvancedCard
          downloadUrl={downloadUrl}
          title={title}
          key={`ecp--document--${i}`}
          className="heightAuto"
        />
      ))}
    {Array.isArray(ecp.videos) &&
    ecp.videos.map((video, i) => (
        <VideoBlock marginTop={4} key={`ecp--video--${i}`}>
          <iframe
            src={video.source}
            frameBorder="0"
            allow="encrypted-media"
            allowFullScreen
            title={`${i}-${video.source}`}
            width="100%"
          />
        </VideoBlock>
      ))}
  </Block>
);

const Ecp = ({ error, querying, ecp }) => (
  <Content>
    {shouldShowSpinner(querying) && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {shouldShowErrorMessage(querying, error) && <ErrorMessage />}
    {shouldShowEcp(querying, ecp) && (
      <Block>
        <BlockResponsive justify bottom paddingBottom>
          <Block>
            <LogoOrTitle ecp={ecp} />
            <Block hiddenXS visibleLG>
              <ResponsiveTitle>{ecp.title}</ResponsiveTitle>
            </Block>
          </Block>
          {shouldShowLaunchButton(ecp.url) && (
            <Block right hiddenLG>
              <Button
                className="launch--button"
                primary
                onClick={() => window.open(ecp.url)}
              >
                <FormattedMessage id="portlet.action.launch" />
              </Button>
            </Block>
          )}
        </BlockResponsive>
        <BlockResponsive paddingTop>
          <HtmlDisplay paddingRight={2} widthLG65of100>
            {ecp.longDescription}
          </HtmlDisplay>
          <EcpAttachments ecp={ecp} />
        </BlockResponsive>
        <BlockTimeline marginTop={2} marginBottom={2}>
          {hasSomeTimelineItems(ecp.timeline) && (
            <Timeline
              seriesLeft={
                <FormattedMessage id="portlet.action.timelines.projects" />
              }
              seriesRight={
                <FormattedMessage id="portlet.text.timelines.implementations" />
              }
              src={ecp.timeline}
            />
          )}
        </BlockTimeline>
      </Block>
    )}
  </Content>
);

Ecp.propTypes = {
  ...ReducerPropType({
    ecp: EcpPropType
  }).isRequired
};

export default Ecp;
