import React from 'react';

import { Block, Navigation, Link, Icon } from 'lucentum';

const URL_LOGOUT = '/network/c/portal/logout';

export default ({
  onSignIn,
  onSignOut,
  onSignUp,
  signInTestId,
  signUpTestId,
  textSignIn,
  textSignOut,
  textSignUp,
  src,
  ...props
}) =>
  src ? (
    <Block marginTop={2} hiddenLG {...props}>
      <Navigation>
        <Link to={URL_LOGOUT}>{textSignOut}</Link>
      </Navigation>
    </Block>
  ) : (
    <Block hiddenLG marginTop={2} marginBottom={2} {...props}>
      <Navigation>
        <Block column>
          <Link data-test-id={signInTestId} primary onClick={onSignIn}>
            <Icon user />
            {textSignIn}
          </Link>
          <Link data-test-id={signUpTestId} primary onClick={onSignUp}>
            {textSignUp}
          </Link>
        </Block>
      </Navigation>
    </Block>
  );
