import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'lucentum';
import Icon from '../icon';
import { Node, BreadcrumbsContainer } from './styles';

export default function Breadcrumbs({ src }) {
  return (
    <BreadcrumbsContainer>
      {Array.isArray(src) &&
        src.length > 0 &&
        src.map(({ text, url }, i) =>
          i !== src.length - 1 ? (
            <Node>
              <Link to={url}>
                <Node>{text}</Node>
              </Link>
              <Icon chevronRight dimmer />
            </Node>
          ) : (
            <Node>{text}</Node>
          )
        )}
    </BreadcrumbsContainer>
  );
}
Breadcrumbs.propTypes = {
  src: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};
