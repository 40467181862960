import { fromUnixToLocalString } from '../../utils/functions';
import {
  MEMBERS_RECEIVED,
  MEMBERS_REQUEST_FAILED,
  MEMBERS_REQUEST_FINISHED,
  MEMBERS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  members: null,
  error: null
};

export const membersRequested = state => ({ ...state, querying: true });

export const membersReceived = (state, action) => ({
  ...state,
  members: {
    ...action.payload.members,
    countries: action.payload.members.countries.map(country => ({
      ...country,
      joined: fromUnixToLocalString(country.joined),
      updated: fromUnixToLocalString(country.updated)
    }))
  },
  error: null
});

export const membersRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  members: null
});

export const membersRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [MEMBERS_REQUESTED]: membersRequested,
  [MEMBERS_RECEIVED]: membersReceived,
  [MEMBERS_REQUEST_FAILED]: membersRequestFailed,
  [MEMBERS_REQUEST_FINISHED]: membersRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
