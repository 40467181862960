import { connect } from 'react-redux';
import {
  changeCookieState,
  getPractices,
  createCookieState as createPracticesCookieState
} from '../../redux/practices/action-creators';
import Practices from './component';
import { pinPractice } from '../../redux/practice/action-creators';

export const mapStateToProps = state => ({
  practices: state.practices,
  cookieState: state.practices.cookieState,
  isUserLoged: !!state.user.object
});

export const mapDispatchToProps = dispatch => ({
  editCookie: () => dispatch(changeCookieState()),
  pinPractice: id => dispatch(pinPractice(id)),
  getPractices: () => dispatch(getPractices()),
  createCookie: () => dispatch(createPracticesCookieState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Practices);
