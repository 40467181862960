import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Page, Members as MembersComponent } from '../../components/';
import { Content } from 'lucentum';

import {
  Office as OfficePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { FormattedMessage } from 'react-intl';
import Breadcrumbs from '../../components/breadcrumbs/component';

export default class Members extends Component {
  render = () => {
    const { offices, hero, active } = this.props;
    return (
      <Page
        active={active}
        className="page--members"
      >
        <Content>
          <Breadcrumbs
            src={[
              {
                text: <FormattedMessage id="portlet.section.home" />,
                url: '#/'
              },
              {
                text: <FormattedMessage id="portlet.section.members" />,
                url: '#/members'
              }
            ]}
          />
        </Content>
        <MembersComponent
          offices={offices.offices}
          querying={!!(offices.querying || hero.querying)}
          error={offices.error || hero.error}
        />
      </Page>
    );
  };

  componentDidMount = () => {
    this.props.getOffices();
    this.props.getHero();
  };
  static propTypes = {
    offices: ReducerPropType({ offices: PropTypes.arrayOf(OfficePropType) })
      .isRequired,
    active: PropTypes.string.isRequired
  };
}
