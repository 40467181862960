import React from 'react';
import { FormattedMessage } from 'react-intl';

export const VALID_EMAIL = 'dario.lopezllueca@soprasteria.com';
export const EUIPOS_CATEGORY = 'EUIPOs';
export const USER_ASSOCIATION_CATEGORY = 'UserAssociation';
export const OTHER_MEMBERS_CATEGORY = 'OtherMembers';
export const NON_EU_IPOS_CATEGORY = 'Non EU IPOs';
export const MAX_PINS = 3;
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LANGUAGE_TITLE = 'English';
export const FONT_SIZE_COOKIE_NAME = 'fontSize';

export const DEFAULT_COOKIES_BANNER = {
    bannerContent:
      'This site uses cookies to offer you a better browsing experience, including for anonymized analytics. Find out more about how we use <a href=\"network/data-protection\">Cookies</a>',
  cookieName: 'data_protection_cookie',
  cookieValue: 'read',
  accepted: false
};

export const TOOL_PIN_TOOLTIP_PINNED = (
  <FormattedMessage id="portlet.tooltip.tools.pin_favourite.hover_pinned_item" />
);
export const TOOL_PIN_TOOLTIP_UNPINNED = (
  <FormattedMessage id="portlet.tooltip.tools.pin_favourite.hover_unpinned_item" />
);
export const TOOL_PIN_TOOLTIP_UNPINNED_MAX = (
  <FormattedMessage id="portlet.tooltip.tools.pin_favourite.max_pinned_items" />
);

export const ECP_PIN_TOOLTIP_PINNED = (
  <FormattedMessage id="portlet.tooltip.ecps.pin_favourite.hover_pinned_item" />
);
export const ECP_PIN_TOOLTIP_UNPINNED = (
  <FormattedMessage id="portlet.tooltip.ecps.pin_favourite.hover_unpinned_item" />
);
export const ECP_PIN_TOOLTIP_UNPINNED_MAX = (
  <FormattedMessage id="portlet.tooltip.ecps.pin_favourite.max_pinned_items" />
);

export const PRACTICE_PIN_TOOLTIP_PINNED = (
  <FormattedMessage id="portlet.tooltip.practices.pin_favourite.hover_pinned_item" />
);
export const PRACTICE_PIN_TOOLTIP_UNPINNED = (
  <FormattedMessage id="portlet.tooltip.practices.pin_favourite.hover_unpinned_item" />
);
export const PRACTICE_PIN_TOOLTIP_UNPINNED_MAX = (
  <FormattedMessage id="portlet.tooltip.practices.pin_favourite.max_pinned_items" />
);
export const NEWS_PER_PAGE = 5;
export const EVENTS_PER_PAGE = 5;

export const TEST_SEARCHTYPE = 'News';
export const DEFAULT_EVENTS_FILTERS = { offices: [], topics: [], webinar: [] };
export const DEFAULT_NEWS_FILTERS = { topics: [] };
export const DEFAULT_TYPE_SEARCH = 'All';

export const TEST_AUTH_TOKEN = 'phLnl1L2FNHiuGh';
export const TEST_NEWSITEM_ID = 200666;
export const TEST_EVENT_ID = 296247;
export const TEST_TOOL_ID = 305304;
export const TEST_ECP_ID = 305314;
export const TEST_PRACTICE_ID = 305424;
export const TEST_SEARCH_TYPE = 'All';
