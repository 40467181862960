import moment from '../libs/moment';

export const fromUnixToLocalString = date => moment(date).format('DD/MM/YYYY');

export const fromMinToMilliseconds = minutes => minutes * 60 * 1000;

export const clampText = (text, maxLength) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

export const shuffle = (array) => {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const order = ( array ) => {
  return array.sort( compare );
}

const compare = ( a, b ) => {
  return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
}

