import {
  NEWS_FILTERS_RECEIVED,
  NEWS_FILTERS_REQUEST_FAILED,
  NEWS_FILTERS_REQUEST_FINISHED,
  NEWS_FILTERS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  filters: null,
  error: null
};

export const newsFiltersRequested = state => ({ ...state, querying: true });

export const newsFiltersReceived = (state, action) => ({
  ...state,
  filters: action.payload.filters,
  error: null
});

export const newsFiltersRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  filters: null
});

export const newsFiltersRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [NEWS_FILTERS_REQUESTED]: newsFiltersRequested,
  [NEWS_FILTERS_RECEIVED]: newsFiltersReceived,
  [NEWS_FILTERS_REQUEST_FAILED]: newsFiltersRequestFailed,
  [NEWS_FILTERS_REQUEST_FINISHED]: newsFiltersRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
