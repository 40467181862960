import { connect } from 'react-redux';

import Ecps from './component';
import { pinEcp } from '../../redux/ecp/action-creators';
import {
  getEcps,
  createCookieState as createEcpsCookieState,
  changeCookieState
} from '../../redux/ecps/action-creators';

export const mapStateToProps = state => ({
  ecps: state.ecps,
  cookieState: state.ecps.cookieState,
  isUserLoged: !!state.user.object
});

export const mapDispatchToProps = dispatch => ({
  changeCookie: () => dispatch(changeCookieState()),
  pinEcp: id => dispatch(pinEcp(id)),
  getEcps: () => dispatch(getEcps()),
  createCookie: () => dispatch(createEcpsCookieState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ecps);
