import styled from 'styled-components';
import { Block as B, Navigation, Cookie as CookieLucentum } from 'lucentum';
import { them } from '../../commonStyles';

import { calculateCardHeight, calculateTitleCardHeight } from '../advanced-card/styles';

import { calculateMarginMembers } from '../members/styles';

import { calculateMarginCheckboxes } from '../filters-menu/styles';

export const Block = styled(B)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Content = styled(B)`
  flex: 1 0 auto;
  color: ${them('fontColor')};
  background: linear-gradient(
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.25)
    ),
    url(${({ backgroundImg }) => backgroundImg});
  background-position: 100% 0%;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: relative;
  right: 0;
  top: 0;

  @media (${them('breakpointMaxMD')}) {
    :nth-child(2) {
      margin-top: calc(${them('sizeDouble')}*1.857);
    }
    background-image: none;
  }

  .advanced-card {
    height: ${({ currentFontSize }) => calculateCardHeight(currentFontSize)};

    .title{
      overflow: hidden;
      max-height: ${({ currentFontSize }) => calculateTitleCardHeight(currentFontSize)};
    }


    @media (${them('breakpointMaxMD')}) {
      .title{
        height: 24px;
      }
    }

    .short-description {
      overflow: hidden;
      max-height: 94px;
    }
  }



  .filters--calculate-margin {
    form {
      fieldset {
        label {
          > input {
            margin-top: ${({ currentFontSize }) =>
  calculateMarginCheckboxes(currentFontSize)};
          }
        }
      }
    }
  }

  .block-members {
    margin-top: ${({ currentFontSize }) =>
  calculateMarginMembers(currentFontSize)};
  }
}
`;

export const NavigationMenu = styled(Navigation)`
  flex-wrap: wrap;
  align-self: end;
`;

// export const NavigationFooterMenu = styled(Navigation)`
//   // margin-top: ${({ theme, type }) => type !== 'vertical' && theme.sizeDouble};
//
// `;

export const NavigationFooterMenu = styled(Navigation)`
    flex-wrap: wrap;
    justify-content: center;  
  > a {
    margin: 0 15px; 
  }
  .contact--launch{
    margin-top: -5px;
  }  
`;

export const CookieBlock = styled(Content)`
  position: fixed;
  z-index: 100;
`;

export const Cookie = styled(CookieLucentum)`
  position: fixed;
  bottom: 0;
  z-index: 200;
  > div:first-child > div:first-child > div:first-child > div:first-child {
    width: auto;
    margin-right: ${({ theme }) => theme.sizeSmall};
    i {
      width: auto;
      height: 83px;
    }
  }
`;

export const BlockContact = styled(Block)`
  font-size: 15px;
`;
