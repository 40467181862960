import {
  NEWS_ITEM_RECEIVED,
  NEWS_ITEM_REQUEST_FAILED,
  NEWS_ITEM_REQUEST_FINISHED,
  NEWS_ITEM_REQUESTED
} from './types';
import api from '../../utils/api';

export const getNewsItemRequested = () => ({
  type: NEWS_ITEM_REQUESTED,
  payload: null
});

export const getNewsItemSucceed = newsItem => ({
  type: NEWS_ITEM_RECEIVED,
  payload: { newsItem }
});

export const getNewsItemFailed = er => ({
  type: NEWS_ITEM_REQUEST_FAILED,
  payload: { error: er }
});

export const getNewsItemFinished = () => ({
  type: NEWS_ITEM_REQUEST_FINISHED,
  payload: null
});

export const getNewsItem = newsItemId => async dispatch => {
  dispatch(getNewsItemRequested());
  try {
    const { data: newsItem } = await api.getNewsItem(newsItemId);
    dispatch(getNewsItemSucceed(newsItem));
  } catch (error) {
    dispatch(getNewsItemFailed(error.response.data));
  } finally {
    dispatch(getNewsItemFinished());
  }
};
