import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavigationFooterMenu } from './styles';
import Link from '../navlink';


export default ({ active, type, user }) => (
  <NavigationFooterMenu>
    <Link
      to="#/"
      active={active === '/' && type !== 'vertical'}
      activeVertical={type === 'vertical' && active === '/'}
      hiddenXS
      className="navigation--home"
    >
      <FormattedMessage id="portlet.section.home" tagName="strong"/>
    </Link>

    <Link bold="true"
          to="#/ecps"
          className="navigation--ecps"
    >
      <FormattedMessage id="portlet.section.ecps"
                        tagName="strong"/>
    </Link>


    <Link bold="true"
          to="#/members"
          className="navigation--members"
    >
      <FormattedMessage id="portlet.section.members"
                        tagName="strong"/>
    </Link>

    <Link bold="true"
          to="#/tools"
          className="navigation--tools"
    >
      <FormattedMessage id="portlet.section.tools"
                        tagName="strong"/>
    </Link>


    <Link bold="true"
          to="#/practices"
          active={
            type !== 'vertical' &&
            ['/practices', '/common-practices'].includes(active)
          }
          activeVertical={
            type === 'vertical' &&
            ['/practices', '/common-practices'].includes(active)
          }
          className="navigation--practices"
    >
      <FormattedMessage id="portlet.section.practices"
                        tagName="strong"/>
    </Link>

    <Link bold="true"
          to="#/events"
          className="navigation--events"
    >
      <FormattedMessage id="portlet.section.events"
                        tagName="strong"/>
    </Link>

    <Link bold="true"
          to="#/news"
          className="navigation--news"
    >
      <FormattedMessage id="portlet.section.news"
                        tagName="strong"/>
    </Link>
    <Link bold="true"
        to="/network/international-cooperation"
        className="navigation--international-cooperation"
    >
      <FormattedMessage id="menu.link.international-cooperation" tagName="strong"/>
    </Link>
    <Link bold="true"
          to="#/contact"
          className="navigation--news"
          target="_blank"
    >
      <FormattedMessage id="portlet.label.contact.title"
                        tagName="strong"/>
    </Link>

  </NavigationFooterMenu>

);

