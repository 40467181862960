import styled from 'styled-components';
import { Block } from 'lucentum';
import { them } from '../../commonStyles';
import sizes from '../page/sizes';

export const calculateMarginMembers = currentFontSize => {
  const memberTitleMarginTop = {
    'font.size.small': '60px',
    'font.size.medium': '110px',
    'font.size.large': '136px',
    'font.size.extralarge': '156px'
  };
  const size = sizes.find(({ size }) => size === currentFontSize);
  return size ? memberTitleMarginTop[size.value] : memberTitleMarginTop['font.size.medium'];
};

export const BlockResponsive = styled(Block)`
  @media (${them('breakpointMaxMD')}) {
    margin: 0;
  }
`;

export const CategoryBlock = styled(Block)`
  > div:first-child {
    align-items: flex-end;
    > h1 {
      line-height: 1;
    }
  }
`;

export const MembersWidget = styled(Block)`
  @media (${them('breakpointMaxMD')}) {
    padding: 1em;
  }
`;

export const MembersWidgetBlock = styled(Block)`
  font-size: ${({ theme }) => theme.fontSize};
  margin: 0;
  
  > span {
    color: ${({ theme }) => theme.colorGray};
    > span {
      color: #2f509a;
      font-weight: bold;
    }
  }
`;

export const MembersList = styled(Block)`
  > div {
    border: none;
    margin: 0;

    h1 {
      font-size: ${({ theme }) => theme.fontSizeMedium};
      font-weight: bold;
    }
  }

  @media (${them('breakpointMaxMD')}) {
    a {
      padding-top: ${them('sizeDouble')};
      font-size: ${({ theme }) => theme.fontSize};
      color: ${({ theme }) => theme.colorGray};
    }

    > div:first-child {
      margin-top: ${them('sizeDouble')};
      h1 {
        font-size: ${({ theme }) => theme.fontSize};
        font-weight: bold;
      }
    }
  }
`;
