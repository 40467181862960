import {
  HERO_RECEIVED,
  HERO_REQUEST_FAILED,
  HERO_REQUEST_FINISHED,
  HERO_REQUESTED
} from './types';

import api from '../../utils/api';

export const getHeroRequested = () => ({
  type: HERO_REQUESTED,
  payload: null
});

export const getHeroSucceed = hero => ({
  type: HERO_RECEIVED,
  payload: { hero }
});

export const getHeroFailed = er => ({
  type: HERO_REQUEST_FAILED,
  payload: { error: er }
});

export const getHeroFinished = () => ({
  type: HERO_REQUEST_FINISHED,
  payload: null
});

export const getHero = () => async dispatch => {
  dispatch(getHeroRequested());
  try {
    const { data: hero } = await api.getHero();
    dispatch(getHeroSucceed(hero));
  } catch (error) {
    dispatch(getHeroFailed(error.response.data));
  } finally {
    dispatch(getHeroFinished());
  }
};
