import styled from 'styled-components';
import { Block, Button, Form } from 'lucentum';

export const StyledBlock = styled(Block)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
export const StyledBlockInput = styled(Block)`
  flex: 3;
`;

export const StyledButton = styled(Button)`
  flex: 1;
`;

export const StyledForm = styled(Form)`
  h3 {
    color: ${({ theme }) => theme.colorWhite}
    font-size: ${({ theme }) => theme.fontSizeMedium}
    font-weight: bold;
  }

  i {
    color: ${({ theme }) => theme.colorWhite};
    font-size: ${({ theme }) => `calc(${theme.fontSizeLarge} * 2)`};
  }
`;
