import styled from 'styled-components';
import { News as LucentumNews } from 'lucentum';

export const News = styled(LucentumNews)`
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column-reverse;

    & > div:last-child a {
      line-height: 2;
      color: ${({ theme }) => theme.colorGrayLight};
    }
  }
`;
