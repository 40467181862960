import React, { Component } from 'react';
import { Block, Icon } from 'lucentum';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { MarginLessButton } from './styles';

class UploadFile extends Component {
  state = {
    uploadButtonText: (
      <FormattedMessage id="portlet.label.contact.attach_file">
        {message => <span>{message}</span>}
      </FormattedMessage>
    )
  };

  handleClick = e => {
    e.preventDefault();
    this.input.click();
  };

  handleChange = e => {
    if (e.target.files.length) {
      this.setState({ uploadButtonText: e.target.files[0].name });
      this.props.handleUpload(e);
    }
  };

  render = () => (
    <Block>
      <Block hidden>
        <input
          type="file"
          name="file"
          onChange={this.handleChange}
          ref={i => (this.input = i)}
        />
      </Block>
      <MarginLessButton onClick={this.handleClick}>
        <Icon file />
        {this.state.uploadButtonText}
      </MarginLessButton>
    </Block>
  );

  static propTypes = {
    handleUpload: PropTypes.func
  };

  static defaultProps = {
    handleUpload: null
  };
}

export default UploadFile;
