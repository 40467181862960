import React from 'react';
import PropTypes from 'prop-types';
import { Content } from 'lucentum';
import { Page, Event as EventComponent } from '../../components';
import {
  Event as EventPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs';
import { FormattedMessage } from 'react-intl';

const Event = ({ event, active }) => (
  <Page active={active}>
    <Content>
      <Breadcrumbs
        src={[
          { text: <FormattedMessage id="portlet.section.home" />, url: '#/' },
          {
            text: <FormattedMessage id="portlet.section.events" />,
            url: '#/events'
          },
          { text: '', url: '#/events' }
        ]}
      />
    </Content>
    <EventComponent
      event={event.event}
      querying={event.querying}
      error={event.error}
    />
  </Page>
);

Event.propTypes = {
  event: ReducerPropType({ event: EventPropType }).isRequired,
  active: PropTypes.string.isRequired
};

export default Event;
