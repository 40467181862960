import React, { Component } from 'react';
import { Content } from 'lucentum';
import Page from '../../components/page';
import CommonPractices from '../../components/common-practices';

export default class extends Component {
  render = () => {
    const { active, categories, querying, error } = this.props;
    return (
      <Page active={active} className="page--common-practices">
        <Content>
          <CommonPractices
            categories={categories}
            querying={querying}
            error={error}
          />
        </Content>
      </Page>
    );
  };

  componentDidMount = () => this.props.getCommonPractices();
}
