import {
  TOOLS_RECEIVED,
  TOOLS_REQUEST_FAILED,
  TOOLS_REQUEST_FINISHED,
  TOOLS_REQUESTED,
  CREATE_TOOLS_COOKIE_STATE,
  TOOLS_PIN_TOOL_SUCCEED,
  TOOLS_PIN_TOOL_FAILED
} from './types';
import api from '../../utils/api';
import Cookies from 'js-cookie';

export const getToolsRequested = () => ({
  type: TOOLS_REQUESTED,
  payload: null
});

export const getToolsSucceed = tools => ({
  type: TOOLS_RECEIVED,
  payload: { tools }
});

export const getToolsFailed = er => ({
  type: TOOLS_REQUEST_FAILED,
  payload: { error: er }
});

export const getToolsFinished = () => ({
  type: TOOLS_REQUEST_FINISHED,
  payload: null
});

export const setCookieState = cookieState => ({
  type: CREATE_TOOLS_COOKIE_STATE,
  payload: { cookieState }
});

export const pinToolSucceed = toolId => ({
  type: TOOLS_PIN_TOOL_SUCCEED,
  payload: { toolId }
});

export const pinToolFailed = error => ({
  type: TOOLS_PIN_TOOL_FAILED,
  payload: { error }
});

export const getTools = () => async dispatch => {
  dispatch(getToolsRequested());
  try {
    const { data: tools } = await api.getTools();
    dispatch(getToolsSucceed(tools));
  } catch (error) {
    dispatch(getToolsFailed(error.response.data));
  } finally {
    dispatch(getToolsFinished());
  }
};

export const createCookieState = () => dispatch => {
  const cookieState = !!Cookies.get('closeToolsNotification');
  dispatch(setCookieState(cookieState));
};

export const changeCookieState = () => dispatch => {
  Cookies.set('closeToolsNotification', 'true', { path: '/' });
  dispatch(setCookieState(true));
};
