import {
  SUBSCRIBE_RECEIVED,
  SUBSCRIBE_REQUEST_FAILED,
  SUBSCRIBE_REQUEST_FINISHED,
  SUBSCRIBE_REQUESTED
} from './types';

import api from '../../utils/api';

export const subscribeNewsletterRequested = () => ({
  type: SUBSCRIBE_REQUESTED,
  payload: null
});

export const subscribeNewsletterSucceed = subscribed => ({
  type: SUBSCRIBE_RECEIVED,
  payload: { subscribed }
});

export const subscribeNewsletterFailed = er => ({
  type: SUBSCRIBE_REQUEST_FAILED,
  payload: { error: er }
});

export const subscribeNewsletterFinished = () => ({
  type: SUBSCRIBE_REQUEST_FINISHED,
  payload: null
});

export const subscribeNewsletter = email => async dispatch => {
  dispatch(subscribeNewsletterRequested());
  try {
    await api.subscribeToNewsletter(email);
    dispatch(subscribeNewsletterSucceed(true));
  } catch (error) {
    dispatch(subscribeNewsletterFailed(error.response.data));
  } finally {
    dispatch(subscribeNewsletterFinished());
  }
};
