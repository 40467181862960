import PropTypes from 'prop-types';

const filter = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
});

export default PropTypes.shape({
  offices: PropTypes.arrayOf(filter).isRequired,
  topics: PropTypes.arrayOf(filter).isRequired,
  webinar: PropTypes.arrayOf(filter).isRequired
});
