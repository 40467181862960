export default {
  color: '#2f509a',
  colorMenuGray: '#7e7e7e',
  colorGray: '#3f3f3f',
  colorGrayAccent: '#f7f7f7',
  colorGrayLight: '#9e9d9d',
  colorResponsiveGray: '#4a4a4a',
  colorInputError: '#f0ad4e',
  colorBlack: '#000000',
  fontFamily: 'Open Sans',
  fontSize: '1rem',
  fontColor: '#3f3f3f',
  h3CurtainBgColor: '#e0e0e0',
  newsletterIconColor: '#bac6d3',
  // reference: https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
  breakpointXS: 'min-width: 320px',
  breakpointSM: 'min-width: 576px',
  breakpointMD: 'min-width: 768px',
  breakpointLG: 'min-width: 992px',
  breakpointXL: 'min-width: 1200px',
  breakpointMaxXS: 'max-width: 575.98px',
  breakpointMaxSM: 'max-width: 767.98px',
  breakpointMaxMD: 'max-width: 991.98px',
  breakpointMaxLG: 'max-width: 1199.98px'
  // No media query since the extra-large breakpoint has no upper bound on its width
  // breakpointMaxXL: "max-width: Infinity"
};
