import React from 'react';

import { Block, Link, Icon, Navigation } from 'lucentum';
import { FormattedMessage } from 'react-intl';

export default () => (
  <Block hiddenLG marginTop>
    <Navigation>
      <Link primary href="#/contact">
        <Icon mail />
        <FormattedMessage id="portlet.action.contact_us" />
      </Link>
      <Link href="/network/accessibility">
        <FormattedMessage id="portlet.common.footer.accessibility" />
      </Link>
      <Link href="/network/data-protection">
        <FormattedMessage id="portlet.common.footer.data_protection" />
      </Link>
      <Link href="/network/legal-notices">
        <FormattedMessage id="portlet.common.footer.legal_notice" />
      </Link>
    </Navigation>
    <Block right>
      <FormattedMessage
        tagName="p"
        id="portlet.common.footer.copyright"
        values={{ year: new Date().getFullYear() }}
      />
    </Block>
  </Block>
);
