import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Page from '../../components/page';
import ToolsComponent from '../../components/tools';
import {
  Tool as ToolPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { FormattedMessage } from 'react-intl';
import { Content } from 'lucentum';

export default class Tools extends Component {
  render = () => {
    const {
      tools,
      active,
      cookieState,
      changeCookie,
      pinTool,
      isUserLoged
    } = this.props;
    return (
      <Page active={active} className="page--tools">
        <Content>
          <Breadcrumbs
            src={[
              {
                text: <FormattedMessage id="portlet.section.home" />,
                url: '#/'
              },
              {
                text: <FormattedMessage id="portlet.section.tools" />,
                url: '#/tools'
              }
            ]}
          />
        </Content>
        <ToolsComponent
          tools={tools.tools}
          querying={tools.querying}
          error={tools.error}
          cookieState={cookieState}
          createCookie={changeCookie}
          pinTool={pinTool}
          isUserLoged={isUserLoged}
        />
      </Page>
    );
  };

  componentDidMount = () => {
    this.props.getTools();
    this.props.createCookie();
  };

  static propTypes = {
    tools: ReducerPropType({
      tools: PropTypes.arrayOf(ToolPropType)
    }).isRequired,
    active: PropTypes.string.isRequired
  };
}
