import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { P } from 'lucentum';

import moment from '../../libs/moment';
import sizes from './sizes';
import Footer from '../footer';
import Header from '../header';
import RenderMenu from './menu';
import RenderFooterMenu from './footerMenu';
import RenderContact from './contact';
import { Copyright } from '../copyright';
import { Block, Content, Cookie } from './styles';
import logoHeader from '../../assets/imgs/logoheader.svg';
import logoFooter from '../../assets/imgs/logoFooter.png';
import { Language as LanguagePropType } from '../../propTypes';
import {Button} from 'lucentum';


const extractPageName = page =>
  page && page.substr(1).length ? page.substr(1) : 'home';

const getCurrentPage = page => (
  <FormattedMessage id={`portlet.${extractPageName(page)}.mobile.title`} />
);

const Page = ({
  active,
  changeLanguage,
  getStuff,
  children,
  currentLanguage,
  curtain = false,
  getLabels,
  languages,
  showSearch = true,
  userObj,
  className,
  saveFontSize,
  backgroundImg,
  currentFontSize,
  acceptCookieLawSucceed,
  acceptCookieLawFailed,
  cookieContent,
  shouldShowCookieBanner,
  getMatomoProperties,
  ...props
}) => (
  <Block className={className}>
    <Header
      currentFontSize={currentFontSize}
      currentLang={currentLanguage}
      currentPage={getCurrentPage(active)}
      fontSizes={sizes}
      language={!curtain && languages}
      login={!curtain && userObj}
      logo={logoHeader}
      logoAlt={logoFooter}
      onLanguage={({ key: locale }) => {
        //moment.locale(locale);
        changeLanguage(locale);
      }}
      activePage={extractPageName(active)}
      textSignUp={<FormattedMessage id="common.sign.up" />}
      textSignIn={<FormattedMessage id="common.sign.in" />}
      textSignOut={<FormattedMessage id="portlet.action.sign_out" />}
      showSearch={showSearch}
      onResize={saveFontSize}
      onSignIn={() => {
        window.location.assign(
          `/ms-cgateway/login?service=${window.location.origin}%2Fnetwork%2Fc%2Fportal%2Flogin`
        );
      }}
      onSignUp={() => {
        window.location.assign('/network/details');
      }}
      {...props}
    >
      {!curtain && <RenderMenu user={userObj} type="" active={active} />}
    </Header>
    <Content currentFontSize={currentFontSize} backgroundImg={backgroundImg}>
      {children}
    </Content>
    {shouldShowCookieBanner && (
      <Content >
        <Cookie onClose={() => 
        acceptCookieLawSucceed()
        }>
        <p dangerouslySetInnerHTML={{ __html: cookieContent }}></p>
          
      {/* Add Accept and Not Accept buttons */}
    
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button  className="button" onClick={() => {
          //set cookie value read
          acceptCookieLawSucceed();
          getMatomoProperties();
  
        }}>
          <FormattedMessage id="cookieAccept" />
        </Button>
        <Button className="button" onClick={() => {
          // set cookie value reject
         
         acceptCookieLawFailed();
          getMatomoProperties();
         
        }}>
         <FormattedMessage id="cookieReject"/>
        
        </Button>
       
      </div>
     
        </Cookie>
      </Content>)}

    <Footer
      renderContact={!curtain && RenderContact}
      renderCopyright={Copyright}
      // logo={logoFooter}
      
      style={shouldShowCookieBanner ? { marginBottom: '72px' } : null}
      
    >
      {!curtain && (
        <RenderFooterMenu user={userObj} type="horizontal" active={active} />
      )}
     
    </Footer>
  </Block>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  languages: PropTypes.arrayOf(LanguagePropType),
  active: PropTypes.string,
  backgroundImg: PropTypes.string
};

Page.defaultProps = {
  languages: [],
  user: [],
  backgroundImg: ''
};

export default Page;
