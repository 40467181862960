import {
  NEWS_ITEM_RECEIVED,
  NEWS_ITEM_REQUEST_FAILED,
  NEWS_ITEM_REQUEST_FINISHED,
  NEWS_ITEM_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  newsItem: null,
  error: null
};

export const newsItemRequested = state => ({ ...state, querying: true });

export const newsItemReceived = (state, action) => ({
  ...state,
  newsItem: action.payload.newsItem,
  error: null
});

export const newsItemRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  newsItem: null
});

const newsItemRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [NEWS_ITEM_REQUESTED]: newsItemRequested,
  [NEWS_ITEM_RECEIVED]: newsItemReceived,
  [NEWS_ITEM_REQUEST_FAILED]: newsItemRequestFailed,
  [NEWS_ITEM_REQUEST_FINISHED]: newsItemRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
