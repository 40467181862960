import React from 'react';
import { Image, P, H2, Link, Block, Spinner, H1 } from 'lucentum';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import ContinentMap from '../continent-map';
import europe from './europe.json';
import map from '../../assets/topo/mapmini.json';
import ErrorMessage from '../error-message';
import { BlockTitle } from '../../commonStyles/homeTooltipStyles';

import { Member as MemberPropType } from '../../propTypes';

export const details = ({ image, number, joined, updated, officeId }) => (
  <div className="map__details">
    <Image src={image} />
    {/*<P>*/}
    {/*  <FormattedMessage id="portlet.action.members.covered" />*/}
    {/*</P>*/}
    {/*<H2>*/}
    {/*  <FormattedNumber value={+number} />*/}
    {/*</H2>*/}
    <P>
      <FormattedMessage id="portlet.action.members.joined" />
    </P>
    <H2>{joined}</H2>
    {/*<P>*/}
    {/*  <FormattedMessage id="portlet.action.members.updated" />*/}
    {/*</P>*/}
    {/*<H2>{updated}</H2>*/}
    <Link to={`#/members/${officeId}`}>
      <FormattedMessage id="portlet.action.members.goto" />
    </Link>
  </div>
);

const parsedInclude = europe.filter(countryCode => countryCode !== 'ES')

const MapComponent = ({ members, error, querying }) => {
  if(members && Array.isArray(members.countries) && members.countries.length) {
    members.countries = members.countries.filter(country => country.officeId!=1810023 && country.officeId!=1809990 );
  }
  return (
    <Block hiddenXS visibleLG paddingTop>
      <BlockTitle>
        <H1>
          <FormattedMessage id="portlet.section.euipn_members" />
        </H1>
      </BlockTitle>
      {querying && (
        <Block center>
          <Spinner show row size={3} />
        </Block>
      )}
      {!querying && error && <ErrorMessage />}
      {!querying && members && (
        <ContinentMap
          src={members}
          topojsonUrl={map}
          include={europe}
          tooltipText={<FormattedMessage id="portlet.action.members.covered" />}
          officeDetail={details}
        />
      )}
    </Block>
  );
};

// const MapComponent = ({members, error, querying}) => {
//
//   console.log(members);
//
// return (
//
//   <Block hiddenXS visibleLG paddingTop>
//     <BlockTitle>
//       <H1>
//         <FormattedMessage id="portlet.section.euipn_members"/>
//       </H1>
//     </BlockTitle>
//     {querying && (
//       <Block center>
//         <Spinner show row size={3}/>
//       </Block>
//     )}
//     {!querying && error && <ErrorMessage/>}
//     {!querying && members && (
//       <ContinentMap
//         src={members}
//         topojsonUrl={map}
//         include={parsedInclude}
//         tooltipText={<FormattedMessage id="portlet.action.members.covered"/>}
//         officeDetail={details}
//       />
//     )}
//   </Block>
// )};

MapComponent.propTypes = {
  members: MemberPropType
};

export default MapComponent
