import React from 'react';
import { Page, Curtain } from '../../components/';

const CurtainPage = () => (
  <Page
    curtain
    language={false}
    renderContact={false}
    showSearch={false}
    textSignIn={false}
    textSignUp={false}
  >
    <Curtain />
  </Page>
);

export default CurtainPage;
