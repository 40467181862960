import React from 'react';
import PropTypes from 'prop-types';
import { Block, Spinner } from 'lucentum';
import ErrorMessage from '../error-message';
import { FormattedMessage } from 'react-intl';
import { StyledTitle, BlockResponsive } from '../../commonStyles';
import {
  CommonPracticeBlock,
  CommonPracticeLink as StyledCommonPracticeLink
} from './styles';

export const CommonPracticeLink = ({ title, downloadUrl }) => (
  <StyledCommonPracticeLink to={downloadUrl} target="_blank">
    {title}
  </StyledCommonPracticeLink>
);
CommonPracticeLink.propTypes = {
  title: PropTypes.string.isRequired,
  downloadUrl: PropTypes.string.isRequired
};

const CommonPractices = ({ querying, categories, error }) => (
  <Block>
    {querying && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {!querying && error && <ErrorMessage />}
    {!querying && categories && (
      <BlockResponsive>
        <Block hiddenXS visibleSM marginTop marginBottom>
          <StyledTitle>
            <FormattedMessage id="portlet.practices.common_practices.title" />
          </StyledTitle>
        </Block>
        <Block marginTop>
          {categories.map(({ title: categoryTitle, documents }, i) => (
            <CommonPracticeBlock
              title={categoryTitle}
              key={i}
              widthSM1of3
              widthXS1of1
              paddingRight={2}
            >
              {documents.map(({ title: documentTitle, downloadUrl }, j) => (
                <CommonPracticeLink
                  downloadUrl={downloadUrl}
                  title={documentTitle}
                  key={j}
                />
              ))}
            </CommonPracticeBlock>
          ))}
        </Block>
      </BlockResponsive>
    )}
  </Block>
);
CommonPractices.propTypes = {
  querying: PropTypes.bool,
  error: PropTypes.instanceOf(Error),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      documents: PropTypes.arrayOf(CommonPracticeLink)
    })
  )
};
export default CommonPractices;
