import styled from 'styled-components';
import { Button, Block, Modal } from 'lucentum';
import { DetailedNews } from '../../components/';

export const BlockStyled = styled(Block)`
  margin: auto ${({ theme }) => theme.sizeSmaller};
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizeMedium};
`;

export const StyledDetailedNews = styled(DetailedNews)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    margin-top: ${({ theme }) => theme.size};
    & > div span {
      margin-bottom: ${({ theme }) => theme.sizeSmall};
    }
  }
`;

export const ButtonStyles = styled(Button)`
  @media (${({ theme }) => theme.breakpointMaxXS}) {
    width: calc(${({ theme }) => theme.sizeDouble} * 5.2);
    height: calc(${({ theme }) => theme.sizeDouble} * 1.4);
    margin-bottom: ${({ theme }) => theme.sizeLarge};
  }

  width: 95%;
  height: 39px;
  margin-bottom: 20px;
`;

export const ButtonStyled = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizeSmaller};
`;

export const ModalStyled = styled(Modal)`
  margin: auto !important;
  min-width: auto !important;
  font-family: ${({ theme }) => theme.fontFamily};

  label span {
    color: ${({ theme }) => theme.colorGray};
  }

  div > button:nth-child(2) {
    position: absolute;
    right: 8px;
  }
  div {
    border-bottom: none;
    justify-content: space-around;
    background-color: #ffffff;
  }

  > div {
    padding: ${({ theme }) => theme.size};
  }

  div > div {
    justify-content: unset;
  }

  button:nth-of-type(2) {
    display: none;
  }

  span button:last-of-type {
    display: initial;
    width: auto;
    height: auto;
    font-weight: 700;
  }
  button:last-of-type {
    width: auto;
    height: auto;
    margin-left: unset;
  }

  span button:only-child {
    position: absolute;
    left: ${({ theme }) => theme.size};
    padding: unset;
    padding-top: 1.6px;
  }
  fieldset {
    padding-bottom: ${({ theme }) => theme.sizeSmall};
    margin-bottom: ${({ theme }) => theme.sizeLarge};
    border-bottom: 1px solid #d8d8d8;
  }
  label {
    margin: 0;
    font-size: calc(${({ theme }) => theme.fontSizeSmaller} + 1);
  }
  label > input {
    margin-right: calc(${({ theme }) => theme.sizeDouble} + 4);
  }
  > div > button:first-child {
    color: ${({ theme }) => theme.colorWhite};
    font-weight: 700;
    width: 100%;
    padding: ${({ theme }) => theme.size};
    font-size: calc(${({ theme }) => theme.fontSizeSmaller} + 1);
  }
  i {
    color: #000000;
    font-size: calc(${({ theme }) => theme.fontSizeDouble} + 8);
  }
  fieldset > div > div > button {
    padding-top: ${({ theme }) => theme.size};
  }
`;
