import { connect } from 'react-redux';
import Newsletters from './component';
import { subscribeNewsletter } from '../../redux/subscribeToNewsletter/action-creators';
import { getNewsletters } from '../../redux/newsletters/action-creators';

export const mapStateToProps = state => ({
  newsletters: state.newsletters,
  subscribedNewsletter: state.subscribedNewsletter
});

export const mapDispatchToProps = dispatch => ({
  handleSubscribeNewsletter: email => dispatch(subscribeNewsletter(email)),
  getNewsletters: () => dispatch(getNewsletters())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Newsletters);
