import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Block, Content, Spinner, Notification, Link } from 'lucentum';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { AdvancedCard } from '../';
import ErrorMessage from '../error-message';
import { PracticesBlock, Description } from './styles';
import {
  StyledTitle,
  BlockResponsive,
  CardsContainer
} from '../../commonStyles/';
import {
  Practice as PracticePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import {
  MAX_PINS,
  PRACTICE_PIN_TOOLTIP_PINNED,
  PRACTICE_PIN_TOOLTIP_UNPINNED,
  PRACTICE_PIN_TOOLTIP_UNPINNED_MAX
} from '../../utils/constants';

export default class Practices extends Component {
  static buildCategories = practices => {
    const res = {};
    practices.forEach(practice => {
      res[practice.category] = [];
    });
    return res;
  };

  static buildPracticesCategoryTree = practices =>
    practices.reduce((prev, practice) => {
      prev[practice.category].push(practice);
      return prev;
    }, Practices.buildCategories(practices));

  handleCloseNotification = () => {
    this.props.createCookie();
  };

  canPin = practices =>
    !!(
      this.props.isUserLoged &&
      practices.filter(tool => tool.favorite).length < MAX_PINS
    );

  isFavorite = practice =>
    practice.favorite ? 'yes' : this.canPin(this.props.practices) ? 'no' : null;

  tooltipMessage = practice =>
    this.isFavorite(practice) === 'yes'
      ? PRACTICE_PIN_TOOLTIP_PINNED
      : this.isFavorite(practice) === 'no'
      ? PRACTICE_PIN_TOOLTIP_UNPINNED
      : '';

  render = () => {
    const { practices, querying, error, isUserLoged } = this.props;
    const categorizedPractices =
      querying || error || !practices
        ? []
        : this.constructor.buildPracticesCategoryTree(practices);

    return (
      <Content>
        {this.shouldShowSpinner() && (
          <Block center>
            <Spinner show row size={10} />
          </Block>
        )}
        {this.shouldShowErrorMessage() && <ErrorMessage />}
        {this.shouldShowPractices() && (
          <BlockResponsive>
            <Block hiddenXS visibleLG marginBottom>
              <StyledTitle>
                <FormattedMessage id="portlet.section.practices" />
              </StyledTitle>
            </Block>
            <Description marginTop>
              <FormattedHTMLMessage
                // id="portlet.practices.description"
                id="porlet.practices.description"
                tagName="div"
              />
            </Description>
            <Block hiddenXS visibleLG>
              {!this.props.cookieState && (
                <Notification onClose={this.handleCloseNotification}>
                  {/*<FormattedHTMLMessage id="portlet.practices.notification" />*/}
                  <FormattedHTMLMessage id="porlet.home.tooltip.info_practices" />
                </Notification>
              )}
            </Block>
            {Object.keys(categorizedPractices).map((category, i) => (
              <Content key={i}>
                <PracticesBlock
                  paddingTop
                  paddingBottom={2}
                  className="category-title"
                  title={category}
                >
                  {categorizedPractices[category].map(practice => (
                    <CardsContainer key={practice.id} widthLG1of3>
                      <AdvancedCard
                        {...practice}
                        favorite={this.isFavorite(practice)}
                        onFavorite={() => {
                          isUserLoged && this.props.pinPractice(practice.id);
                          !this.props.cookieState &&
                            this.handleCloseNotification();
                        }}
                        tooltipMessage={this.tooltipMessage(practice)}
                      />
                    </CardsContainer>
                  ))}
                </PracticesBlock>
              </Content>
            ))}
          </BlockResponsive>
        )}
      </Content>
    );
  };

  shouldShowSpinner = () => this.props.querying;

  shouldShowPractices = () => !this.props.querying && this.props.practices;

  shouldShowErrorMessage = () => !this.props.querying && this.props.error;

  static propTypes = {
    ...ReducerPropType({
      practices: PropTypes.arrayOf(PracticePropType)
    }).isRequired,
    cookieState: PropTypes.bool,
    createCookie: PropTypes.func.isRequired,
    pinPractice: PropTypes.func.isRequired
  };

  static defaultProps = {
    cookieState: false
  };
}
