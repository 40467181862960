import React from 'react';
import { H2, Content, Block, Image } from 'lucentum';

import Initiatives from './initiatives';
import ImgCurtain from '../../assets/imgs/curtainAsset.png';

const Curtain = () => {
  return (
    <Content>
      <Block>
        <Block center>
          <Image src={ImgCurtain} />
        </Block>
        <Block center marginBottom={3}>
          <H2>
            We are sorry, the page you have requested is temporarily
            unavailable.
          </H2>
        </Block>
        <Initiatives />
      </Block>
    </Content>
  );
};

export default Curtain;
