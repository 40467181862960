import styled from 'styled-components';
import { Tooltip as LucentumTooltip } from 'lucentum';

export const Tooltip = styled(LucentumTooltip)`
  &.__react_component_tooltip {
    max-width: 320px;
    max-height: 90%;
    border-radius: 2px;
  }
  &.__react_component_tooltip.place-right {
    margin-left: 20px;
  }

  ::before {
    margin-left: 20px;
  }
`;
