import { connect } from 'react-redux';

import Page from './component';
import { getLabels } from '../../redux/labels/action-creators';
import { changeLanguage } from '../../redux/languages/action-creators';
import { getSearchResults } from '../../redux/search/action-creators';
import { saveFontSize } from '../../redux/user/action-creators';
import { setCookieLawCookie } from '../../redux/cookies/action-creators';
import { getMatomoProperties } from '../../redux/matomo/action-creators';
import { setCookieLawCookieFalse } from '../../redux/cookies/action-creators';


export const mapStateToProps = ({
  languages: { languages = [], activeLanguageCode },
  user,
  i18n,
  cookies: { accepted, bannerContent, }
}) => ({
  languages,
  userObj: user.object,
  userLoading: user.querying,
  currentLanguage: activeLanguageCode,
  i18n,
  currentFontSize: user.fontSize,
  acceptCookieLawSucceed: accepted,
  cookieContent: bannerContent,
  shouldShowCookieBanner: !accepted && !!bannerContent

});

export const mapDispatchToProps = dispatch => ({
  changeLanguage: lang => dispatch(changeLanguage(lang)),
  getLabels: () => dispatch(getLabels()),
  getSearchResults: (searchTerm, searchType) =>
    dispatch(getSearchResults(searchTerm, searchType)),
  saveFontSize: size => dispatch(saveFontSize(size)),
  getMatomoProperties: ()=> dispatch(getMatomoProperties()),
  acceptCookieLawSucceed: () => dispatch(setCookieLawCookie()),
  acceptCookieLawFailed: () => dispatch(setCookieLawCookieFalse())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Page);