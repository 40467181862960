import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Content, Block, Image, Spinner, P } from 'lucentum';
import HtmlDisplay from '../html-display';
import Timeline from '../timeline';
import OfficesIndex from './officesIndex';
import ErrorMessage from '../error-message';
import { EUIPOS_CATEGORY, NON_EU_IPOS_CATEGORY } from '../../utils/constants';
import { Icon } from '../../commonStyles';
import {
  Title,
  BlockResponsive,
  BlockIcon,
  BlockLogo,
  BlockTimeline,
  Button
} from './styles';
import {
  Office as OfficePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import WarningMessage from '../members/warning-message';

const isIPOffice = ({ category }) => category === EUIPOS_CATEGORY;
const isNonIPOffice = ({ category }) => category === NON_EU_IPOS_CATEGORY;

const hasTimeline = office =>
  isIPOffice(office)
    ? Array.isArray(office.featuredImplementations) && office.featuredImplementations.length > 0
    : Array.isArray(office.workingGroupParticipation) &&
    office.workingGroupParticipation.length > 0;

const getOfficeInitiativesNum = initiatives =>
  (initiatives || []).filter(item => item.type === 'Milestone');

export const MemberTimeline = ({ office }) => {

  console.log("MyOfficeImplementations", office)

  return (
    <BlockTimeline
      title={
        (isIPOffice(office) || isNonIPOffice(office)) &&
        // isIPOffice(office) && (
        (
          <FormattedMessage id="portlet.members.timeline.title">
            {message => (
              <span>{`${getOfficeInitiativesNum(office.timeline).length
                } ${message}`}</span>
            )}
          </FormattedMessage>
        )
      }
      marginTop={4}
      marginBottom={4}
    >
      {hasTimeline(office) && (
        <Timeline
          seriesLeft={
            (((office.timeline.length > 0 || (office.implementations && office.implementations.length > 0)) 
            && !(Array.isArray(office.workingGroupParticipation) && office.workingGroupParticipation.length)) || office.id ===1809990  ) 
            && (
              <FormattedMessage id="portlet.members.timeline.tools" />
            )
          }
          seriesCenter={(office.category === EUIPOS_CATEGORY || office.id ===1809990 )?
            <FormattedMessage
              id="portlet.section.ecps"
            /> : null
          }
          seriesRight={(office.timeline.length > 0 || (office.implementations && office.implementations.length > 0)) && (
            <FormattedMessage
              id={
                (office.implementations.length > 0 || office.id ===1809990)
                  ? 'portlet.members.timeline.practices'
                  : office.workingGroupParticipation && 'entity.WorkingGroup'
              } />
          )
          }

          src={office.timeline}
          office={office}
        />
      )}
    </BlockTimeline>
  )
};

export const OfficeIcon = ({ office }) => (
  <BlockIcon width1of5>
    <Icon
      iPOffices={isIPOffice(office)}
      office={!isIPOffice(office)}
      big
      dimmed
    />
  </BlockIcon>
);

const shouldShowError = (querying, error) => !querying && error;

const shouldRenderOffice = (querying, office) => !querying && office;



export const Member = ({ office, offices, querying, error }) => (
  <Content>
    {querying && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {shouldShowError(querying, error) && <ErrorMessage />}
    {shouldRenderOffice(querying, office) && (
      <Block>
        <BlockResponsive>
          {/*<Title>{office.title}</Title>*/}
          <Title>{office.officeName}</Title>
        </BlockResponsive>

        {office.id === 1809990 && <WarningMessage />}

        <Block width2of10 marginTop={2} hiddenXS visibleLG>
          <OfficesIndex
            offices={offices.offices}
            querying={offices.querying}
            error={offices.error}
          />
        </Block>
        <Block widthMD8of10>
          <BlockResponsive width3of6 marginTop={2}>
            <Block middle marginBottom={2}>
              <OfficeIcon office={office} />
              <Block width4of5>
                <HtmlDisplay>{office.contactAddress}</HtmlDisplay>
              </Block>
            </Block>
            <Block middle marginBottom={2}>
              <BlockIcon width1of5>
                <Icon user big dimmed />
              </BlockIcon>
              <Block width4of5>{office.contactPhone}</Block>
            </Block>
            <Block middle marginBottom={2}>
              <BlockIcon width1of5>
                <Icon mail big dimmed />
              </BlockIcon>
              <Block width4of5>
                <P>{office.contactEmail}</P>
                <P>
                  <Button style={{ whiteSpace: 'nowrap' }}
                    className="office--contact--button"
                    link
                    onClick={() => window.open(office.contactWebsite)}
                  >
                    {office.contactWebsite}
                  </Button>
                </P>
              </Block>
            </Block>
          </BlockResponsive>
          <BlockResponsive hidden={!office.logo} width3of6 center>
            <BlockLogo paddingLeft={2}>
              <Image src={office.logo} big />
            </BlockLogo>
          </BlockResponsive>
          <MemberTimeline office={office} />
        </Block>
      </Block>
    )}
  </Content>
);

Member.propTypes = {
  ...ReducerPropType({ office: OfficePropType }).isRequired,
  offices: ReducerPropType({ offices: PropTypes.arrayOf(OfficePropType) })
    .isRequired
};

export default Member;
