import React from 'react';
import { Block } from 'lucentum';
import HtmlDisplay from '../html-display';

import { P, Strong } from './styles';

export default ({ longDescription, projectManager, phone, email }) => (
  <Block>
    <HtmlDisplay>{longDescription}</HtmlDisplay>
    <Block>
      {(projectManager || email) && (
        <P>
          <Strong>Contact details</Strong>
        </P>
      )}
      {projectManager && (
        <P>
          <Strong>Project Manager:</Strong> {projectManager}
        </P>
      )}
      {projectManager && (
        <P>
          <Strong>Phone:</Strong> {phone}
        </P>
      )}
      {email && (
        <P>
          <Strong>email:</Strong> {email}
        </P>
      )}
    </Block>
  </Block>
);
