import {
  SUBMIT_RECEIVED,
  SUBMIT_REQUEST_FAILED,
  SUBMIT_REQUEST_FINISHED,
  SUBMIT_REQUESTED
} from './types';

import api from '../../utils/api';

export const submitFormRequested = () => ({
  type: SUBMIT_REQUESTED,
  payload: null
});

export const submitFormSucceed = () => ({
  type: SUBMIT_RECEIVED,
  payload: { ok: true }
});

export const submitFormFailed = er => ({
  type: SUBMIT_REQUEST_FAILED,
  payload: { error: er }
});

export const submitFormFinished = () => ({
  type: SUBMIT_REQUEST_FINISHED,
  payload: null
});

export const contactSubmit = formData => async dispatch => {
  dispatch(submitFormRequested());
  try {
    await api.contactSubmit(formData);
    dispatch(submitFormSucceed());
  } catch (error) {
    dispatch(submitFormFailed(error.response.data));
  } finally {
    dispatch(submitFormFinished());
  }
};
