import styled from 'styled-components';
import { Block, H2, Icon, Button, Link } from 'lucentum';

export const StyledH2 = styled(H2)`
  color: ${({ theme }) => theme.colorWhite}
  font-size: ${({ theme }) => `calc(${theme.fontSize} + 0.7rem)`};
`;
export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorWhite};
  font-size: ${({ theme }) => `calc(${theme.fontSizeLarge}* 2)`};
`;

export const StyledButton = styled(Button)`
  margin: auto 0;
  flex: 1;
`;

export const StyledLink = styled(Link)`
  color: white;
  margin: ${({ theme }) => theme.sizeSmall} 0 0 0;
  width: auto;
  flex: 1;
  text-align: right;
`;

export const StyledBlock = styled(Block)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledBlockButton = styled(Block)`
  flex: 2;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

export const StyledBlockIcon = styled(Block)`
  flex: 1;
`;
