import {
  FEATURED_TOOLS_RECEIVED,
  FEATURED_TOOLS_REQUEST_FAILED,
  FEATURED_TOOLS_REQUEST_FINISHED,
  FEATURED_TOOLS_REQUESTED,
  FEATURED_TOOLS_PIN_TOOL_SUCCEED,
  FEATURED_TOOLS_PIN_TOOL_FAILED
} from './types';

const INITIAL_STATE = {
  querying: false,
  featuredTools: null,
  error: null
};

export const featuredToolsRequested = state => ({ ...state, querying: true });
export const featuredToolsReceived = (state, action) => ({
  ...state,
  featuredTools: action.payload.featuredTools,
  error: null
});

export const featuredToolsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  featuredTools: null
});

export const featuredToolsRequestFinished = state => ({
  ...state,
  querying: false
});

export const pinToolSucceed = (state, { payload: { toolId } }) => ({
  ...state,
  featuredTools: Array.isArray(state.featuredTools)
    ? state.featuredTools.map(tool => ({
        ...tool,
        favorite: !!(tool.id === toolId ? !tool.favorite : tool.favorite)
      }))
    : state.featuredTools
});

export const pinToolFailed = (state, { payload: { error } }) => ({
  ...state,
  featuredTools: null,
  error
});

const reducersMap = {
  [FEATURED_TOOLS_REQUESTED]: featuredToolsRequested,
  [FEATURED_TOOLS_RECEIVED]: featuredToolsReceived,
  [FEATURED_TOOLS_REQUEST_FAILED]: featuredToolsRequestFailed,
  [FEATURED_TOOLS_REQUEST_FINISHED]: featuredToolsRequestFinished,
  [FEATURED_TOOLS_PIN_TOOL_SUCCEED]: pinToolSucceed,
  [FEATURED_TOOLS_PIN_TOOL_FAILED]: pinToolFailed
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
