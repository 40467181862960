import styled from 'styled-components';
import { Block } from 'lucentum';

export const SearchBlock = styled(Block)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    padding: 0 ${({ theme }) => theme.sizeSmall};
    margin-bottom: ${({ theme }) => theme.size};
  }

  .highlight {
    font-weight: bold;
    color: ${({ theme }) => theme.color};
  }

  .search-button {
    height: 100%;
  }
`;

export const SearchTools = styled(Block)`
  margin-top: ${({ theme }) => theme.size};
  display: flex;
  width: 60%;
  float: none;

  > div:first-child {
    flex: 3;
  }
  > div:nth-child(2) {
    flex: 5;
  }
  > div:nth-child(3) {
    flex: 1;
    text-align: right;
  }
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    width: 100%;
  }
`;
