import {
  GET_LOGGED_USER_FAILED,
  GET_LOGGED_USER_FINISHED,
  GET_LOGGED_USER_RECEIVED,
  GET_LOGGED_USER_REQUESTED,
  CHANGE_FONT_SIZE
} from './types';

export const INITIAL_STATE = {
  querying: false,
  object: null,
  error: null
};

const reducer = {
  [GET_LOGGED_USER_REQUESTED]: state => ({ ...state, querying: true }),
  [GET_LOGGED_USER_RECEIVED]: (state, action) => ({
    ...state,
    object: action.payload.user,
    error: null
  }),
  [GET_LOGGED_USER_FAILED]: (state, action) => ({
    ...state,
    error: action.payload.error,
    object: null
  }),
  [GET_LOGGED_USER_FINISHED]: state => ({ ...state, querying: false }),
  [CHANGE_FONT_SIZE]: (state, action) => ({
    ...state,
    fontSize: action.payload.fontSize
  })
};

export default (state = INITIAL_STATE, action) =>
  reducer[action.type] ? reducer[action.type](state, action) : state;
