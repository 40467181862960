import {
  HERO_CAROUSEL_REQUEST_SUCCEED,
  HERO_CAROUSEL_REQUEST_FAILED,
  HERO_CAROUSEL_REQUEST_FINISHED,
  HERO_CAROUSEL_REQUEST_STARTED
} from './types';
import api from '../../utils/api';

export const getHeroCarouselRequested = () => ({
  type: HERO_CAROUSEL_REQUEST_STARTED,
  payload: null
});

export const getHeroCarouselSucceed = heroCarousel => ({
  type: HERO_CAROUSEL_REQUEST_SUCCEED,
  payload: { heroCarousel }
});

export const getHeroCarouselFailed = er => ({
  type: HERO_CAROUSEL_REQUEST_FAILED,
  payload: { error: er }
});

export const getHeroCarouselFinished = () => ({
  type: HERO_CAROUSEL_REQUEST_FINISHED,
  payload: null
});

export const getHeroCarousel = () => async dispatch => {
  dispatch(getHeroCarouselRequested());
  try {
    const { data: newsletter } = await api.getHeroCarousel();
    dispatch(getHeroCarouselSucceed(newsletter));
  } catch (error) {
    dispatch(getHeroCarouselFailed(error.response.data));
  } finally {
    dispatch(getHeroCarouselFinished());
  }
};
