import {
  CONTACT_US_TOPICS_RECEIVED,
  CONTACT_US_TOPICS_REQUEST_FAILED,
  CONTACT_US_TOPICS_REQUEST_FINISHED,
  CONTACT_US_TOPICS_REQUESTED
} from './types';
import api from '../../utils/api';

export const getContactUsTopicsRequested = () => ({
  type: CONTACT_US_TOPICS_REQUESTED,
  payload: null
});

export const getContactUsTopicsSucceed = contactUsTopics => ({
  type: CONTACT_US_TOPICS_RECEIVED,
  payload: { contactUsTopics }
});

export const getContactUsTopicsFailed = error => ({
  type: CONTACT_US_TOPICS_REQUEST_FAILED,
  payload: { error }
});

export const getContactUsTopicsFinished = () => ({
  type: CONTACT_US_TOPICS_REQUEST_FINISHED,
  payload: null
});

export const getContactUsTopics = () => async dispatch => {
  dispatch(getContactUsTopicsRequested());
  try {
    const { data: contactUsTopics } = await api.getTopicsContactUs();
    dispatch(getContactUsTopicsSucceed(contactUsTopics));
  } catch (error) {
    dispatch(getContactUsTopicsFailed(error.response.data));
  } finally {
    dispatch(getContactUsTopicsFinished());
  }
};
