import {
  HOME_DATA_RECEIVED,
  HOME_DATA_REQUEST_FAILED,
  HOME_DATA_REQUEST_FINISHED,
  HOME_DATA_REQUESTED,
  TOGGLE_NEWSLETTER_WIDGET
} from './types';

const INITIAL_STATE = {
  querying: false,
  success: false,
  error: null,
  showLastNewsletter: null
};

export const homeDataRequested = state => ({ ...state, querying: true });
export const homeDataReceived = (state, action) => ({
  ...state,
  success: true,
  error: null
});

export const homeDataRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  success: false
});
export const homeDataRequestFinished = state => ({
  ...state,
  querying: false
});

export const toggleNewsletterWidget = (state, action) => ({
  ...state,
  showLastNewsletter: action.payload.showLastNewsletter
});

const reducersMap = {
  [HOME_DATA_REQUESTED]: homeDataRequested,
  [HOME_DATA_RECEIVED]: homeDataReceived,
  [HOME_DATA_REQUEST_FAILED]: homeDataRequestFailed,
  [HOME_DATA_REQUEST_FINISHED]: homeDataRequestFinished,
  [TOGGLE_NEWSLETTER_WIDGET]: toggleNewsletterWidget
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
