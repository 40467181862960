import {
  EVENTS_FILTERS_RECEIVED,
  EVENTS_FILTERS_REQUEST_FAILED,
  EVENTS_FILTERS_REQUEST_FINISHED,
  EVENTS_FILTERS_REQUESTED
} from './types';

export const getEventsFiltersRequested = () => ({
  type: EVENTS_FILTERS_REQUESTED,
  payload: null
});

export const getEventsFiltersSucceed = filters => ({
  type: EVENTS_FILTERS_RECEIVED,
  payload: { filters }
});

export const getEventsFiltersFailed = er => ({
  type: EVENTS_FILTERS_REQUEST_FAILED,
  payload: { error: er }
});

export const getEventsFiltersFinished = () => ({
  type: EVENTS_FILTERS_REQUEST_FINISHED,
  payload: null
});
