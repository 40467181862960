import {
  TOOLS_RECEIVED,
  TOOLS_REQUEST_FAILED,
  TOOLS_REQUEST_FINISHED,
  TOOLS_REQUESTED,
  CREATE_TOOLS_COOKIE_STATE,
  TOOLS_PIN_TOOL_SUCCEED,
  TOOLS_PIN_TOOL_FAILED
} from './types';

const INITIAL_STATE = {
  querying: false,
  tools: null,
  error: null,
  cookieState: false
};

export const toolsRequested = state => ({ ...state, querying: true });

export const toolsReceived = (state, action) => ({
  ...state,
  tools: action.payload.tools.map(tool => ({
    ...tool,
    infoLink: `#/tools/${tool.id}`
  })),
  error: null
});

export const toolsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  tools: null
});

export const setCookieState = (state, action) => ({
  ...state,
  cookieState: action.payload.cookieState
});

const toolsRequestFinished = state => ({ ...state, querying: false });

export const pinToolSucceed = (state, { payload: { toolId } }) => ({
  ...state,
  tools: Array.isArray(state.tools)
    ? state.tools.map(tool => ({
        ...tool,
        favorite: !!(tool.id === toolId ? !tool.favorite : tool.favorite)
      }))
    : state.tools
});

export const pinToolFailed = (state, { payload: { error } }) => ({
  ...state,
  tools: null,
  error: error
});

const reducersMap = {
  [TOOLS_REQUESTED]: toolsRequested,
  [TOOLS_RECEIVED]: toolsReceived,
  [TOOLS_REQUEST_FAILED]: toolsRequestFailed,
  [TOOLS_REQUEST_FINISHED]: toolsRequestFinished,
  [CREATE_TOOLS_COOKIE_STATE]: setCookieState,
  [TOOLS_PIN_TOOL_SUCCEED]: pinToolSucceed,
  [TOOLS_PIN_TOOL_FAILED]: pinToolFailed
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
