import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Block, Content, Spinner, Link, Notification } from 'lucentum';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Select from 'react-select';
import $ from 'jquery'

import { AdvancedCard } from '../';
import {
  Ecp as EcpPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import ErrorMessage from '../error-message';
import { EcpsList, Description, SelectWrapper } from './styles';
import {
  StyledTitle,
  BlockResponsive,
  CardsContainer
} from '../../commonStyles';
import {
  MAX_PINS,
  ECP_PIN_TOOLTIP_PINNED,
  ECP_PIN_TOOLTIP_UNPINNED,
  ECP_PIN_TOOLTIP_UNPINNED_MAX
} from '../../utils/constants';

let options = [
  // { value: 'showAll', label: "Show All" },
  // { value: 'ecp1', label: "ECP1 - Consolidate the implementation of EUIPN tools" },
  // { value: 'ecp2', label: "ECP2 - Improvement and upgrade of EUIPN tools." },
  // { value: 'ecp3', label: "ECP3 - New tools" },
  // { value: 'ecp4', label: "ECP4 - Convergence of practices" },
  // { value: 'ecp5', label: "ECP5 - Sustainability of the network" },
  // { value: 'ecp6', label: "ECP6 - Supporting SMEs" },
  // { value: 'ecp7', label: "ECP7 - Implementation of the directive" },
  // { value: 'ecp8', label: "ECP8 - Collaborative services" }
]

export default class Ecps extends Component {

  constructor(props) {
    super(props);
    this.state = { active: "ecp1" };
    const { intl } = props;
    this.documentUrl = intl.formatMessage({
      id: "portlet.ecps.description.linkDocument",
      defaultMessage: "#"
    })
  }

  setOptions(categorizedEcps){
    options=[];
    Object.keys(categorizedEcps).map((ecp, i) =>{ return options.push({value: `ecp${i+1}`, label:`${ecp}`})})
  }

  handleDropdownChange = (event) => {

    // window.scrollTo(0, this.ref.offsetTop)
    // window.scrollTo(0, this[`${event.value}`].offsetTop)
    // this[`${event.value}`].current.scrollIntoView()

    $('html, body').animate({
      scrollTop: $(`#${event.value}`).offset().top - 80
    }, 1000)

    console.log(event);
    this.setState({

      active: event.value

    }) };

  static buildCategories = ecps =>
    ecps.reduce((prev, ecp) => ({ ...prev, [ecp.category]: [] }), {});

  static buildEcpsCategoryTree = ecps =>
    ecps.reduce((prev, ecp) => {
      prev[ecp.category].push(ecp);
      return prev;
    }, Ecps.buildCategories(ecps));

  handleCloseNotification = () => {
    this.props.createCookie();
  };

  canPin = ecps =>
    !!(
      this.props.isUserLoged &&
      ecps.filter(tool => tool.favorite).length < MAX_PINS
    );

  isFavorite = ecp =>
    ecp.favorite ? 'yes' : this.canPin(this.props.ecps) ? 'no' : null;

  tooltipMessage = ecp =>
    this.isFavorite(ecp) === 'yes'
      ? ECP_PIN_TOOLTIP_PINNED
      : this.isFavorite(ecp) === 'no'
      ? ECP_PIN_TOOLTIP_UNPINNED
      : ECP_PIN_TOOLTIP_UNPINNED_MAX;

  render = () => {

    const {active} = this.state;

    const { ecps, querying, error, isUserLoged } = this.props;

    const categorizedEcps =
      querying || error || !ecps
        ? []
        : this.constructor.buildEcpsCategoryTree(ecps);

    this.setOptions(categorizedEcps);

    return (
      <Content>
        {querying && (
          <Block center>
            <Spinner show row size={10} />
          </Block>
        )}
        {this.shouldShowErrorMessage() && <ErrorMessage />}
        {this.shouldShowEcps() && (
          <BlockResponsive>
            <Block hiddenXS visibleLG marginBottom>
              <StyledTitle>
                <FormattedMessage id="portlet.section.description.title" />
              </StyledTitle>
            </Block>
            <Description marginTop>
              <FormattedHTMLMessage
                id="portlet.ecps.description"
                tagName="p"
              />
              <FormattedMessage
                id="ecps.link"
                defaultMessage='{link}'
                values={{
                  link: (
                    <Link to={this.documentUrl} target="_blank">
                      <FormattedMessage id="portlet.ecps.description.link" />
                    </Link>
                  )
                }}
                tagName="p"
              />
              
            </Description>
            {/*<Block hiddenXS visibleLG marginBottom>*/}
              {/*{!this.props.cookieState && (*/}
                {/*<Notification onClose={this.handleCloseNotification}>*/}
                  {/*<FormattedHTMLMessage id="portlet.ecps.notification" />*/}
                {/*</Notification>*/}
              {/*)}*/}
            {/*</Block>*/}

            <Block style={{ display: 'flex', justifyContent: 'flex-end' }} hiddenXS visibleLG marginBottom>
              <SelectWrapper>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>Go to: </div>
                <Select style={{ width: '400px'}} options={options} onChange={this.handleDropdownChange} value={active} clearable={false} />
              </SelectWrapper>
            </Block>

            {Object.keys(categorizedEcps).map((category, i) =>
              this.getCategorizedEcp(
                i,
                category,
                categorizedEcps,
                isUserLoged,
                active
              )
            )}
          </BlockResponsive>
        )}



      </Content>
    );
  };

  shouldShowEcps = () => !this.props.querying && this.props.ecps;

  shouldShowErrorMessage = () => !this.props.querying && this.props.error;

  getCategorizedEcp = (i, category, categorizedEcps, isUserLoged, active) => {

    console.log('categorizedEcps', categorizedEcps)
    console.log('category', category)
     // if (active === 'showAll' || active === `ecp${i+1}`) {

        return (<Content key={i}>
          <EcpsList className="category-title" title={category} marginBottom={2}>
            {categorizedEcps[category].map(ecp => {
              const { logo, ...parsedEcp} = ecp
              return (
                <CardsContainer key={ecp.id} widthLG1of3>
                  <AdvancedCard
                    // ref={el => (this.ecp[i+1] = el)}
                    idKey={`ecp${i+1}`}
                    {...parsedEcp}
                    className={`ecp--${ecp.key}`}
                    tooltipMessage={this.tooltipMessage(ecp)}
                  />
                </CardsContainer>
              )}
            )}
          </EcpsList>
        </Content>)
     // return null
  };

  static propTypes = {
    ...ReducerPropType({
      ecps: PropTypes.arrayOf(EcpPropType)
    }).isRequired
  };
}
