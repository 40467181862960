import {
  PAGINATED_EVENTS_RECEIVED,
  PAGINATED_EVENTS_REQUEST_FAILED,
  PAGINATED_EVENTS_REQUEST_FINISHED,
  PAGINATED_EVENTS_REQUESTED
} from './types';
import api from '../../utils/api';

import {
  getEventsFiltersSucceed,
  getEventsFiltersFailed,
  getEventsFiltersFinished,
  getEventsFiltersRequested
} from '../eventsFilters/action-creators';

const ITEMS_PER_PAGE = 5;

export const getPaginatedEventsRequested = () => ({
  type: PAGINATED_EVENTS_REQUESTED,
  payload: null
});

export const getPaginatedEventsSucceed = (events, numEvents, currentPage) => ({
  type: PAGINATED_EVENTS_RECEIVED,
  payload: { events, currentPage, numEvents }
});

export const getPaginatedEventsFailed = er => ({
  type: PAGINATED_EVENTS_REQUEST_FAILED,
  payload: { error: er }
});

export const getPaginatedEventsFinished = () => ({
  type: PAGINATED_EVENTS_REQUEST_FINISHED,
  payload: null
});

export const getPaginatedEvents = (
  currentPage = 1,
  filters = {}
) => async dispatch => {
  dispatch(getPaginatedEventsRequested());
  try {
    const {
      data: { events, numEvents }
    } = await api.getPaginatedEvents(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
      filters
    );
    dispatch(getPaginatedEventsSucceed(events, numEvents, currentPage));
  } catch (error) {
    dispatch(getPaginatedEventsFailed(error.response.data));
  } finally {
    dispatch(getPaginatedEventsFinished());
  }
};

export const getEventsHomeData = () => async dispatch => {
  dispatch(getEventsFiltersRequested());

  try {
    const {
      data: {
        eventsFilters: filters,
        eventsPaginated: { events, numEvents: nEvents }
      }
    } = await api.getEventsHomeData();
    dispatch(getPaginatedEventsSucceed(events, nEvents, 1));
    dispatch(getEventsFiltersSucceed(filters));
  } catch (error) {
    dispatch(getPaginatedEventsFailed(error.response.data));
    dispatch(getEventsFiltersFailed(error.response.data));
  } finally {
    dispatch(getEventsFiltersFinished());
    dispatch(getPaginatedEventsFinished());
  }
};
