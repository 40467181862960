import React, { Component } from 'react';
import { isEmail } from 'validator';

export default WrappedComponent =>
  class extends Component {
    state = {
      email: '',
      inputHasBeenFocused: false
    };

    saveEmail = ({ target: { value: email } }) => {
      this.setState({ email });
    };

    callRegisterFunction = async () => {
      try {
        this.setState(() => ({ submitting: true }));
        await this.props.registerFunction(this.state.email);
        this.setState(() => ({ subscribed: true }));
      } finally {
        this.setState(() => ({ submitting: false }));
      }
    };

    shouldSubmitForm = () => this.isAValidEmail() && !this.isSubmitting();
    isAValidEmail = () => isEmail(this.state.email);
    isSubmitting = () => this.state.submitting;
    handleSubmit = async ev => {
      ev.preventDefault();
      if (this.shouldSubmitForm()) {
        await this.callRegisterFunction();
      }
    };

    setAsFocused = () => {
      this.setState(() => ({ inputHasBeenFocused: true }));
    };

    render = () => (
      <WrappedComponent
        {...this.props}
        saveEmail={this.saveEmail}
        callRegisterFunction={this.callRegisterFunction}
        shouldSubmitForm={this.shouldSubmitForm}
        isAValidEmail={this.isAValidEmail}
        isSubmitting={this.isSubmitting}
        handleSubmit={this.handleSubmit}
        setAsFocused={this.setAsFocused}
        inputHasBeenFocused={this.state.inputHasBeenFocused}
      />
    );
  };
