import { connect } from 'react-redux';

import CommonPracticePage from './component';
import { getCommonPractices } from '../../redux/commonPractices/action-creators';

export const mapStateToProps = ({
  commonPractices: { querying, categories, error }
}) => ({ querying, categories, error });
export const mapDispatchToProps = dispatch => ({
  getCommonPractices: () => dispatch(getCommonPractices())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommonPracticePage);
