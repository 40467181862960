import {
  OFFICES_RECEIVED,
  OFFICES_REQUEST_FAILED,
  OFFICES_REQUEST_FINISHED,
  OFFICES_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  offices: null,
  error: null
};

export const officesRequested = state => ({ ...state, querying: true });

export const officesReceived = (state, action) => ({
  ...state,
  offices: action.payload.offices,
  error: null
});

export const officesRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  offices: null
});

const officesRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [OFFICES_REQUESTED]: officesRequested,
  [OFFICES_RECEIVED]: officesReceived,
  [OFFICES_REQUEST_FAILED]: officesRequestFailed,
  [OFFICES_REQUEST_FINISHED]: officesRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
