import React from 'react';
import { Content, Block, H3, Spinner, Image } from 'lucentum';
import HtmlDisplay from '../html-display';
import {
  ContentBlock,
  StyledTitle,
  ImageBlock,
  NewsBlock,
  NewContentBlock,
  StyledDate
} from './styles';
import ErrorMessage from '../error-message';
import {
  News as NewsPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { fromUnixToLocalString } from '../../utils/functions';

const NewsItem = ({ newsItem, querying, error }) => (
  <Content>
    {querying && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {!querying && error && <ErrorMessage />}
    {!querying && newsItem && (
      <NewsBlock paddingTop>
        {newsItem.imageURL && (
          <ImageBlock paddingRight={2}>
            <Image src={newsItem.imageURL}></Image>
          </ImageBlock>
        )}
        <NewContentBlock width3of5={!!newsItem.imageURL}>
          <StyledTitle>{newsItem.title}</StyledTitle>
          <ContentBlock paddingTop>
            <StyledDate>{fromUnixToLocalString(newsItem.date)}</StyledDate>
            <Block hiddenXS visibleSM paddingTop>
              <H3>{newsItem.title}</H3>
            </Block>
            <HtmlDisplay>{newsItem.description}</HtmlDisplay>
          </ContentBlock>
        </NewContentBlock>
      </NewsBlock>
    )}
  </Content>
);

NewsItem.propTypes = {
  ...ReducerPropType({
    newsItem: NewsPropType
  }).isRequired
};

export default NewsItem;
