import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Block, Spinner, Link, H1, P } from 'lucentum';
import Tooltip from '../tooltip';
import { InfoIcon } from './styles';

import AdvancedCard from '../advanced-card';
import ErrorMessage from '../error-message';
import {
  BlockTitle,
  TooltipFieldset
} from '../../commonStyles/homeTooltipStyles';
import {
  Practice as PracticePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import {
  MAX_PINS,
  PRACTICE_PIN_TOOLTIP_PINNED,
  PRACTICE_PIN_TOOLTIP_UNPINNED,
  PRACTICE_PIN_TOOLTIP_UNPINNED_MAX
} from '../../utils/constants';

export default class PracticeWidget extends Component {
  canPin = practices =>
    !!(
      this.props.isUserLoged &&
      practices.filter(practice => practice.favorite).length < MAX_PINS
    );

  isFavorite = practice =>
    practice.favorite
      ? 'yes'
      : this.canPin(this.props.featuredPractices)
        ? 'no'
        : null;

  tooltipMessage = practice =>
    this.isFavorite(practice) === 'yes'
      ? PRACTICE_PIN_TOOLTIP_PINNED
      : this.isFavorite(practice) === 'no'
        ? PRACTICE_PIN_TOOLTIP_UNPINNED
        : PRACTICE_PIN_TOOLTIP_UNPINNED_MAX;

  render() {
    const {
      featuredPractices,
      querying,
      error,
      pinPractice,
      isUserLoged
    } = this.props;
    return (
      <Block>
        <BlockTitle>
          <H1>
            <FormattedMessage id="portlet.section.practices" />
          </H1>
          <TooltipFieldset>
            <div data-tip data-for="practices-tooltip">
              <InfoIcon information />
            </div>
            <Tooltip id="practices-tooltip">
              <P>
                {/*<FormattedMessage id="portlet.home.tooltip.info_practices" />*/}
                <FormattedHTMLMessage id="porlet.home.tooltip.info_practices" />
              </P>
            </Tooltip>
          </TooltipFieldset>
        </BlockTitle>
        <Block>
          {querying && (
            <Block center>
              <Spinner show row size={3} />
            </Block>
          )}
          {!querying && error && <ErrorMessage />}
          {!querying && featuredPractices && (
            <Block marginTop={0.86}>
              {featuredPractices.map(practice => (
                <AdvancedCard
                  {...practice}
                  key={practice.id}
                  infoLink={`#/practices/${practice.id}`}
                  favorite={this.isFavorite(practice)}
                  onFavorite={() => isUserLoged && pinPractice(practice.id)}
                  tooltipMessage={this.tooltipMessage(practice)}
                />
              ))}
              <Block right>
                <Link to="#/practices">
                  <FormattedMessage id="portlet.action.practices.all" />
                </Link>
              </Block>
            </Block>
          )}
        </Block>
      </Block>
    );
  }
  static propTypes = {
    ...ReducerPropType({
      featuredPractices: PropTypes.arrayOf(PracticePropType)
    }).isRequired,
    pinPractice: PropTypes.func.isRequired
  };
}
