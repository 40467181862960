import styled from 'styled-components';
import { H3 } from 'lucentum';

export const StyledOfficeSpan = styled.span`
  margin-bottom: ${({ theme }) => theme.sizeSmall};
  display: block;
`;

export const OfficeCategory = styled(H3)`
  margin-bottom: ${({ theme }) => theme.sizeSmall};
`;
