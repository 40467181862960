import { getAuthToken } from './redux/authToken/action-creators';
import { getActiveLanguage } from './redux/languages/action-creators';

export default function boot(store) {
  store.dispatch(getAuthToken());
  let authToken = null;
  store.subscribe(() => {
    const currentAuthToken = store.getState().authToken.authToken;
    if (!authToken && currentAuthToken) {
      authToken = currentAuthToken;
      store.dispatch(getActiveLanguage());
    }
  });
}
