import {
  GET_LABELS_FAILED,
  GET_LABELS_FINISHED,
  GET_LABELS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  error: null
};

const reducers = {
  [GET_LABELS_REQUESTED]: state => ({ ...state, querying: true }),
  [GET_LABELS_FAILED]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [GET_LABELS_FINISHED]: state => ({ ...state, querying: false })
};

export default (state = INITIAL_STATE, action) =>
  reducers[action.type] ? reducers[action.type](state, action) : state;
