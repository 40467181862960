import styled from 'styled-components';
import { Block, Fieldset } from 'lucentum';
import Icon from '../components/icon';

export const BlockTitle = styled(Block)`
  display: flex;
  align-items: flex-end;
  border-bottom: ${({ small, theme }) =>
    small ? 0 : `${theme.line} solid ${theme.colorGray}`};

  height: ${({ theme }) => theme.sizeDouble};
  margin-top: 0.5rem;
  margin-bottom: ${({ theme }) => theme.size};
  padding: ${({ theme: { line, sizeSmall } }) =>
    `0 ${line} ${sizeSmall} ${line}`};

  > fieldset {
    i {
      font-size: ${({ theme }) => theme.fontSize};
    }
  }

  > h1 {
    line-height: 1;
  }
`;

export const TooltipFieldset = styled(Fieldset)`
  display: flex;
  height: 100%;
  align-items: center;
  margin: auto;
  margin-left: 1rem;
  width: auto;

  & > div:last-child {
    margin: auto;
  }
`;

export const InfoIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorGrayLight};
  margin: auto;
`;

export const IconContainer = styled(Block)`
  float: 'none';
  width: '1rem';
  margin: 'auto';
  display: 'flex';
`;
