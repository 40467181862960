import styled from 'styled-components';
import {
  Checkbox as LucentumCheckbox,
  Block as BlockLucentum,
  Button as ButtonLucentum,
  Form as FormLucentum
} from 'lucentum';

export const MarginLeftCheckbox = styled(LucentumCheckbox)`
  > label {
    margin: 0;
  }
`;

export const BlockResponsive = styled(BlockLucentum)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    padding: ${({ theme }) => theme.sizeSmall};
    margin: auto;
    width: 100%;
    &:nth-last-child(2) {
      padding-bottom: 96px;
    }
  }
`;

export const BigButton = styled(ButtonLucentum)`
  padding: ${({ theme }) => theme.size};
  width: 51%;
`;

export const Form = styled(FormLucentum)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    input:not(:focus):invalid + div {
      display: none;
    }
  }
`;
