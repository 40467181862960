import styled from 'styled-components';

import { Block } from 'lucentum';

export const Footer = styled.footer`
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.sizeSmall};
  background-color: ${({ theme }) => theme.color};
  p {
    margin: 0;
  }
  button {
    border-color: ${({ theme }) => theme.colorWhite};
  }
  a,
  p,
  button {
    color: ${({ theme }) => theme.colorWhite};
  }

  @media (${({ theme }) => theme.breakpointMaxMD}) {
    display: none;
  }
`;

export const Underlined = styled(Block)`
  border-bottom: ${({ theme }) => [
    'solid ',
    theme.line,
    ' ',
    theme.colorWhite
  ]};
`;

export const ContactBlock = styled(Block)`
  display: flex;
  flex-wrap: wrap;
`;

export const BlockLinks = styled(Block)`
  display: flex;
  justify-content: space-around;
`;
