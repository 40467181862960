import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Block, Button, Tooltip, P, Link } from 'lucentum';

import { Level, StyledCard, Title, ButtonsBlock } from './styles';
import ApplicationIcon from '../application-icon';

const Logo = ({ logo, title, level }) => (
  <Block marginBottom={logo ? 1 : 0.5} middle>
    {logo ? (
      <ApplicationIcon src={logo} />
    ) : (
      <Title className="title">
        {title}
        {level && (
          <Level>
            &nbsp;
            {`- ${level}`}
          </Level>
        )}
      </Title>
    )}
  </Block>
);

const AdvancedCard = ({
  id,
  className = '',
  title,
  shortDescription,
  logo,
  level,
  url,
  commonCommunicationSource,
  downloadUrl,
  infoLink,
  favorite,
  onFavorite,
  tooltipMessage = '',
  currentFontSize,
  idKey,
  ...props
}) => (
  <StyledCard
    className={`advanced-card ${className}`}
    favorite={favorite}
    onFavorite={onFavorite}
    key={id}
    id={idKey}
    {...props}
  >
    {tooltipMessage && (
      <Tooltip>
        <P>{tooltipMessage}</P>
      </Tooltip>
    )}
    <Logo logo={logo} title={title} level={level} />
    {shortDescription && (
      <Block className="short-description">{shortDescription}</Block>
    )}
    <ButtonsBlock>
      {url && (
        <Button className="button" onClick={() => window.open(url)}>
          <FormattedMessage id="portlet.action.launch" />
        </Button>
      )}
      {(commonCommunicationSource || downloadUrl) && (
        <Button
          onClick={() =>
            commonCommunicationSource
              ? window.open(commonCommunicationSource)
              : window.open(downloadUrl)
          }
        >
          <FormattedMessage id="portlet.action.download" />
        </Button>
      )}
      {infoLink && (
        <Link
          className="link"
          onClick={() => (window.location.href = infoLink)}
        >
          <FormattedMessage id="portlet.action.more_info" />
        </Link>
      )}
    </ButtonsBlock>
  </StyledCard>
);

AdvancedCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
  level: PropTypes.string,
  commonCommunicationSource: PropTypes.string,
  downloadUrl: PropTypes.string,
  infoLink: PropTypes.string,
  favorite: PropTypes.string,
  onFavorite: PropTypes.func
};

AdvancedCard.defaultProps = {
  id: '',
  title: '',
  shortDescription: '',
  logo: null,
  url: null,
  level: null,
  commonCommunicationSource: '',
  downloadUrl: '',
  infoLink: '',
  favorite: '',
  onFavorite: null
};

export default AdvancedCard;
