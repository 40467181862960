import {
  OFFICE_RECEIVED,
  OFFICE_REQUEST_FAILED,
  OFFICE_REQUEST_FINISHED,
  OFFICE_REQUESTED,
  CLEAR_OFFICE
} from './types';

const INITIAL_STATE = {
  querying: false,
  office: null,
  error: null
};

export const officeRequested = state => ({ ...state, querying: true });
export const officeReceived = (state, action) => ({
  ...state,
  office: action.payload.office,
  error: null
});

export const officeRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  office: null
});
export const officeRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [OFFICE_REQUESTED]: officeRequested,
  [OFFICE_RECEIVED]: officeReceived,
  [OFFICE_REQUEST_FAILED]: officeRequestFailed,
  [OFFICE_REQUEST_FINISHED]: officeRequestFinished,
  [CLEAR_OFFICE]: state => ({ ...state, office: null })
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
