import {
  EVENT_RECEIVED,
  EVENT_REQUEST_FAILED,
  EVENT_REQUEST_FINISHED,
  EVENT_REQUESTED
} from './types';
import api from '../../utils/api';

export const getEventRequested = () => ({
  type: EVENT_REQUESTED,
  payload: null
});

export const getEventSucceed = event => ({
  type: EVENT_RECEIVED,
  payload: { event }
});

export const getEventFailed = error => ({
  type: EVENT_REQUEST_FAILED,
  payload: { error }
});

export const getEventFinished = () => ({
  type: EVENT_REQUEST_FINISHED,
  payload: null
});

export const getEvent = eventId => async dispatch => {
  dispatch(getEventRequested());
  try {
    const { data: event } = await api.getEvent(eventId);
    dispatch(getEventSucceed(event));
  } catch (error) {
    dispatch(getEventFailed(error.response.data));
  } finally {
    dispatch(getEventFinished());
  }
};
