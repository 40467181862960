import {
  ACTIVE_LANGUAGE_REQUESTED,
  ACTIVE_LANGUAGE_SUCCEED,
  ACTIVE_LANGUAGE_FAILED,
  ACTIVE_LANGUAGE_FINISHED,
  CHANGE_LANGUAGE_REQUESTED,
  CHANGE_LANGUAGE_SUCCEED,
  CHANGE_LANGUAGE_FAILED,
  CHANGE_LANGUAGE_FINISHED,
  LANGUAGES_RECEIVED,
  LANGUAGES_REQUEST_FAILED,
  LANGUAGES_REQUEST_FINISHED,
  LANGUAGES_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  changingLanguage: false,
  languages: null,
  error: null,
  activeLanguageCode: null,
  gettingActiveLanguage: false
};

export const languagesRequested = state => ({ ...state, querying: true });

export const languagesReceived = (state, action) => ({
  ...state,
  languages: action.payload.languages,
  error: null
});

export const languagesRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  languages: null
});

export const languagesRequestFinished = state => ({
  ...state,
  querying: false
});

export const changeLanguageRequested = state => ({
  ...state,
  changingLanguage: true
});
export const changeLanguageSucceed = (state, action) => ({
  ...state,
  activeLanguageCode: action.payload.languageCode,
  error: null
});

export const changeLanguageFailed = (state, action) => ({
  ...state,
  error: action.payload.error
});

export const changeLanguageFinished = state => ({
  ...state,
  changingLanguage: false
});

export const activeLanguageRequested = state => ({
  ...state,
  gettingActiveLanguage: true
});
export const activeLanguageSucceed = (state, action) => ({
  ...state,
  activeLanguageCode: action.payload.languageCode,
  error: null
});

export const activeLanguageFailed = (state, action) => ({
  ...state,
  error: action.payload.error
});

export const activeLanguageFinished = (state, action) => ({
  ...state,
  gettingActiveLanguage: false
});

const reducersMap = {
  [LANGUAGES_REQUESTED]: languagesRequested,
  [LANGUAGES_RECEIVED]: languagesReceived,
  [LANGUAGES_REQUEST_FAILED]: languagesRequestFailed,
  [LANGUAGES_REQUEST_FINISHED]: languagesRequestFinished,
  [CHANGE_LANGUAGE_REQUESTED]: changeLanguageRequested,
  [CHANGE_LANGUAGE_SUCCEED]: changeLanguageSucceed,
  [CHANGE_LANGUAGE_FAILED]: changeLanguageFailed,
  [CHANGE_LANGUAGE_FINISHED]: changeLanguageFinished,
  [ACTIVE_LANGUAGE_REQUESTED]: activeLanguageRequested,
  [ACTIVE_LANGUAGE_SUCCEED]: activeLanguageSucceed,
  [ACTIVE_LANGUAGE_FAILED]: activeLanguageFailed,
  [ACTIVE_LANGUAGE_FINISHED]: activeLanguageFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
