import { combineReducers } from 'redux';
import { intlReducer as intl } from 'react-intl-redux';

import authToken from './authToken/reducer';
import contactForm from './contact-form/reducer';
import event from './event/reducer';
import featuredEvents from './featuredEvents/reducer';
import paginatedEvents from './paginated-events/reducer';
import eventsFilters from './eventsFilters/reducer';
import featuredCourses from './featuredCourses/reducer';
import featuredPractices from './featuredPractices/reducer';
import featuredTools from './featuredTools/reducer';
import featuredEcps from './featuredEcps/reducer';
import labels from './labels/reducer';
import languages from './languages/reducer';
import members from './members/reducer';
import news from './news/reducer';
import featuredNews from './featuredNews/reducer';
import newsFilters from './newsFilters/reducer';
import newsItem from './newsItem/reducer';
import newsletters from './newsletters/reducer';
import office from './office/reducer';
import offices from './offices/reducer';
import practice from './practice/reducer';
import practices from './practices/reducer';
import commonPractices from './commonPractices/reducer';
import searchResults from './search/reducer';
import tool from './tool/reducer';
import tools from './tools/reducer';
import ecp from './ecp/reducer';
import ecps from './ecps/reducer';
import user from './user/reducer';
import subscribedNewsletter from './subscribeToNewsletter/reducer';
import contactUsTopics from './contactUsTopics/reducer';
import homeData from './home/reducer';
import featuredNewsletter from './featuredNewsletter/reducer';
import hero from './hero/reducer';
import heroCarousel from './heroCarousel/reducer';
import cookies from './cookies/reducer';
import matomoProperties from './matomo/reducer';

export default combineReducers({
  authToken,
  homeData,
  hero,
  heroCarousel,
  contactForm,
  event,
  featuredEvents,
  paginatedEvents,
  eventsFilters,
  featuredCourses,
  featuredPractices,
  featuredTools,
  featuredEcps,
  intl,
  labels,
  languages,
  members,
  news,
  featuredNews,
  newsFilters,
  newsItem,
  newsletters,
  office,
  offices,
  practice,
  practices,
  commonPractices,
  searchResults,
  tool,
  tools,
  ecp,
  ecps,
  user,
  subscribedNewsletter,
  contactUsTopics,
  featuredNewsletter,
  cookies,
  matomoProperties
});
