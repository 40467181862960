import React from 'react';
import Slider from "react-slick";
import { Link } from 'lucentum';
import leftArrow from '../../assets/imgs/left-arrow.png'
import rightArrow from '../../assets/imgs/right-arrow.png'
import { FormattedMessage } from 'react-intl';

import { BlockStyled, BlockOpacity } from './styles';

class HeroCarousel extends React.Component {
  renderArrows = () => {
    return (
      <div className="slider-arrow">
        <img
          width="15px"
          src={leftArrow} 
          alt="left-arrow-slider" 
          className="arrow-btn prev"
          onClick={() => this.slider.slickPrev()}
        />
        <img
          width="15px"
          src={rightArrow} 
          alt="left-arrow-slider" 
          className="arrow-btn next"
          onClick={() => this.slider.slickNext()}
        />
      </div>
    );
  };

  replaceTags = (str) => {
    if ((str===null) || (str===''))
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }

  render() {
    const { sliderData } = this.props;
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      className: 'slides'
    };
    return (
      <div style={{ position: "relative" }}>
        <Slider 
          ref={c => (this.slider = c)}
          {...settings}> 
          {
              sliderData.map((item, index) => {
                return(
                  <BlockStyled key={index} middle>
                      <img src={item.imageUrl} alt={item.title} />
                      <BlockOpacity>
                        <h3>{item.title}</h3>
                        {/*<p>{item.shortDescription}</p>*/}
                        <p>{this.replaceTags(item.shortDescription)}</p>
                        <Link key={index} to={item.url} target="_blank">
                          <FormattedMessage id="readmore.label">
                            {message => <span>{message + '...'}</span>}
                          </FormattedMessage>
                        </Link>
                      </BlockOpacity>
                  </BlockStyled>
                  
                )
              })
            }
        </Slider>
        {this.renderArrows()}
      </div>
    );
  }
  
};

export default HeroCarousel;
