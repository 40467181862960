import React from 'react';
import { Notification } from 'lucentum';
import { FormattedMessage } from 'react-intl';

export const ERROR_MESSAGE = (
  <FormattedMessage id="portlet.common.server_error" />
);

const ErrorMessage = ({ message = ERROR_MESSAGE }) => (
  <Notification error>{message}</Notification>
);

export default ErrorMessage;
