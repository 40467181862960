import {
  PRACTICES_RECEIVED,
  PRACTICES_REQUEST_FAILED,
  PRACTICES_REQUEST_FINISHED,
  PRACTICES_REQUESTED,
  CREATE_PRACTICE_COOKIE_STATE,
  PRACTICES_PIN_PRACTICE
} from './types';

import { parsePractices } from './action-creators';

const INITIAL_STATE = {
  querying: false,
  practices: null,
  error: null
};

export const practicesRequested = state => ({ ...state, querying: true });

export const practicesReceived = (state, action) => ({
  ...state,
  practices: parsePractices(action.payload.practices),
  error: null
});

export const practicesRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  practices: null
});

export const practicesRequestFinished = state => ({
  ...state,
  querying: false
});
export const setCookieState = (state, action) => ({
  ...state,
  cookieState: action.payload.cookieState
});

export const pinPractice = (state, { payload: { practiceId } }) => ({
  ...state,
  practices: Array.isArray(state.practices)
    ? state.practices.map(practice => ({
        ...practice,
        favorite: !!(practice.practiceId === practiceId)
          ? !practice.favorite
          : practice.favorite
      }))
    : state.practices
});

const reducersMap = {
  [PRACTICES_REQUESTED]: practicesRequested,
  [PRACTICES_RECEIVED]: practicesReceived,
  [PRACTICES_REQUEST_FAILED]: practicesRequestFailed,
  [PRACTICES_REQUEST_FINISHED]: practicesRequestFinished,
  [CREATE_PRACTICE_COOKIE_STATE]: setCookieState,
  [PRACTICES_PIN_PRACTICE]: pinPractice
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
