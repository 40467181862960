import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Block, Spinner, Link, H1 } from 'lucentum';

import { News } from './styles';
import { BlockTitle } from '../../commonStyles/homeTooltipStyles';
import ErrorMessage from '../error-message';
import {
  Event as EventPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { formatDateRanges } from '../../components/detailed-news/component';
import { fromUnixToLocalString } from '../../utils/functions';

const renderDates = (begin, end = null) =>
  end && end !== begin
    ? formatDateRanges(begin, end)
    : fromUnixToLocalString(begin);

const EventsWidget = ({ events, className, querying, error }) => (
  <Block className={className}>
    <BlockTitle>
      <H1>
        <FormattedMessage id="portlet.section.events" />
      </H1>
    </BlockTitle>
    {querying && (
      <Block center>
        <Spinner show row size={3} />
      </Block>
    )}
    {!querying && error !== null && <ErrorMessage />}
    {!querying && events && (
      <Block marginTop={0.86}>
        <News
          src={events.map(event => ({
            ...event,
            detail: renderDates(event.beginDate, event.endDate)
          }))}
        />
        <Block right>
          <Link to="#/events">
            <FormattedMessage id="portlet.action.events.show_all" />
          </Link>
        </Block>
      </Block>
    )}
  </Block>
);

EventsWidget.propTypes = {
  ...ReducerPropType({
    events: PropTypes.arrayOf(EventPropType)
  }).isRequired
};

export default EventsWidget;
