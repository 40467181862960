import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const configureStore = INITIAL_STATE =>
  INITIAL_STATE
    ? createStore(
        reducer,
        INITIAL_STATE,
        composeEnhancers(applyMiddleware(thunk))
      )
    : createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
export default createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
