import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/page';
import { Tool as ToolComponent } from '../../components/';
import {
  Tool as ToolProptype,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { FormattedMessage } from 'react-intl';
import { Content } from 'lucentum';

const Tool = ({ tool, active, clearTool }) => (
  <Page active={active}>
    <Content>
      <Breadcrumbs
        src={[
          { text: <FormattedMessage id="portlet.section.home" />, url: '#/' },
          {
            text: <FormattedMessage id="portlet.section.tools" />,
            url: '#/tools'
          },
          {
            text: '',
            url: '#/tools'
          }
        ]}
      />
    </Content>
    <ToolComponent
      tool={tool.tool}
      querying={tool.querying}
      error={tool.error}
      clearTool={clearTool}
    />
  </Page>
);

Tool.propTypes = {
  tool: ReducerPropType({
    tool: ToolProptype
  }).isRequired,
  active: PropTypes.string
};

export default Tool;
