import {
  TOOL_RECEIVED,
  TOOL_REQUEST_FAILED,
  TOOL_REQUEST_FINISHED,
  TOOL_REQUESTED,
  PIN_TOOL_REQUESTED,
  PIN_TOOL_FINISHED,
  PIN_TOOL_FAILED,
  PIN_TOOL_RECEIVED
} from './types';

const INITIAL_STATE = {
  querying: false,
  tool: null,
  error: null
};

export const toolRequested = state => ({ ...state, querying: true });
export const toolReceived = (state, action) => ({
  ...state,
  tool: action.payload.tool,
  error: null
});

export const toolRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  tool: null
});

export const toolRequestFinished = state => ({ ...state, querying: false });

export const pinToolRequested = state => ({
  ...state,
  querying: true,
  error: null
});

export const pinToolRequestReceived = state => ({
  ...state,
  ok: true
});

export const pinToolRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error
});

export const pinToolRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [TOOL_REQUESTED]: toolRequested,
  [TOOL_RECEIVED]: toolReceived,
  [TOOL_REQUEST_FAILED]: toolRequestFailed,
  [TOOL_REQUEST_FINISHED]: toolRequestFinished,
  [PIN_TOOL_REQUESTED]: pinToolRequested,
  [PIN_TOOL_RECEIVED]: pinToolRequestReceived,
  [PIN_TOOL_FAILED]: pinToolRequestFailed,
  [PIN_TOOL_FINISHED]: pinToolRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
