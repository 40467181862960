import styledNormalize from 'styled-normalize';
import { injectGlobal } from 'styled-components';

import svgFont from './components/icon/fonts/Icons2.svg';
import ttfFont from './components/icon/fonts/Icons2.ttf';
import woffFont from './components/icon/fonts/Icons2.woff';

injectGlobal`
  import './public/fonts.css'
  ${styledNormalize}
  @font-face {
    font-display: fallback;
    font-family: 'Lucentum Icons 2';
    src: url('${woffFont}');
    src: url('${ttfFont}') format('truetype'),
        url('${woffFont}') format('woff'),
        url('${svgFont}#Lucentum-icon-set') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  html, body {
    height: 100%;
    line-height: normal;
  }
  body > div:not(.ReactModalPortal) {
    height: 100%;
  }
  p {
    margin-block-start: 0;
  }

  .slider-arrow {
    position: relative;

    .arrow-btn {
      color: rgb(47, 80, 154);
      &:hover {
        cursor: pointer;
      }
    }
    .arrow-btn.prev {
      position: absolute;
      top: -47px;
      left: 15px;
        
    }
    .arrow-btn.next {
      position: absolute;
      top: -47px;
      left: 30px;
    }
    @media (min-width: 992px) {
      .arrow-btn.prev {
        position: absolute;
        top: -51px;
        left: 15px;
          
      }
      .arrow-btn.next {
        position: absolute;
        top: -51px;
        left: 30px;
      }
    }
  }
  .slides {
    position: relative;
    .slick-prev, .slick-next {
      position: absolute;
      top: 79%;
      opacity: 1;
    }
    .slick-prev {
      left: 15px;
       z-index: 1;
    }
    .slick-next {
      left: 45px;
    }
    .slick-prev:before, .slick-next:before {
      font-size: 25px;
      color: rgb(47, 80, 154);
    }
    @media (min-width: 992px) {
      .slick-prev, .slick-next {
        top: 80%;
      }
      .slick-prev:before, .slick-next:before {
        font-size: 24px;
      }
    }
    
  }
`;
