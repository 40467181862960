import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Page, NewsletterPage } from '../../components';

import {
  Newsletter as NewsletterPropType,
  Reducer as ReducerPropType
} from '../../propTypes';

export default class Newsletters extends Component {
  render = () => {
    const {
      newsletters,
      handleSubscribeNewsletter,
      subscribedNewsletter,
      active
    } = this.props;
    return (
      <Page active={active}>
        <NewsletterPage
          newsletters={newsletters.newsletters}
          querying={newsletters.querying}
          error={newsletters.error}
          submitToNewsletter={handleSubscribeNewsletter}
          subscribedNewsletter={subscribedNewsletter}
        />
      </Page>
    );
  };

  componentDidMount = () => this.props.getNewsletters();

  static propTypes = {
    newsletters: ReducerPropType({
      newsletters: PropTypes.arrayOf(NewsletterPropType)
    }).isRequired,
    subscribedNewsletter: ReducerPropType({
      subscribed: PropTypes.bool
    }).isRequired,
    handleSubscribeNewsletter: PropTypes.func.isRequired,
    active: PropTypes.string.isRequired
  };
}
