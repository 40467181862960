import { connect } from 'react-redux';

import ContactForm from './component';
import { contactSubmit } from '../../redux/contact-form/action-creators';
import { getContactUsTopics } from '../../redux/contactUsTopics/action-creators';

export const mapStateToProps = state => ({
  contactForm: state.contactForm,
  contactUsTopics: state.contactUsTopics,
  contactUsTopicsQuering: state.contactUsTopics.querying
});

export const mapDispatchToProps = dispatch => ({
  handleSubmit: formData => dispatch(contactSubmit(formData)),
  getContactUsTopics: () => dispatch(getContactUsTopics())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm);
