import {
  FEATURED_NEWSLETTER_REQUEST_SUCCEED,
  FEATURED_NEWSLETTER_REQUEST_FAILED,
  FEATURED_NEWSLETTER_REQUEST_FINISHED,
  FEATURED_NEWSLETTER_REQUEST_STARTED
} from './types';
import api from '../../utils/api';

export const getFeaturedNewsletterRequested = () => ({
  type: FEATURED_NEWSLETTER_REQUEST_STARTED,
  payload: null
});

export const getFeaturedNewsletterSucceed = featuredNewsletter => ({
  type: FEATURED_NEWSLETTER_REQUEST_SUCCEED,
  payload: { featuredNewsletter }
});

export const getFeaturedNewsletterFailed = er => ({
  type: FEATURED_NEWSLETTER_REQUEST_FAILED,
  payload: { error: er }
});

export const getFeaturedNewsletterFinished = () => ({
  type: FEATURED_NEWSLETTER_REQUEST_FINISHED,
  payload: null
});

export const getFeaturedNewsletter = () => async dispatch => {
  dispatch(getFeaturedNewsletterRequested());
  try {
    const { data: newsletter } = await api.getFeaturedNewsletter();
    dispatch(getFeaturedNewsletterSucceed(newsletter));
  } catch (error) {
    dispatch(getFeaturedNewsletterFailed(error.response.data));
  } finally {
    dispatch(getFeaturedNewsletterFinished());
  }
};
