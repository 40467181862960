import React from 'react';
import { Block, Link } from 'lucentum';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { BlockLink, BlockContact, BlockLinks, BlockCopyright } from './styles';

export default () => (


    <BlockCopyright justifyMD hiddenXS>
        <div>
        <FormattedMessage
            tagName="p"
            id="portlet.common.footer.copyright"
            values={{ year: new Date().getFullYear() }}
          />
        </div>
        <BlockContact >
            <FormattedHTMLMessage
              id="portlet.common.footer.contact"
            />
        </BlockContact>



     
        <BlockLink >
          <Link href="/network/accessibility">
            <FormattedMessage
              id="portlet.common.footer.accessibility"
              tagName="strong"
            />
          </Link>
        </BlockLink>
        <BlockLink >
          <Link href="/network/data-protection">
            <FormattedMessage
              id="portlet.common.footer.data_protection"
              tagName="strong"
            />
          </Link>
        </BlockLink>
        <BlockLink >
          <Link href="/network/legal-notices">
            <FormattedMessage
              id="portlet.common.footer.legal_notice"
              tagName="strong"
            />
          </Link>
        </BlockLink>

  </BlockCopyright>


);

