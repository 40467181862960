import React from 'react';
import { Block } from 'lucentum';

import { Background, BlockStyled } from './styles';

export default ({ children, imgMobile, img, ...props }) => (
  <BlockStyled middle {...props}>
    <Background imgMobile={imgMobile} img={img} />
    <Block>{children}</Block>
  </BlockStyled>
);
