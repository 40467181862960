import {
  CONTACT_US_TOPICS_RECEIVED,
  CONTACT_US_TOPICS_REQUEST_FAILED,
  CONTACT_US_TOPICS_REQUEST_FINISHED,
  CONTACT_US_TOPICS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  contactUsTopics: null,
  error: null
};

export const contactUsTopicsRequested = state => ({ ...state, querying: true });

export const contactUsTopicsReceived = (state, action) => ({
  ...state,
  contactUsTopics: action.payload.contactUsTopics,
  error: null
});

export const contactUsTopicsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  contactUsTopics: null
});

const contactUsTopicsRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [CONTACT_US_TOPICS_REQUESTED]: contactUsTopicsRequested,
  [CONTACT_US_TOPICS_RECEIVED]: contactUsTopicsReceived,
  [CONTACT_US_TOPICS_REQUEST_FAILED]: contactUsTopicsRequestFailed,
  [CONTACT_US_TOPICS_REQUEST_FINISHED]: contactUsTopicsRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
