import React from 'react';
import { Block, Content, Image } from 'lucentum';

import { Footer, Underlined, ContactBlock } from './styles';
import {BlockCopyright, BlockLink, BlockLinks} from "../copyright/styles";
import {Link} from "../copyright/component";
import {FormattedMessage} from "react-intl";

export default ({
  children,
  // logo,
  renderContact,
  renderCopyright,
  ...props
}) => (
  <Footer {...props}>
    {/*<Underlined>*/}
      <Content>
        <Block justify>
          <Block justify style={{ flexDirection: 'column'}}>
            {/*<Block padding paddingLeft={15} paddingRight={0}>*/}
            <Block padding style={{ flexDirection: 'row',justifyContent: 'center',display:'flex',padding:'14px 0'}} paddingLeft={15} paddingRight={0}>
              {children}
            </Block >
            <ContactBlock paddingLeft={0} paddingRight={0}>
              {renderContact && renderContact()}
            </ContactBlock>
          </Block>
        </Block>
      </Content>
    {/*</Underlined>*/}
    <Content>
      {renderCopyright && (
        <Block padding paddingLeft={0} paddingRight={0} justify>
          {renderCopyright()}
        </Block>
      )}
    </Content>
  </Footer>


);
