import {
  FEATURED_ECPS_RECEIVED,
  FEATURED_ECPS_REQUEST_FAILED,
  FEATURED_ECPS_REQUEST_FINISHED,
  FEATURED_ECPS_REQUESTED,
  FEATURED_ECPS_PIN_ECP_SUCCEED,
  FEATURED_ECPS_PIN_ECP_FAILED
} from './types';

const INITIAL_STATE = {
  querying: false,
  featuredEcps: null,
  error: null
};

export const featuredEcpsRequested = state => ({ ...state, querying: true });
export const featuredEcpsReceived = (state, action) => ({
  ...state,
  featuredEcps: action.payload.featuredEcps,
  error: null
});

export const featuredEcpsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  featuredEcps: null
});

export const featuredEcpsRequestFinished = state => ({
  ...state,
  querying: false
});

export const pinEcpSucceed = (state, { payload: { ecpId } }) => ({
  ...state,
  featuredEcps: Array.isArray(state.featuredEcps)
    ? state.featuredEcps.map(ecp => ({
        ...ecp,
        favorite: !!(ecp.id === ecpId ? !ecp.favorite : ecp.favorite)
      }))
    : state.featuredEcps
});

export const pinEcpFailed = (state, { payload: { error } }) => ({
  ...state,
  featuredEcps: null,
  error
});

const reducersMap = {
  [FEATURED_ECPS_REQUESTED]: featuredEcpsRequested,
  [FEATURED_ECPS_RECEIVED]: featuredEcpsReceived,
  [FEATURED_ECPS_REQUEST_FAILED]: featuredEcpsRequestFailed,
  [FEATURED_ECPS_REQUEST_FINISHED]: featuredEcpsRequestFinished,
  [FEATURED_ECPS_PIN_ECP_SUCCEED]: pinEcpSucceed,
  [FEATURED_ECPS_PIN_ECP_FAILED]: pinEcpFailed
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
