import { Link } from 'lucentum';
import styled from 'styled-components';
import { them } from '../../commonStyles';
export default styled(Link)`
  @media (${them('breakpointMaxMD')}) {
    ${({ hiddenXS }) => hiddenXS && `display: none`};
  }
  nav > & {
    font-weight: ${({ active, activeVertical }) =>
      active || activeVertical ? 'bold' : 'normal'};

    padding-bottom: 2px;

    border-bottom: solid
      ${({ active, theme: { color, colorTransparent, lineThick } }) =>
        `${lineThick} ${active ? color : colorTransparent}`};
  }
`;
