import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Block, Content, P, Spinner } from 'lucentum';
import { StyledTitle } from '../../commonStyles/';
import Pagination from '../../components/pagination';

import {
  Page,
  DetailedNews,
  FiltersMenu,
  buildItemFilter
} from '../../components';
import {
  News as NewsProptype,
  NewsFilters as NewsFiltersPropTypes,
  Reducer as ReducerPropType
} from '../../propTypes';
import {
  ButtonStyles,
  BlockStyled,
  ModalStyled,
  ButtonStyled,
  StyledDetailedNews,
  Title
} from './styles';

import { DEFAULT_NEWS_FILTERS } from '../../utils/constants';
import Breadcrumbs from '../../components/breadcrumbs/component';

export const buildFilters = ({ topics }, chosenOptions) => [
  {
    ...buildItemFilter(
      topics,
      <FormattedMessage id="portlet.action.filter_topic" />,
      'topics',
      chosenOptions
    )
  }
];

export const BreadcrumbsWrapper = ({ page }) => (
  <Content>
    <Breadcrumbs
      src={[
        {
          text: <FormattedMessage id="portlet.section.home" />,
          url: '#/'
        },
        {
          text: <FormattedMessage id={`portlet.section.${page}`} />,
          url: `#/${page}`
        }
      ]}
    />
  </Content>
);

export const MobileFilterMenu = ({
  resetFilters,
  isOpen,
  onAfterOpen,
  onSubmit,
  onRequestClose,
  menu
}) => (
  <ModalStyled
    textButtonSubmit={
      <FormattedMessage id="portlet.action.filter_responsive_apply_button" />
    }
    isOpen={isOpen}
    title={
      <P>
        <span>
          <ButtonStyled onClick={resetFilters} link>
            <FormattedMessage id="portlet.action.filter_reset" />
          </ButtonStyled>
        </span>
        <Title>
          <FormattedMessage id="portlet.action.news.filter_responsive_title" />
        </Title>
      </P>
    }
    onAfterOpen={onAfterOpen}
    onSubmit={onSubmit}
    onRequestClose={onRequestClose}
  >
    {menu()}
  </ModalStyled>
);

export class News extends Component {
  state = {
    isOpen: false,
    filters: DEFAULT_NEWS_FILTERS,
    appliedFilters: DEFAULT_NEWS_FILTERS
  };

  onPageChange = (e, link) => {
    e.preventDefault();
    const { changeToPage, currentPage, pages } = this.props;
    link === 'right'
      ? changeToPage(currentPage + 1, this.state.filters)
      : link === 'left'
      ? changeToPage(currentPage - 1, this.state.filters)
      : link === 'first'
      ? changeToPage(1, this.state.filters)
      : changeToPage(pages, this.state.filters);
  };

  onMobileMenuSubmit = () => {
    this.applyFilters(this.state.filters);
    this.setState(() => ({ isOpen: false }));
  };

  onMobileMenuRequestClose = () =>
    this.setState(prevState => ({
      isOpen: false,
      filters: prevState.appliedFilters
    }));

  resetFilters = () => {
    this.setState(({ filters }) => ({
      filters: Object.keys(filters).reduce(
        (acc, field) => ({ ...acc, [field]: [] }),
        {}
      )
    }));
  };

  onFiltersChange = direct => filters => {
    this.setState(
      () => ({ filters }),
      () => direct && this.applyFilters(filters)
    );
  };

  applyFilters = filters => {
    this.setState(
      () => ({
        appliedFilters: filters
      }),
      () => this.props.changeToPage(1, filters)
    );
  };

  render = () => {
    const { news, newsFilters, queryingFilters } = this.props;
    const filters = buildFilters(
      newsFilters || DEFAULT_NEWS_FILTERS,
      this.state.filters
    );

    return (
      <Page
        languages={this.props.languages}
        active={this.props.active}
        className="page--news"
      >
        <BreadcrumbsWrapper page="news" />
        {queryingFilters ? (
          <Block center>
            <Spinner show row size={10} />
          </Block>
        ) : (
          <Block>
            <Content>
              <Block hiddenXS visibleLG marginBottom>
                <StyledTitle>
                  <FormattedMessage id="portlet.section.news" />
                </StyledTitle>
              </Block>
            </Content>
            <Content>
              <Block hiddenXS visibleLG width1of4 paddingRight marginTop>
                <Block highlight padding style={{ height: '100%' }}>
                  <FiltersMenu
                    filters={filters}
                    error={news.error}
                    onFiltersChange={this.onFiltersChange(true)}
                  />
                </Block>
              </Block>
              <Block hiddenXS visibleLG width2of3 marginTop>
                <DetailedNews
                  src={news.news}
                  querying={news.querying}
                  error={news.error}
                  news
                />
              </Block>
              <BlockStyled visibleXS hiddenLG width3of3>
                <StyledDetailedNews
                  src={news.news}
                  querying={news.querying}
                  error={news.error}
                  news
                  mobile
                />
              </BlockStyled>
            </Content>
            <Content>
              <Block width3of3 right>
                {this.props.pages >= 2 && (
                  <Pagination
                    className="paginator"
                    onPageChange={this.onPageChange}
                    pageTotalKey={this.props.pages}
                    pageKey={this.props.currentPage}
                  />
                )}
              </Block>
            </Content>
          </Block>
        )}

        <Content>
          <Block visibleXS hiddenLG>
            <Block alignContents="center" marginTop={2}>
              <ButtonStyles
                title={
                  <FormattedMessage id="portlet.action.filter_responsive_button" />
                }
                icon="filters"
                className="filters--button"
                onClick={() => this.setState({ isOpen: true })}
              />

              <MobileFilterMenu
                filters={filters}
                menu={() => (
                  <FiltersMenu
                    filters={filters}
                    error={news.error}
                    onFiltersChange={this.onFiltersChange(false)}
                  />
                )}
                isOpen={this.state.isOpen}
                onRequestClose={this.onMobileMenuRequestClose}
                onSubmit={this.onMobileMenuSubmit}
                resetFilters={this.resetFilters}
              />
            </Block>
          </Block>
        </Content>
      </Page>
    );
  };

  componentDidMount = () => this.props.getInitialData();
}

News.propTypes = {
  news: ReducerPropType({
    news: PropTypes.arrayOf(NewsProptype)
  }).isRequired,
  active: PropTypes.string.isRequired,
  newsFilters: NewsFiltersPropTypes,
  queryingFilters: PropTypes.bool.isRequired,
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  changeToPage: PropTypes.func.isRequired,
  getInitialData: PropTypes.func.isRequired
};

export default News;
