import React from 'react';
import { Link, Block, Spinner } from 'lucentum';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ErrorMessage from '../../error-message';
import { buildOfficesCategoryTree } from '../../members/';
import {
  EUIPOS_CATEGORY,
  USER_ASSOCIATION_CATEGORY,
  OTHER_MEMBERS_CATEGORY,
  NON_EU_IPOS_CATEGORY
} from '../../../utils/constants';
import {
  Office as OfficePropType,
  Reducer as ReducerPropType
} from '../../../propTypes';

import { OfficeCategory, StyledOfficeSpan } from './styles';

export const OfficeLink = ({ id, title }) => (
// {
//   let officeName = title
//   if(title === 'whatever') {
//     officeName=ASDFAS
//   }
//   return (
    <Link className={`office--${id}`} to={`#/members/${id}`}>
      {/*<StyledOfficeSpan>{officeName}</StyledOfficeSpan>*/}
      <StyledOfficeSpan>{title}</StyledOfficeSpan>
    </Link>
  );
// }

const getCategoryTitle = name =>
  name === EUIPOS_CATEGORY ? (
    <FormattedMessage id="portlet.members.euipos"> 
      {(txt) => (
        <span style={{'word-break': 'break-all'}}>
          {txt}
        </span>
      )}
    </FormattedMessage>
  ) : name === USER_ASSOCIATION_CATEGORY ? (
    <FormattedMessage id="portlet.members.userassociation" >
      {(txt) => (
        <span style={{'word-break': 'break-all'}}>
          {txt}
        </span>
      )}
    </FormattedMessage>
  ) : name === NON_EU_IPOS_CATEGORY ? (
    //<FormattedMessage id="portlet.members.noneuipos" />
    {/*<FormattedMessage id="Non-European IPOs" />*/}
  ) : (
    <FormattedMessage id="portlet.members.othermembers" >
      {(txt) => (
        <span style={{'word-break': 'break-all'}}>
          {txt}
        </span>
      )}
    </FormattedMessage>
  );

const getTopMargin = category => (category === EUIPOS_CATEGORY ? 0 : 3);

const CategoryOfficesList = ({ category, categorizedOffices }) => (
  <Block marginTop={getTopMargin(category)} key={`category--${category}`}>
    <OfficeCategory>{getCategoryTitle(category)}</OfficeCategory>
    {categorizedOffices[category] &&
      categorizedOffices[category]
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((office, i) => (
          <Block key={`${i}-euipos`}>
            <OfficeLink {...office} />
          </Block>
        ))}
  </Block>
);

const shouldShowErroMessage = (querying, error) => !querying && error;

const shouldShowOfficeList = (querying, offices) => !querying && offices;

const shouldShowOfficeTree = (querying, error, offices) =>
  querying || error || !offices;

const OfficesIndex = ({ offices, querying, error }) => {
  const categorizedOffices = shouldShowOfficeTree(querying, error, offices)
    ? []
    : buildOfficesCategoryTree(offices);
  const officeCategories = [
    EUIPOS_CATEGORY,
    OTHER_MEMBERS_CATEGORY,
    USER_ASSOCIATION_CATEGORY
  ];
  return (
    <Block>
      {querying && (
        <Block center>
          <Spinner show row size={8} />
        </Block>
      )}
      {shouldShowErroMessage(querying, error) && <ErrorMessage />}
      {shouldShowOfficeList(querying, offices) &&
        officeCategories.map(category => (
          <CategoryOfficesList
            key={category}
            category={category}
            categorizedOffices={categorizedOffices}
          />
        ))}
    </Block>
  );
};

OfficesIndex.propTypes = ReducerPropType({
  offices: PropTypes.arrayOf(OfficePropType)
}).isRequired;

export default OfficesIndex;
