import cookie from 'js-cookie';
import { Block, Link } from 'lucentum';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from 'react-simple-maps';
import { withTheme } from 'styled-components';
import { InnerWrapper, OuterWrapper, ScrollBar, Tooltip } from './styles';

const nameAsc = (a, b) => a.name.localeCompare(b.name);

const position = ([x, y]) => ({ left: `${+x}px`, top: `${+y}px` });

const prop = name => obj => obj[name];

const codeEquals = code => obj => obj.code === code;

const shortEquals = short => obj => obj.short === short;

const marked = (geo, code) => codeEquals(geo.properties[code]);

const highlight = ({ active }) => (active ? prop('color') : prop('colorLight'));

const hover = x => (x ? prop('color') : prop('colorAccent'));

const getColor = x => (x ? highlight(x) : prop('colorAccent'));

const setColor = (theme, marked, type) => ({
  fill: (!type ? getColor(marked) : hover(marked))(theme),
  stroke: !type ? theme.colorWhite : theme.colorTransparent,
  strokeWidth: 0.25,
  outline: 'none'
});

const activate = id => xs => {
  if (id === 'EM') {
    return xs.map(x => ({
      ...x,
      active: true
    }));
  }
  if (id === 'BX') {
    return xs.map(x => ({
      ...x,
      active: x.code === 'BE' || x.code === 'BX' || x.code === 'OPI'
    }));
  }
  return xs.map(x => ({
    ...x,
    active: x.code === id
  }));
};

const setColors = (theme, marked) => ({
  default: setColor(theme, marked),
  hover: setColor(theme, marked, 'hover'),
  pressed: setColor(theme, marked, 'pressed')
});

const styles = {
  width: '100%',
  height: '100%',
  overflow: 'hidden'
};

const minusCoords = () => {
  const fontSize = cookie.get('fontSize');
  return fontSize === '13px'
    ? 20
    : fontSize === '16px'
    ? 10
    : fontSize === '18px'
    ? 0
    : fontSize === '20px'
    ? -10
    : 10;
};

class ContinentMap extends Component {
  state = {
    tooltip: null,
    selected: null,
    coords: [null, null],
    countries: this.props.src.countries || [],
    short: null
  };

  render() {
    const { tooltip, selected, coords, countries, short } = this.state;
    const {
      center,
      continent,
      include,
      officeCode,
      shortCode,
      officeDetail,
      textBack,
      theme,
      tooltipText,
      topojsonUrl,
      zoom,
      ...props
    } = this.props;
    const selOffice = countries.find(country => {
      return codeEquals(selected)(country) && shortEquals(short)(country);
    });
    const setCoords = coords => this.setState({ coords });
    const setCountries = f =>
      this.setState(({ countries }) => ({ countries: f(countries) }));
    const setSelected = selected => this.setState({ selected });
    const setShort = short => this.setState({ short });
    const setTooltip = tooltip => this.setState({ tooltip });
    return (
      <Block>
        <Block padding highlight width1of4>
          {selOffice && (
            <Link onClick={() => setSelected(null)}>&lt; {textBack}</Link>
          )}
          <Block paddingLeft paddingTop>
            <ScrollBar>
              {!selOffice &&
                countries
                  .filter(x => x.belongs.includes(continent))
                  .sort(nameAsc)
                  .map(({ code, name, short }) => {
                    return (
                      short !== 'GGE2' && (
                        <Link
                          key={`${code}-${short}`}
                          href={code}
                          onClick={e => {
                            e.preventDefault();
                            setSelected(code);
                            setShort(short);
                          }}
                          onMouseOver={e => setCountries(activate(code))}
                          onMouseOut={e => setCountries(activate())}
                        >
                          {name}
                        </Link>
                      )
                    );
                  })}
              {selOffice && officeDetail && officeDetail(selOffice, props)}
            </ScrollBar>
          </Block>
        </Block>
        <Block width3of4>
          {tooltip && (
            <Tooltip {...tooltip} text={tooltipText} style={position(coords)} />
          )}
          <OuterWrapper>
            <InnerWrapper>
              <ComposableMap
                projectionConfig={{ scale: 205, rotation: [-10, 0, 0] }}
                width={980}
                height={551}
                style={styles}
              >
                <ZoomableGroup disablePanning center={center} zoom={zoom}>
                  <Geographies disableOptimization geography={topojsonUrl}>
                    {(geographies, projection) =>
                      geographies.map((geo, index) => {
                        return (
                          include.includes(geo.properties.ISO_A2) && (
                            <Geography
                              key={index}
                              cacheId={index}
                              geography={geo}
                              projection={projection}
                              style={setColors(
                                theme,
                                countries.find(marked(geo, officeCode))
                              )}
                              onClick={({ properties }) => {
                                const code = properties[officeCode];
                                const short = properties[shortCode];
                                setSelected(code);
                                setShort(short);
                                setCountries(activate(code));
                              }}
                              onMouseLeave={() => setTooltip(null)}
                              onMouseMove={(e, { clientX, clientY }) => {
                                const tooltip = countries.find(
                                  marked(geo, officeCode)
                                );

                                if (tooltip) {
                                  const x = clientX;
                                  const y =
                                    clientY +
                                    window.pageYOffset -
                                    minusCoords();
                                  setCoords([x, y]);
                                  setTooltip(tooltip);
                                }
                              }}
                            />
                          )
                        );
                      })
                    }
                  </Geographies>
                </ZoomableGroup>
              </ComposableMap>
            </InnerWrapper>
          </OuterWrapper>
        </Block>
      </Block>
    );
  }
}

ContinentMap.defaultProps = {
  center: [15, 53],
  continent: 'EUR',
  officeCode: 'IP_OFFICE',
  shortCode: 'SHORT',
  textBack: <FormattedMessage id="common.back" />,
  zoom: 4
};

export default withTheme(ContinentMap);
