import styled from 'styled-components';
import { H2, Block as BlockLucentum } from 'lucentum';

import TextFieldLucentum from '../components/textField';
import IconLucentum from '../components/icon';

const subProp = main => sub => obj => obj[main][sub];
export const them = subProp('theme');

export const Strong = styled.strong``;

export const StyledTitle = styled(H2)`
  font-weight: bold;
  margin: ${({ theme }) => theme.sizeSmall} auto ${({ theme }) => theme.size}
    auto;
  @media (${them('breakpointMaxMD')}) {
    font-size: ${({ theme }) => theme.fontSizeMedium};
    margin: 0 0 ${({ theme }) => theme.size} 0;
  }
`;

export const TextField = styled(TextFieldLucentum)`
  border: ${({ theme }) => ['solid ', theme.line, ' ', theme.colorGray]};
`;

export const Icon = styled(IconLucentum)`
  ${props =>
    props.big && `font-size: calc(${props.theme.fontSizeDouble} + 1rem)`};
  ${props => props.dimmed && `color: ${props.theme.colorLight}`};

  @media (${them('breakpointMaxSM')}) {
    ${props => props.big && `font-size: ${props.theme.fontSizeDouble}`};
  }
`;

export const BottomBar = styled(BlockLucentum)`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colorWhite};
`;

export const WidthAutoStyled = styled(BlockLucentum)`
  width: auto;
`;

export const BlockResponsive = styled(BlockLucentum)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    padding: ${({ theme }) => theme.size};
  }
`;

export const CardsContainer = styled(BlockLucentum)`
  @media (${({ theme }) => theme.breakpointLG}) {
    width: calc(100% / 3 - 2rem / 3);
    float: none;
    margin: 0;
    margin-right: 1em;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
`;

export const ResponsiveTitle = styled(H2)`
  font-weight: bold;
  margin: ${them('sizeSmall')} auto ${them('size')} auto;
  @media (${them('breakpointMaxMD')}) {
    font-size: ${them('fontSizeMedium')};
    margin: 0;
  }
`;

export const BlockTimeline = styled(BlockLucentum)`
  @media (${them('breakpointMaxMD')}) {
    margin-top: ${them('sizeDouble')};
    margin-bottom: ${them('sizeDouble')};
  }
`;

export const VideoBlock = styled(BlockLucentum)`
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;
