import React from 'react';
import {TrackingService} from '../../utils/MatomoService'
export default WrappedComponent => match => history => props =>  {
  const path = match.path.split('/', 2)[1];
  const url = match.url.split('/', 2)[1];
  TrackingService.trackRouting(history.location, path);
  return (
    <WrappedComponent active={(path === url && `/${path}`) || ''} {...props} />
  );
};
