import React from 'react';
import { Button } from 'lucentum';

import { Icon } from '..';

export default ({ src, title, ...props }) => (
  <Button
    type="dropdown"
    primary
    title={title}
    onDropdown={e => document.location.assign(e.props.src)}
    {...props}
  >
    {src.map(({ name, URL, icon }) => (
      <Button key={URL} src={URL}>
        <Icon {...{ [icon]: true }} />
        <span>{name}</span>
      </Button>
    ))}
  </Button>
);
