import {
  FEATURED_PRACTICES_RECEIVED,
  FEATURED_PRACTICES_REQUEST_FAILED,
  FEATURED_PRACTICES_REQUEST_FINISHED,
  FEATURED_PRACTICES_REQUESTED,
  FEATURED_PRACTICES_PIN_PRACTICE
} from './types';

import api from '../../utils/api';

export const getFeaturedPracticesRequested = () => ({
  type: FEATURED_PRACTICES_REQUESTED,
  payload: null
});

export const getFeaturedPracticesSucceed = featuredPractices => ({
  type: FEATURED_PRACTICES_RECEIVED,
  payload: { featuredPractices }
});

export const getFeaturedPracticesFailed = er => ({
  type: FEATURED_PRACTICES_REQUEST_FAILED,
  payload: { error: er }
});

export const getFeaturedPracticesFinished = () => ({
  type: FEATURED_PRACTICES_REQUEST_FINISHED,
  payload: null
});

export const pinPractice = practiceId => ({
  type: FEATURED_PRACTICES_PIN_PRACTICE,
  payload: { practiceId }
});

export const getFeaturedPractices = () => async dispatch => {
  dispatch(getFeaturedPracticesRequested());
  try {
    const { data: featuredPractices } = await api.getFeaturedPractices();
    dispatch(getFeaturedPracticesSucceed(featuredPractices));
  } catch (error) {
    dispatch(getFeaturedPracticesFailed(error.response.data));
  } finally {
    dispatch(getFeaturedPracticesFinished());
  }
};
