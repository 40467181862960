import api from '../../utils/api';
import { COMMON_PRACTICES_RECEIVED } from './types';
import {
  COMMON_PRACTICES_REQUEST_FAILED,
  COMMON_PRACTICES_REQUEST_FINISHED,
  COMMON_PRACTICES_REQUESTED
} from './types';

export const getCommonPracticesRequested = () => ({
  type: COMMON_PRACTICES_REQUESTED,
  payload: null
});
export const getCommonPracticesSucceed = categories => ({
  type: COMMON_PRACTICES_RECEIVED,
  payload: { categories }
});
export const getCommonPracticesFailed = error => ({
  type: COMMON_PRACTICES_REQUEST_FAILED,
  payload: { error }
});
export const getCommonPracticesFinished = () => ({
  type: COMMON_PRACTICES_REQUEST_FINISHED,
  payload: null
});
export const getCommonPractices = () => async dispatch => {
  dispatch(getCommonPracticesRequested());
  try {
    const { data: commonPractices } = await api.getCommonPractices();
    dispatch(getCommonPracticesSucceed(commonPractices));
  } catch (error) {
    dispatch(getCommonPracticesFailed(error.response.data));
  } finally {
    dispatch(getCommonPracticesFinished());
  }
};
