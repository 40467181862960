import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Block, Content, Image, Spinner, Button } from 'lucentum';

import HtmlDisplay from '../html-display';
import Timeline from '../timeline';
import AdvancedCard from '../advanced-card';
import ErrorMessage from '../error-message';
import {
  ResponsiveTitle,
  BlockResponsive,
  BlockTimeline,
  VideoBlock
} from '../../commonStyles';
import {
  Tool as ToolPropType,
  Reducer as ReducerPropType
} from '../../propTypes';

const hasSomeTimelineItems = timeline =>
  !!(Array.isArray(timeline) && timeline.length);

export const LogoOrTitle = ({ tool }) => (
  <Block hiddenLG>
    {tool.logo ? (
      <Image src={tool.logo} style={{ width: '100%' }} />
    ) : (
      <ResponsiveTitle>{tool.title}</ResponsiveTitle>
    )}
  </Block>
);

const shouldShowSpinner = querying => querying;

const shouldShowErrorMessage = (querying, error) => !querying && error;

const shouldShowTool = (querying, tool) => !querying && tool;

const shouldShowLaunchButton = url => url;

const shouldShowToolCard = url => url;

export const ToolAttachments = ({ tool }) => (
  <Block widthLG35of100 hiddenXS visibleLG>
    {shouldShowToolCard(tool.url) && (
      <AdvancedCard
        id={tool.id}
        logo={tool.logo}
        title={tool.title}
        url={tool.url}
        className="heightAuto"
      />
    )}
    {tool.documents &&
      tool.documents.map(({ title, downloadUrl }, i) => (
        <AdvancedCard
          downloadUrl={downloadUrl}
          title={title}
          key={`tool--document--${i}`}
          className="heightAuto"
        />
      ))}
    {Array.isArray(tool.videos) &&
      tool.videos.map((video, i) => (
        <VideoBlock marginTop={4} key={`tool--video--${i}`}>
          <iframe
            src={video.source}
            frameBorder="0"
            allow="encrypted-media"
            allowFullScreen
            title={`${i}-${video.source}`}
            width="100%"
          />
        </VideoBlock>
      ))}
  </Block>
);

const Tool = ({ error, querying, tool }) => (
  <Content>
    {shouldShowSpinner(querying) && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {shouldShowErrorMessage(querying, error) && <ErrorMessage />}
    {shouldShowTool(querying, tool) && (
      <Block>
        <BlockResponsive justify bottom paddingBottom>
          <Block>
            <LogoOrTitle tool={tool} />
            <Block hiddenXS visibleLG>
              <ResponsiveTitle>{tool.title}</ResponsiveTitle>
            </Block>
          </Block>
          {shouldShowLaunchButton(tool.url) && (
            <Block right hiddenLG>
              <Button
                className="launch--button"
                primary
                onClick={() => window.open(tool.url)}
              >
                <FormattedMessage id="portlet.action.launch" />
              </Button>
            </Block>
          )}
        </BlockResponsive>
        <BlockResponsive paddingTop>
          <HtmlDisplay paddingRight={2} widthLG65of100>
            {tool.longDescription}
          </HtmlDisplay>
          <ToolAttachments tool={tool} />
        </BlockResponsive>
        <BlockTimeline marginTop={2} marginBottom={2}>
          {hasSomeTimelineItems(tool.timeline) && (
            <Timeline
              seriesLeft={
                <FormattedMessage id="portlet.action.timelines.projects" />
              }
              seriesRight={
                <FormattedMessage id="portlet.text.timelines.implementations" />
              }
              src={tool.timeline}
            />
          )}
        </BlockTimeline>
      </Block>
    )}
  </Content>
);

Tool.propTypes = {
  ...ReducerPropType({
    tool: ToolPropType
  }).isRequired
};

export default Tool;
