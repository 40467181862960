import { connect } from 'react-redux';
import News from './component';
import { getNews, getNewsHomeData } from '../../redux/news/action-creators';

export const mapStateToProps = state => ({
  news: state.news,
  newsFilters: state.newsFilters.filters,
  queryingFilters: state.newsFilters.querying,
  pages: Math.ceil(state.news.numNews / 10),
  currentPage: state.news.currentPage
});
export const mapDispatchToProps = dispatch => ({
  changeToPage: (page, { topics = {} }) =>
    dispatch(
      getNews(page, {
        topics: Object.entries(topics)
          .filter(([key, value]) => !!value)
          .map(([key]) => parseInt(key, 10))
      })
    ),
  getInitialData: () => dispatch(getNewsHomeData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(News);
