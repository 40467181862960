import styled from 'styled-components';
import { P, Block } from 'lucentum';
import { them } from '../../commonStyles';

export const Node = styled(P)`
  display: inline-flex;
  align-items: center;

  i {
    margin: auto ${({ theme }) => theme.sizeSmall};
    display: inline-flex;
    align-items: center;
  }
`;

export const BreadcrumbsContainer = styled(Block)`
  @media (${them('breakpointMaxMD')}) {
    display: none;
  }
  margin: 1rem auto 0 auto;
  display: flex;
  align-items: center;
  float: none;
`;
