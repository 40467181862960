import styled from 'styled-components';
import { Detail, Card, Block } from 'lucentum';

import { them } from '../../commonStyles';

import sizes from '../page/sizes';

export const calculateCardHeight = currentFontSize => {
  const cardHeights = {
    'font.size.small': '190px',
    'font.size.medium': '210px',
    'font.size.large': '210px',
    'font.size.extralarge': '250px'
  };
  const size = sizes.find(({ size }) => size === currentFontSize);
  return size ? cardHeights[size.value] : cardHeights['font.size.medium'];
};

export const calculateTitleCardHeight = currentFontSize => {
  const cardHeights = {
    'font.size.small': '118px',
    'font.size.medium': '118px',
    'font.size.large': '118px',
    'font.size.extralarge': '118px'
  };
  const size = sizes.find(({ size }) => size === currentFontSize);
  return size ? cardHeights[size.value] : cardHeights['font.size.medium'];
};

export const StyledCard = styled(Card)`
  &.advanced-card {
    > button {
      z-index: 2;
    }
    @media (${them('breakpointMaxMD')}) {
      padding: ${them('sizeSmaller')};
      > button {
        display: none;
      }
    }
    line-height: calc(${({ theme }) => theme.size} * 1.7);
    margin-bottom: 1em;
    overflow: hidden;
    height: calc(${them('size')} * 18);

    &.heightAuto {
      height: auto;
      padding-bottom: calc(${them('size')} * 4);
    }

    .__react_component_tooltip {
      max-width: 320px;
      max-height: 90%;
      border-radius: 2px;

      ::before {
        margin-left: 20px;
      }
      &.place-right {
        margin-left: 20px;
      }
    }
  }
`;

export const Title = styled.strong`
  font-weight: 600;

  @media (${them('breakpointMaxMD')}) {
    color: ${them('color')};
    font-weight: 500;
    font-size: ${them('fontSizeMedium')};
  }
`;

export const Level = styled(Detail)`
  display: inline-block;
  font-size: ${them('fontSize')};
  font-weight: normal;
`;

export const ButtonsBlock = styled(Block)`
  //max-width: 220px;
  position: absolute;
  bottom: ${them('size')};
  right: ${them('size')};
  text-align: right;
  float: none;
  z-index: 1;
  width: calc(100% - (${them('size')} * 2));
  line-height: 1.15;

  .link {
    display: inline-block;
    margin-left: ${them('size')};
    margin-top: ${them('sizeSmall')};
  }
`;
