import PropTypes from 'prop-types';

export default PropTypes.shape({
  label: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      info: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      count: PropTypes.string.isRequired
    })
  ).isRequired
});
