import styled from 'styled-components';
import { Block } from 'lucentum';
import sizes from '../page/sizes';

export const calculateMarginCheckboxes = currentFontSize => {
  const filtersMarginTop = {
    'font.size.small': '1px',
    'font.size.medium': '3.4px',
    'font.size.large': '5px',
    'font.size.extralarge': '6px'
  };
  const size = sizes.find(({ size }) => size === currentFontSize);
  return size ? filtersMarginTop[size.value] : filtersMarginTop['font.size.medium'];
};

export const FiltersBlock = styled(Block)`
  fieldset {
    > div:last-child {
      > div > div > div {
        width: 100%;
      }
      label {
        display: flex;
        > input {
          width: auto;
        }
      }
      button {
        text-align: left;
        line-height: normal;
      }
    }
  }
`;
