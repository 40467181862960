import {
  FEATURED_EVENTS_REQUEST_SUCCEED,
  FEATURED_EVENTS_REQUEST_FAILED,
  FEATURED_EVENTS_REQUEST_FINISHED,
  FEATURED_EVENTS_REQUEST_STARTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  featuredEvents: null,
  error: null
};

export const featuredEventsRequested = state => ({ ...state, querying: true });

export const featuredEventsReceived = (state, action) => ({
  ...state,
  featuredEvents: action.payload.featuredEvents.map(event => ({
    text: event.location,
    href: `#/events/${event.eventId}`,
    ...event
  })),
  error: null
});

export const featuredEventsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  featuredEvents: null
});

const featuredEventsRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [FEATURED_EVENTS_REQUEST_STARTED]: featuredEventsRequested,
  [FEATURED_EVENTS_REQUEST_SUCCEED]: featuredEventsReceived,
  [FEATURED_EVENTS_REQUEST_FAILED]: featuredEventsRequestFailed,
  [FEATURED_EVENTS_REQUEST_FINISHED]: featuredEventsRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
