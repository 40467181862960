import {
  EVENT_RECEIVED,
  EVENT_REQUEST_FAILED,
  EVENT_REQUEST_FINISHED,
  EVENT_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  event: null,
  error: null
};

export const eventRequested = state => ({ ...state, querying: true });

export const eventReceived = (state, action) => ({
  ...state,
  event: action.payload.event,
  error: null
});

export const eventRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  event: null
});

const eventRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [EVENT_REQUESTED]: eventRequested,
  [EVENT_RECEIVED]: eventReceived,
  [EVENT_REQUEST_FAILED]: eventRequestFailed,
  [EVENT_REQUEST_FINISHED]: eventRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
