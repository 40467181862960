import {
  NEWS_FILTERS_RECEIVED,
  NEWS_FILTERS_REQUEST_FAILED,
  NEWS_FILTERS_REQUEST_FINISHED,
  NEWS_FILTERS_REQUESTED
} from './types';

export const getNewsFiltersRequested = () => ({
  type: NEWS_FILTERS_REQUESTED,
  payload: null
});

export const getNewsFiltersSucceed = filters => ({
  type: NEWS_FILTERS_RECEIVED,
  payload: { filters }
});

export const getNewsFiltersFailed = er => ({
  type: NEWS_FILTERS_REQUEST_FAILED,
  payload: { error: er }
});

export const getNewsFiltersFinished = () => ({
  type: NEWS_FILTERS_REQUEST_FINISHED,
  payload: null
});
