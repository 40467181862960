import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Events from './component';
import {
  getPaginatedEvents,
  getEventsHomeData
} from '../../redux/paginated-events/action-creators';

export const mapStateToProps = state => ({
  events: state.events,
  eventsFilters: state.eventsFilters,
  queryingFilters: state.eventsFilters.querying,
  paginatedEvents: state.paginatedEvents,
  pages: Math.ceil(state.paginatedEvents.numEvents / 5),
  currentPage: state.paginatedEvents.currentPage
});

export const mapDispatchToProps = dispatch => ({
  getPaginatedEvents: (page, filters) =>
    dispatch(
      getPaginatedEvents(
        page,
        Object.keys(filters).reduce(
          (acc, field) => ({
            ...acc,
            [field]: Object.entries(filters[field])
              .filter(([key, value]) => !!value)
              .map(([key]) => parseInt(key, 10))
          }),
          {}
        )
      )
    ),
  getInitialData: () => dispatch(getEventsHomeData())
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Events)
);
