import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Page from '../../components/page';
import { Practices as PracticesComponent } from '../../components';
import {
  Practice as PracticePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { Content } from 'lucentum';
import { FormattedMessage } from 'react-intl';

export default class Practices extends Component {
  render = () => {
    const {
      practices,
      active,
      cookieState,
      editCookie,
      pinPractice,
      isUserLoged
    } = this.props;
    return (
      <Page active={active} className="page--practices">
        <Content>
          <Breadcrumbs
            src={[
              {
                text: <FormattedMessage id="portlet.section.home" />,
                url: '#/'
              },
              {
                text: <FormattedMessage id="portlet.section.practices" />,
                url: '#/practices'
              }
            ]}
          />
        </Content>
        <PracticesComponent
          practices={practices.practices}
          querying={practices.querying}
          error={practices.error}
          cookieState={cookieState}
          createCookie={editCookie}
          pinPractice={pinPractice}
          isUserLoged={isUserLoged}
        />
      </Page>
    );
  };

  componentDidMount = () => {
    this.props.getPractices();
    this.props.createCookie();
  };

  static propTypes = {
    practices: ReducerPropType({
      practices: PropTypes.arrayOf(PracticePropType)
    }).isRequired,
    active: PropTypes.string.isRequired,
    cookieState: PropTypes.bool,
    createCookie: PropTypes.func.isRequired,
    pinPractice: PropTypes.func.isRequired,
    editCookie: PropTypes.func.isRequired
  };
}
