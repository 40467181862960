import styled from 'styled-components';
import { Block } from 'lucentum';
import sizes from '../page/sizes';

export const calculateMarginTopTooltipUser = currentFontSize => {
  const marginTopTooltipUser = {
    'font.size.small': '35px',
    'font.size.medium': '38px',
    'font.size.large': '42px',
    'font.size.extralarge': '49px'
  };
  const size = sizes.find(({ size }) => size === currentFontSize);
  return size ? marginTopTooltipUser[size.value] : marginTopTooltipUser['font.size.medium'];
};

export const calculateMarginLeftTooltipUser = currentFontSize => {
  const marginLeftTooltipUser = {
    'font.size.small': '21px',
    'font.size.medium': '21px',
    'font.size.large': '21px',
    'font.size.extralarge': '16px'
  };

  const size = sizes.find(({ size }) => size === currentFontSize);
  return size
    ? marginLeftTooltipUser[size.value]
    : marginLeftTooltipUser['font.size.medium'];
};

export const BlockStyled = styled(Block)`
  width: auto;
  display: flex;
  align-items: center;

  .tooltip-user{
    width:auto;
  }

  .tooltip-user:hover{
    .tooltip-user__message{
      display:block;
    } 
   }

  .tooltip-user__message {
    width: auto;
    display: none;
    position: absolute;
    border-bottom: 1px black
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF;
    margin-top: ${({ currentFontSize }) =>
      calculateMarginTopTooltipUser(currentFontSize)} 
    margin-left: ${({ currentFontSize }) =>
      calculateMarginLeftTooltipUser(currentFontSize)} 
  }


`;
