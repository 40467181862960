import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Filters, Filter, Block, Spinner } from 'lucentum';
import PropTypes from 'prop-types';

import { FiltersBlock } from './styles';
import {
  FiltersMenu as FiltersMenuPropType,
  Reducer as ReducerPropType
} from '../../propTypes';

export const WEBINAR_FILTER = intl => ({
  label: intl.formatMessage({ id: 'portlet.action.filter_webinar' }),
  labelKey: 'webinar',
  data: [
    {
      checked: false,
      name: intl.formatMessage({ id: 'portlet.webinar.yes' })
    },
    {
      checked: false,

      name: intl.formatMessage({ id: 'portlet.webinar.no' })
    }
  ]
});

export const buildItemFilter = (items, label, labelKey, chosenOptions) => ({
  label,
  labelKey,
  data: [
    ...items.reduce(
      (prev, { title: name, id: value }) =>
        !prev.find(item => item.value === value)
          ? [
              ...prev,
              {
                checked: !!(
                  chosenOptions[labelKey] && chosenOptions[labelKey][value]
                ),
                name,
                value
              }
            ]
          : prev,
      []
    )
  ]
});

const buildCheckedFilters = filters =>
  filters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.labelKey]: filter.data.reduce(
        (accOptions, { value, checked }) => ({
          ...accOptions,
          [value]: checked
        }),
        {}
      )
    }),
    {}
  );

export default class FiltersMenu extends Component {
  state = {
    checked: buildCheckedFilters(this.props.filters)
  };

  componentDidUpdate = ({ filters }) => {
    if (filters !== this.props.filters) {
      this.setState(() => ({
        checked: buildCheckedFilters(this.props.filters)
      }));
    }
  };

  saveChecked = (opt, data, key) => {
    let labelKey = key;
    let itemName = data.value;

    this.setState(
      prev => ({
        checked: {
          ...prev.checked,
          [labelKey]: {
            ...(prev.checked[labelKey] || {}),
            [itemName]: !(
              prev.checked[labelKey] && prev.checked[labelKey][itemName]
            )
          }
        }
      }),
      () => this.props.onFiltersChange(this.state.checked)
    );
  };

  render = () => {
    const { querying, filters } = this.props;

    return (
      <FiltersBlock className="filters--calculate-margin">
        {querying && (
          <Block center>
            <Spinner show row size={5} />
          </Block>
        )}
        {!querying && filters && (
          <Filters
            src={filters}
            onSelectFilter={this.saveChecked.bind(this)}
            textShowAll={
              <span>
                <FormattedMessage id="portlet.action.filter.see_all_results_1" />{' '}
              </span>
            }
            textResults={
              <span>
                {' '}
                <FormattedMessage id="portlet.action.filter.see_all_results_2" />
              </span>
            }
            textShowLess={<FormattedMessage id="portlet.action.show_less" />}
          >
            {filters.map((firstLevel, i) => (
              <Filter
                key={i}
                labelKey={firstLevel.labelKey}
                label={firstLevel.label}
              />
            ))}
          </Filters>
        )}
      </FiltersBlock>
    );
  };
}

FiltersMenu.propTypes = {
  ...ReducerPropType({
    filters: PropTypes.arrayOf(FiltersMenuPropType)
  }).isRequired
};
