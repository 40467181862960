import {
  SUBSCRIBE_RECEIVED,
  SUBSCRIBE_REQUEST_FAILED,
  SUBSCRIBE_REQUEST_FINISHED,
  SUBSCRIBE_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  subscribed: false,
  error: null
};

export const subscribeNewsletterRequested = state => ({
  ...state,
  querying: true
});

export const subscribeNewsletterReceived = (state, action) => ({
  ...state,
  subscribed: action.payload.subscribed,
  error: null
});

export const subscribeNewsletterRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  subscribed: null
});

export const subscribeNewsletterRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [SUBSCRIBE_REQUESTED]: subscribeNewsletterRequested,
  [SUBSCRIBE_RECEIVED]: subscribeNewsletterReceived,
  [SUBSCRIBE_REQUEST_FAILED]: subscribeNewsletterRequestFailed,
  [SUBSCRIBE_REQUEST_FINISHED]: subscribeNewsletterRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
