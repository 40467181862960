import React from 'react';
import { Block, Button } from 'lucentum';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {BlockLink } from "../copyright/styles";
import {BlockContact} from "./styles";

export default () => (
  <Block>
    <Block row>
      <Block >
        <BlockContact >
          <FormattedHTMLMessage id='EUIPN European Union Intellectual Property Network   |   Avenida de Europa, 4 E-03008 Alicante (SPAIN)   |   Information Centre:  +34 96 513 9100' tagName="p" />
        </BlockContact>
      </Block>
    </Block>
  </Block>

);
