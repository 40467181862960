import React from 'react';

import { InputStyled, IconStyled, StyledBlockSearch } from './styles';

export default ({
  activeSearch,
  onChangeSearch,
  onClickSearch,
  onEnterChange,
  currentFontSize,
  ...props
}) => (
  <StyledBlockSearch currentFontSize={currentFontSize} right middle {...props}>
    <InputStyled
      search={activeSearch}
      onChange={onChangeSearch}
      onKeyPress={onEnterChange}
    />
    <IconStyled search onClick={onClickSearch} />
  </StyledBlockSearch>
);
