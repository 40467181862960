import React from 'react';
import PropTypes from 'prop-types';

import Page from '../../components/page';
import NewsItemComponent from '../../components/newsItem';
import {
  News as NewsPropTypes,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { FormattedMessage } from 'react-intl';
import { Content } from 'lucentum';

const NewsItem = ({ newsItem, active }) => (
  <Page active={active}>
    <Content>
      <Breadcrumbs
        src={[
          { text: <FormattedMessage id="portlet.section.home" />, url: '#/' },
          {
            text: <FormattedMessage id="portlet.section.news" />,
            url: '#/news'
          },
          {
            text: '',
            url: '#/news'
          }
        ]}
      />
    </Content>
    <NewsItemComponent
      newsItem={newsItem.newsItem}
      querying={newsItem.querying}
      error={newsItem.error}
    />
  </Page>
);

NewsItem.propTypes = {
  newsItem: ReducerPropType({
    newsItem: NewsPropTypes
  }).isRequired,
  active: PropTypes.string.isRequired
};

export default NewsItem;
