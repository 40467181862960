import {
  HERO_RECEIVED,
  HERO_REQUEST_FAILED,
  HERO_REQUEST_FINISHED,
  HERO_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  hero: null,
  error: null
};

export const heroRequested = state => ({ ...state, querying: true });
export const heroReceived = (state, action) => ({
  ...state,
  hero: action.payload.hero,
  error: null
});

export const heroRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  hero: null
});

export const heroRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [HERO_REQUESTED]: heroRequested,
  [HERO_RECEIVED]: heroReceived,
  [HERO_REQUEST_FAILED]: heroRequestFailed,
  [HERO_REQUEST_FINISHED]: heroRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
