import PropTypes from 'prop-types';

export const EventDetail = PropTypes.shape({
  begin: PropTypes.number.isRequired,
  end: PropTypes.number
}).isRequired;

export default PropTypes.shape({
  eventId: PropTypes.number.isRequired,
  href: PropTypes.string,
  beginDate: PropTypes.number.isRequired,
  endDate: PropTypes.number,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  description: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  office: PropTypes.string.isRequired,
  officeId: PropTypes.number.isRequired,
  topic: PropTypes.string.isRequired,
  topicId: PropTypes.number.isRequired,
  webinar: PropTypes.bool.isRequired
});
