import styled from 'styled-components';
import { Block } from 'lucentum';
import cookie from 'js-cookie';
import { them } from '../../commonStyles';
import sizes from '../page/sizes';

export const calculateMaxheight = () => {
  const fontSize = cookie.get('fontSize');
  const cardHeights = {
    'font.size.small': '42px',
    'font.size.medium': '51px',
    'font.size.large': '57px',
    'font.size.extralarge': '63px'
  };
  const size = sizes.find(({ size }) => size === fontSize);
  return size ? cardHeights[size.value] : cardHeights['font.size.medium'];
};
export const Background = styled(Block)`
  background-color: ${them('color')};
  background-image: ${({ imgMobile }) => `url(${imgMobile})`};
  background-position: 325% 75%;
  background-repeat: no-repeat;
  background-size: 85%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  @media (${them('breakpointLG')}) {
    background-color: ${them('colorGrayAccent')};
    background-image: ${({ img }) => `url(${img})`};
    background-position: 100% 70%;
    background-size: 20%;
  }
`;

export const BlockStyled = styled(Block)`
  background-color: white;
  position: relative;
  text-align: center;
  img {
    width: calc(100% - 40px);
    object-fit: cover;
    height: inherit;
    margin-left: 40px;
  }
  @media (${them('breakpointLG')}) {
    height: 220px;
  }
`;

export const BlockOpacity = styled(Block)`
  background-color: rgba(247, 247, 247, 1);
  position: absolute;
  width: 290px;
  height: 190px;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flrx-start;
  text-align: left;
  top: 15px;
  color: black;
  boxShadow: rgba(160, 160, 159, 0.4) 1px 1px 4px;
  @media (${them('breakpointLG')}) {
    
    h3 {
      padding: 0 15px;
      margin: 10px 0 7px 0;
      line-height: normal;
      font-weight: normal;
      max-height: ${() => calculateMaxheight()};
      overflow: hidden;      
    }
    P {
      padding: 0 15px;
      font-size: 12px;
      max-height: 51px;
      overflow: hidden;
    }

    a:link, a:active {
      position: absolute;
      right: 15px;
      bottom: 15px;
      border: 1px solid rgb(47, 80, 154);
      padding: 4px 10px;
      font-size: 12px;
      text-decoration: none;
      &:hover {
        color: white;
        background-color: rgb(159, 177, 193);
        outline: none;
      }
    }
  }
`;
