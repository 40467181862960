import {
    GET_MATOMO_PROPERTIES_RECEIVED,
    GET_MATOMO_PROPERTIES_FINISHED,
    GET_MATOMO_PROPERTIES_FAILED,
    GET_MATOMO_PROPERTIES_REQUESTED
} from './types';
import { TrackingService } from '../../utils/MatomoService'


const u = 'https://local.matomo.cloud/'
const INITIAL_STATE = {
    cookieDomain: '*',
    disabled: true,
    siteId: 0,
    trackerURL: ''
};
  
  
  
export const matomoPropertiesRequested = (state, action) => { return ({
    ...state,
})};
export const matomoPropertiesFailed = (state, action) => { return ({
    ...state,
    matomoProperties: INITIAL_STATE
})};
export const matomoPropertiesReceived = (state, action) => {TrackingService.init(action.payload.matomoObject); return ({
    ...state,
    matomoProperties: action.payload.matomoObject
})};
  
  
const reducersMap = {
[GET_MATOMO_PROPERTIES_REQUESTED]: matomoPropertiesRequested,
 [GET_MATOMO_PROPERTIES_RECEIVED]: matomoPropertiesReceived,
[GET_MATOMO_PROPERTIES_FAILED]: matomoPropertiesFailed,
/*[GET_MATOMO_PROPERTIES_FINISHED]: matomoPropertiesFinished, */

};
  
  export default (state = INITIAL_STATE, action) =>
    reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
  


