import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Fieldset, Block } from 'lucentum';

import { Select, TextArea } from '../../';
import { Field as FieldPropTypes } from '../../../propTypes';
import { Strong, TextField } from '../../../commonStyles';

const SelectField = ({
  title,
  markAsFocused,
  src,
  handleSelect,
  required,
  focused,
  filled,
  placeholder
}) => (
  <Fieldset label={<Strong>{title}</Strong>} onBlur={markAsFocused}>
    <Block>
      <Select
        src={src}
        onChange={handleSelect}
        required={required && focused}
        placeholder={placeholder}
        defaultValue=""
        errorMessage={
          required &&
          focused &&
          !filled && <FormattedMessage id="portlet.form.error.topic" />
        }
      />
    </Block>
  </Fieldset>
);

const TextareaField = ({
  title,
  markAsFocused,
  type,
  field,
  updateFormField,
  focused,
  filled,
  required
}) => (
  <Fieldset label={<Strong>{title}</Strong>} onBlur={markAsFocused}>
    <Block hiddenXS visibleLG>
      <TextArea
        rows={10}
        type={type}
        name={field}
        className="form--input"
        onChange={updateFormField}
        showError={focused && !filled}
        errorMessage={
          <FormattedMessage
            id={`portlet.form.error.${type === 'textArea' ? field : 'email'}`}
          />
        }
        required={required && focused}
      />
    </Block>
    <Block visibleXS hiddenLG>
      <TextField
        type={type}
        name={field}
        className="form--input"
        onChange={updateFormField}
        showError={focused && !filled}
        errorMessage={
          <FormattedMessage
            id={`portlet.form.error.${type === 'text' ? field : 'email'}`}
          />
        }
        required={required && focused}
      />
    </Block>
  </Fieldset>
);

const DefaultField = ({
  title,
  markAsFocused,
  type,
  field,
  updateFormField,
  focused,
  filled,
  required
}) => (
  <Fieldset label={<Strong>{title}</Strong>} onBlur={markAsFocused}>
    <TextField
      type={type}
      name={field}
      className="form--input"
      onChange={updateFormField}
      showError={focused && !filled}
      errorMessage={
        <FormattedMessage
          id={`portlet.form.error.${type === 'text' ? field : 'email'}`}
        />
      }
      required={required && focused}
    />
  </Fieldset>
);

const Field = ({
  markAsFocused,
  title,
  required,
  field,
  updateFormField,
  focused,
  filled,
  type = 'text',
  src,
  handleSelect,
  placeholder
}) => {
  switch (type) {
    case 'select':
      return (
        <SelectField
          title={title}
          markAsFocused={markAsFocused}
          src={src}
          handleSelect={handleSelect}
          required={required}
          focused={focused}
          filled={filled}
          placeholder={placeholder}
        />
      );
    case 'textArea':
      return (
        <TextareaField
          title={title}
          markAsFocused={markAsFocused}
          type={type}
          field={field}
          updateFormField={updateFormField}
          focused={focused}
          filled={filled}
          required={required}
        />
      );
    default:
      return (
        <DefaultField
          title={title}
          markAsFocused={markAsFocused}
          type={type}
          field={field}
          updateFormField={updateFormField}
          focused={focused}
          filled={filled}
          required={required}
        />
      );
  }
};

Field.propTypes = {
  ...FieldPropTypes.isRequired
};

export default Field;
