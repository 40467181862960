import {
  NEWS_RECEIVED,
  NEWS_REQUEST_FAILED,
  NEWS_REQUEST_FINISHED,
  NEWS_REQUESTED
} from './types';

import api from '../../utils/api';
import {
  getNewsFiltersSucceed,
  getNewsFiltersFailed,
  getNewsFiltersFinished,
  getNewsFiltersRequested
} from '../newsFilters/action-creators';

const ITEMS_PER_PAGE = 10;

export const getNewsRequested = () => ({
  type: NEWS_REQUESTED,
  payload: null
});

export const getNewsSucceed = (news, numNews, currentPage) => ({
  type: NEWS_RECEIVED,
  payload: { news, numNews, currentPage }
});

export const getNewsFailed = er => ({
  type: NEWS_REQUEST_FAILED,
  payload: { error: er }
});

export const getNewsFinished = () => ({
  type: NEWS_REQUEST_FINISHED,
  payload: null
});

export const getNews = (currentPage = 1, filters = {}) => async dispatch => {
  dispatch(getNewsRequested());
  try {
    const {
      data: { news, numNews }
    } = await api.getNews(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
      filters
    );
    dispatch(getNewsSucceed(news, numNews, currentPage));
  } catch (error) {
    dispatch(getNewsFailed(error.response.data));
  } finally {
    dispatch(getNewsFinished());
  }
};

export const getNewsHomeData = () => async dispatch => {
  dispatch(getNewsFiltersRequested());

  try {
    const {
      data: {
        newsFilters: filters,
        newsPaginated: { news, numNews: nNews }
      }
    } = await api.getNewsHomeData();
    dispatch(getNewsSucceed(news, nNews, 1));
    dispatch(getNewsFiltersSucceed(filters));
  } catch (error) {
    dispatch(getNewsFailed(error.response.data));
    dispatch(getNewsFiltersFailed(error.response.data));
  } finally {
    dispatch(getNewsFiltersFinished());
    dispatch(getNewsFinished());
  }
};
