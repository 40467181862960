import {
  CREATE_PRACTICE_COOKIE_STATE,
  PRACTICES_PIN_PRACTICE,
  PRACTICES_RECEIVED,
  PRACTICES_REQUEST_FAILED,
  PRACTICES_REQUEST_FINISHED,
  PRACTICES_REQUESTED
} from './types';

import api from '../../utils/api';

import Cookies from 'js-cookie';

export const getPracticesRequested = () => ({
  type: PRACTICES_REQUESTED,
  payload: null
});

export const getPracticesSucceed = practices => ({
  type: PRACTICES_RECEIVED,
  payload: { practices }
});

export const getPracticesFailed = er => ({
  type: PRACTICES_REQUEST_FAILED,
  payload: { error: er }
});

export const getPracticesFinished = () => ({
  type: PRACTICES_REQUEST_FINISHED,
  payload: null
});

export const parsePractices = practices =>
  practices.map(practice => ({
    ...practice,
    id: practice.practiceId,
    infoLink: `#/practices/${practice.practiceId}`
  }));

export const setCookieState = cookieState => ({
  type: CREATE_PRACTICE_COOKIE_STATE,
  payload: { cookieState }
});

export const pinPractice = practiceId => ({
  type: PRACTICES_PIN_PRACTICE,
  payload: { practiceId }
});

export const getPractices = () => async dispatch => {
  dispatch(getPracticesRequested());
  try {
    const { data: practices } = await api.getPractices();
    dispatch(getPracticesSucceed(practices));
  } catch (error) {
    dispatch(getPracticesFailed(error.response.data));
  } finally {
    dispatch(getPracticesFinished());
  }
};

export const createCookieState = () => dispatch => {
  const cookieState = !!Cookies.get('closePracticesNotification');
  dispatch(setCookieState(cookieState));
};

export const changeCookieState = () => dispatch => {
  Cookies.set('closePracticesNotification', 'true', { path: '/' });
  dispatch(setCookieState(true));
};
