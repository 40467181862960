import styled from 'styled-components';
import { Block as LucentumBlock } from 'lucentum';

export const SelectContainer = styled(LucentumBlock)`
  height: 100%;
  > div,
  .Select {
    margin: 0;
    height: 100%;
  }
  .Select-input input {
    line-height: normal;
  }

  ${({ hasError, theme: { colorInputError } }) =>
    hasError &&
    `
    .Select-control {
      border-color: ${colorInputError}
    }

    > div > div:nth-child(2) > p {
      margin-bottom:0px;
    }

    > div > div:nth-child(2) {
      max-width: 320px;
      border-radius: 2px;
      margin-left: 20px;
      background-color: ${colorInputError};
      color: #fff;
      &:after {
        border-right-color: ${colorInputError};
      }
    }
  `}
`;
