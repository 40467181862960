import React from 'react';
import { Block, Content, Link } from 'lucentum';
import { Language, Login, FontSize, Search } from '../';
import { WidthAutoStyled } from '../../commonStyles';
import {
  TopStyled,
  DesktopTopHeader,
  DesktopLogo,
  HeaderBlock
} from './styles';

export default ({
  activeSearch,
  children,
  currentLang,
  fontSizes,
  language,
  showSearch,
  languageSelectTestId,
  login,
  logo,
  onChangeSearch,
  onClickSearch,
  onEnterChange,
  onLanguage,
  userLoading,
  onResize,
  currentFontSize,
  ...props
}) => (
  <Block hiddenXS visibleLG>
    <Block column {...props}>
      <TopStyled>
        <DesktopTopHeader currentFontSize={currentFontSize}>
          <HeaderBlock paddingTop paddingBottom right>
            <WidthAutoStyled right middle>
              {showSearch && (
                <Search
                  currentFontSize={currentFontSize}
                  activeSearch={activeSearch}
                  onChangeSearch={onChangeSearch}
                  onClickSearch={onClickSearch}
                  onEnterChange={onEnterChange}
                />
              )}
            </WidthAutoStyled>
            {fontSizes && (
              <FontSize
                onResize={({ props: { size } }) => onResize(size)}
                fontSizes={fontSizes}
              />
            )}
            {language && (
              <Language
                currentLang={currentLang}
                onLanguage={onLanguage}
                primary
                src={language}
                testId={languageSelectTestId}
              />
            )}
            {!userLoading && (
              <Login src={login} {...props} currentFontSize={currentFontSize} />
            )}
          </HeaderBlock>
        </DesktopTopHeader>
      </TopStyled>
      <Block paddingTop paddingBottom>
        <Content>
          <Block width1of5>
            <Link to="#/">
              <DesktopLogo src={logo} />
            </Link>
          </Block>
          <Block
            style={{
              alignItems: 'center',
              display: 'flex',
              marginLeft: '40px'
            }}
          >
            {children}
          </Block>
        </Content>
      </Block>
    </Block>
  </Block>
);
