import styled from 'styled-components';
import { Block } from 'lucentum';

export const inputMixin = ({ theme }) => `
  border-radius: ${theme.radius};
  border: ${['solid', theme.line, theme.colorGray].join(' ')};
  box-sizing: border-box;
  display: inline-block;
  font: inherit;
  padding: ${theme.sizeSmall};
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${theme.color};
    box-shadow: ${theme.focusShadow};
  }

  &:not(:focus):invalid {
    border-color: ${theme.colorInputError};
    box-shadow: ${theme.focusShadowError};
  }
`;

export const Textfield = styled.div`
  align-items: center;
  display: flex;
  flex: 1 auto;
  position: relative;

  & input {
    ${props => inputMixin(props)}
  }
`;

export const Wrapper = styled(Block)`
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(${({ theme }) => theme.sizeDouble} * 2);
  button {
    opacity: ${({ showClear }) => (showClear ? 1 : 0)};
    cursor: ${({ showClear }) => (showClear ? 'pointer' : 'initial')};
    padding: 0;
    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.colorTransparent};
      color: ${({ theme }) => theme.colorGray};
    }
  }
`;
export const ErrorMessage = styled.div`
  max-width: 320px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colorInputError};
  border-radius: ${({ theme }) => theme.radius};
  color: #fff;
  font-size: ${({ theme }) => theme.fontSize};
  left: 100%;
  margin-left: 20px;
  min-width: calc(${({ theme }) => theme.size} * 10);
  padding: ${({ theme }) => theme.size};
  position: absolute;
  z-index: 9999;

  &:after {
    border-bottom: 10px solid ${({ theme }) => theme.colorTransparent};
    border-right: solid 6px ${({ theme }) => theme.colorInputError};
    border-top: 10px solid ${({ theme }) => theme.colorTransparent};
    content: '';
    left: -5px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    z-index: 99;
  }
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    display: none;
  }
`;

export const ErrorTextField = styled(ErrorMessage)`
  display: ${props => (props.showError ? 'inline-block' : 'none')};
  input:not(:focus):invalid + & {
    display: inline-block;
  }
`;
