import styled from 'styled-components';
import { Link } from 'lucentum';
import { PracticesBlock } from '../practices/styles';

export const CommonPracticeLink = styled(Link)`
  margin-bottom: 0.5rem;
`;
export const CommonPracticeBlock = styled(PracticesBlock)`
  > div:first-child {
    height: 7rem;
  }
  &:last-of-type {
    padding-right: 0;
  }
  @media (${({ theme }) => theme.breakpointMaxXS}) {
    > div:first-child {
      height: 100%;
      min-height: 0;
    }
  }
`;
