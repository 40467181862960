import styled from 'styled-components';
import { Block } from 'lucentum';

export const BlockLink = styled(Block)`
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
  width : 100%;
  text-align: center;
  flex: 1;
  padding-left: 8px;
`;

export const BlockContact = styled(Block)`
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  color: white;
  flex: 5;
  width: auto;
  text-align: center;
`;

export const BlockLinks = styled(Block)`
  display: flex;
  justify-content: space-around;
`;

export const BlockCopyright = styled(Block)`
  font-size: 11px;
`;
