import {
  FEATURED_NEWSLETTER_REQUEST_SUCCEED,
  FEATURED_NEWSLETTER_REQUEST_FAILED,
  FEATURED_NEWSLETTER_REQUEST_FINISHED,
  FEATURED_NEWSLETTER_REQUEST_STARTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  featuredNewsletter: null,
  error: null
};

export const featuredNewsletterRequested = state => ({
  ...state,
  querying: true
});

export const featuredNewsletterReceived = (state, action) => ({
  ...state,
  featuredNewsletter: action.payload.featuredNewsletter,
  error: null
});

export const featuredNewsletterRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  featuredNewsletter: null
});

const featuredNewsletterRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [FEATURED_NEWSLETTER_REQUEST_STARTED]: featuredNewsletterRequested,
  [FEATURED_NEWSLETTER_REQUEST_SUCCEED]: featuredNewsletterReceived,
  [FEATURED_NEWSLETTER_REQUEST_FAILED]: featuredNewsletterRequestFailed,
  [FEATURED_NEWSLETTER_REQUEST_FINISHED]: featuredNewsletterRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
