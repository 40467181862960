import styled from 'styled-components';
import { H2, Icon, Block } from 'lucentum';
import AdvancedCard from '../advanced-card';

export const BlockNewsStyled = styled(Block)`
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const CardStyled = styled(AdvancedCard)`
  strong {
    font-size: ${({ theme }) => theme.fontSizeLarge};
    font-weight: ${({ theme }) => theme.fontWeight};
  }

  width: calc(33% - (1 / 2) * ${({ theme }) => theme.size});
  margin-right: ${({ theme }) => theme.size};
  &:nth-of-type(3n) {
    margin-right: 0;
  }
`;

export const BlockStyled = styled(Block)`
  display: flex;
  flex-direction: column;
  flex: 9;
  width: auto;
`;

export const MainBlockStyled = styled(Block)`
  display: flex;
  float: none;
`;

export const BlockInputStyled = styled(Block)`
  width: auto;
  display: flex;
  margin-top: ${({ theme }) => theme.sizeSmall};
`;

export const BlockIconStyled = styled(Block)`
  float: none;
  justify-content: flex-end;
  display: flex;
  flex: 1;
  margin: auto 0;
  width: auto;
`;

export const StyledH2 = styled(H2)`
  color: ${({ theme }) => theme.colorWhite}
  font-size: ${({ theme }) => `calc(${theme.fontSizeLarge} + 0.5rem)`};
  font-weight: bold;
`;
export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.newsletterIconColor};
  font-size: ${({ theme }) => `calc(${theme.fontSizeLarge} + 3.5rem)`};
`;
