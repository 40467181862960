import React from 'react';
import { H2 } from 'lucentum';

import { Strong } from '../../commonStyles';
import { Label } from './styles';

export default ({ text, ...props }) => (
  <Label {...props}>
    <H2>
      <Strong>{text}</Strong>
    </H2>
  </Label>
);
