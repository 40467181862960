import { Block } from 'lucentum';
import styled from 'styled-components';
import { lastBlock } from '../practices/styles';

export const Description = styled(Block)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    p {
      margin-top: 0;
    }
  }
`;

export const SelectWrapper = styled (Block)`      
      left: 15px;
      
      align-items:center;
      justify-content:flex-end;
      display: flex;
`;


export const EcpsList = styled(Block)`
  @media (${({ theme }) => theme.breakpointMaxMD}) {
    p {
      margin-top: ${({ theme }) => theme.sizeLarge};
    }
    > div {
      border: none;
    }
    > div:nth-child(2) {
      > div {
        padding-right: 0;
      }
    }

    h1 {
      font-size: ${({ theme }) => theme.fontSizeLarge};
      color: ${({ theme }) => theme.colorGray};
    }
    margin: ${({ theme }) => theme.size} 0;
  }

  ${lastBlock}

  > div:first-child {
    align-items: flex-end;
    height: auto;
    > h1 {
      line-height: 1;
    }
  }
`;
