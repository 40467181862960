import Cookies from 'js-cookie';

import {
  GET_LOGGED_USER_FAILED,
  GET_LOGGED_USER_FINISHED,
  GET_LOGGED_USER_RECEIVED,
  GET_LOGGED_USER_REQUESTED,
  CHANGE_FONT_SIZE
} from './types';
import { getAuthToken } from '../authToken/action-creators';
import availableFontSizes from '../../components/page/sizes';
import { subscribeNewsletterSucceed } from '../subscribeToNewsletter/action-creators';
import api from '../../utils/api';
import { FONT_SIZE_COOKIE_NAME } from '../../utils/constants';

export const getLoggedUserRequested = () => ({
  type: GET_LOGGED_USER_REQUESTED,
  payload: null
});

export const getLoggedUserReceived = user => ({
  type: GET_LOGGED_USER_RECEIVED,
  payload: { user }
});

export const getLoggedUserFailed = error => ({
  type: GET_LOGGED_USER_FAILED,
  payload: { error }
});

export const getLoggedUserFinished = () => ({
  type: GET_LOGGED_USER_FINISHED,
  payload: null
});

export const changeFontSize = size => ({
  type: CHANGE_FONT_SIZE,
  payload: { fontSize: size }
});

export const getLoggedUser = () => async dispatch => {
  dispatch(getLoggedUserRequested());
  try {
    const { data: user } = await api.getLoggedUser();
    dispatch(getLoggedUserReceived(user));
    dispatch(subscribeNewsletterSucceed(user.newsLetterSubscribed));
  } catch (error) {
    dispatch(getLoggedUserFailed(error.response.data));
    dispatch(getAuthToken());
  } finally {
    dispatch(getLoggedUserFinished());
  }
};

const resizeFont = size => {
  if (isAValidFontSize(size)) {
    document.documentElement.style.fontSize = size;
  }
};

const isAValidFontSize = size =>
  !!availableFontSizes.find(item => item.size === size);

export const applyFontSizeFromCookie = () => dispatch => {
  const size = Cookies.get(FONT_SIZE_COOKIE_NAME);
  resizeFont(size);
  dispatch(changeFontSize(size));
};

export const saveFontSize = size => dispatch => {
  if (isAValidFontSize(size)) {
    Cookies.set(FONT_SIZE_COOKIE_NAME, size, { path: '/' });
    document.documentElement.style.fontSize = size;
    dispatch(changeFontSize(size));
  }
};
