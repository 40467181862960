import {
  FEATURED_COURSES_RECEIVED,
  FEATURED_COURSES_REQUEST_FAILED,
  FEATURED_COURSES_REQUEST_FINISHED,
  FEATURED_COURSES_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  featuredCourses: null,
  error: null
};

export const featuredCoursesRequested = state => ({ ...state, querying: true });

export const featuredCoursesReceived = (state, action) => ({
  ...state,
  featuredCourses: action.payload.featuredCourses.map(course => ({
    ...course,
    id: course.courseId
  })),
  coursesURL: action.payload.coursesURL,
  error: null
});

export const featuredCoursesRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  featuredCourses: null
});

const featuredCoursesRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [FEATURED_COURSES_REQUESTED]: featuredCoursesRequested,
  [FEATURED_COURSES_RECEIVED]: featuredCoursesReceived,
  [FEATURED_COURSES_REQUEST_FAILED]: featuredCoursesRequestFailed,
  [FEATURED_COURSES_REQUEST_FINISHED]: featuredCoursesRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
