import {
  ECPS_RECEIVED,
  ECPS_REQUEST_FAILED,
  ECPS_REQUEST_FINISHED,
  ECPS_REQUESTED,
  CREATE_ECPS_COOKIE_STATE,
  ECPS_PIN_ECP_SUCCEED,
  ECPS_PIN_ECP_FAILED
} from './types';
import api from '../../utils/api';
import Cookies from 'js-cookie';

export const getEcpsRequested = () => ({
  type: ECPS_REQUESTED,
  payload: null
});

export const getEcpsSucceed = ecps => ({
  type: ECPS_RECEIVED,
  payload: { ecps }
});

export const getEcpsFailed = er => ({
  type: ECPS_REQUEST_FAILED,
  payload: { error: er }
});

export const getEcpsFinished = () => ({
  type: ECPS_REQUEST_FINISHED,
  payload: null
});

export const setCookieState = cookieState => ({
  type: CREATE_ECPS_COOKIE_STATE,
  payload: { cookieState }
});

export const pinEcpSucceed = ecpId => ({
  type: ECPS_PIN_ECP_SUCCEED,
  payload: { ecpId }
});

export const pinEcpFailed = error => ({
  type: ECPS_PIN_ECP_FAILED,
  payload: { error }
});

export const getEcps = () => async dispatch => {
  dispatch(getEcpsRequested());
  try {
    const { data: ecps } = await api.getEcps();
    dispatch(getEcpsSucceed(ecps));
  } catch (error) {
    dispatch(getEcpsFailed(error.response.data));
  } finally {
    dispatch(getEcpsFinished());
  }
};

export const createCookieState = () => dispatch => {
  const cookieState = !!Cookies.get('closeEcpsNotification');
  dispatch(setCookieState(cookieState));
};

export const changeCookieState = () => dispatch => {
  Cookies.set('closeEcpsNotification', 'true', { path: '/' });
  dispatch(setCookieState(true));
};
