import React, { Component } from 'react';

import { Block } from 'lucentum';

import HeaderDesktop from './desktop';
import HeaderMobile from './mobile';

const KEY_ENTER = 'Enter';

export default class extends Component {
  state = {
    showMenu: false,
    activeSearch: false,
    inputText: null
  };

  searchTypes = [
    { text: 'All' },
    { text: 'Members' },
    { text: 'Tools' },
    { text: 'Ecps' },
    { text: 'Practices' },
    { text: 'News' },
    { text: 'Events' }
  ];

  search = () => {
    const searchTerm = this.state.inputText;
    const searchType = this.parseSearchType(this.props.activePage);

    window.location.href = `#/search/${searchType}/${
      searchTerm != null ? encodeURIComponent(searchTerm) : ''
    }`;
    this.props.getSearchResults(searchTerm, searchType);
  };

  onShow = () => this.setState({ showMenu: true });
  onHidden = () => this.setState({ showMenu: false });

  onEnterChange = e => e.key === KEY_ENTER && this.search();

  onChangeSearch = e => this.setState({ inputText: e.currentTarget.value });

  onClickSearch = e =>
    !this.state.activeSearch
      ? this.setState(({ activeSearch }) => ({ activeSearch: !activeSearch }))
      : this.search();

  parseSearchType = active => {
    const page = active.charAt(0).toUpperCase() + active.slice(1).toLowerCase();
    const type = this.searchTypes.find(item => page === item.text);
    return (type && type.text) || this.searchTypes[0].text;
  };

  render() {
    return (
      <Block>
        <HeaderDesktop
          activeSearch={this.state.activeSearch}
          onChangeSearch={this.onChangeSearch}
          onClickSearch={this.onClickSearch}
          onEnterChange={this.onEnterChange}
          {...this.props}
          {...this.state}
        />
        <HeaderMobile
          activeSearch={this.state.activeSearch}
          onChangeSearch={this.onChangeSearch}
          onClickSearch={this.onClickSearch}
          onHidden={this.onHidden}
          onShow={this.onShow}
          onEnterChange={this.onEnterChange}
          {...this.props}
          {...this.state}
        />
      </Block>
    );
  }
}
