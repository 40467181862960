import React, { Component } from 'react';
import { isEmail as isAValidEmail } from 'validator';
import { Block, Spinner } from 'lucentum';

import NewsletterWidget from './styles';
import UnsubscribeNewsletter from './unsubscribeNewsletter/';
import SubscribeNewsletter from './subscribeNewsletter/';

class Newsletter extends Component {
  state = {
    email: this.props.email || '',
    inputHasBeenFocused: false
  };
  isSubmitting = this.props.subscribedNewsletter.querying;

  saveEmail = email => this.setState(() => ({ email }));
  setAsFocused = () => this.setState(() => ({ inputHasBeenFocused: true }));

  shouldSubmitTheForm = () =>
    isAValidEmail(this.state.email) && !this.isSubmitting;

  handleSubmit = ev => {
    ev.preventDefault();
    this.setAsFocused();
    if (this.shouldSubmitTheForm()) {
      this.props.handleSubscribeNewsletter(this.state.email);
    }
  };

  shouldShowLastNewsletterWidget = () =>
    (this.props.subscribedNewsletter.subscribed ||
      this.props.showLastNewsletter) &&
    this.props.featuredNewsletter;

  render = () => (
    <NewsletterWidget>
      {this.props.querying ? (
        <Block center>
          <Spinner show row size={3} />
        </Block>
      ) : this.shouldShowLastNewsletterWidget() ? (
        <SubscribeNewsletter
          className={this.props.className}
          featuredNewsletter={this.props.featuredNewsletter}
        />
      ) : (
        <UnsubscribeNewsletter
          className={this.props.className}
          handleSubmit={this.handleSubmit}
          email={this.state.email}
          setAsFocused={this.setAsFocused}
          saveEmail={this.saveEmail}
          inputHasBeenFocused={this.state.inputHasBeenFocused}
          isSubmitting={this.isSubmitting}
        />
      )}
    </NewsletterWidget>
  );
}

export default Newsletter;
