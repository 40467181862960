import React from 'react';
import camel from 'lodash.camelcase';
import styled from 'styled-components';

import * as icons from './icons';

export const Icon = styled.i`
  font-family: 'Lucentum Icons 2', 'Helvetica', 'sans-serif';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: ${props =>
      icons[
        Object.entries(props)
          .filter(([, value]) => !!value)
          .map(([key]) => camel(key))
          .find(name => icons[name])
      ]};
  }
  &:not(:only-child) {
    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.sizeSmall};
    }
  }
  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.sizeSmall};
  }
`;

export const tooltip = id => (id ? { 'data-tip': true, 'data-for': id } : {});

export default ({ id, ...props }) => <Icon {...tooltip(id)} {...props} />;
