import { connect } from 'react-redux';

import Member from './component';
import { clearOffice } from '../../redux/office/action-creators';

export const mapStateToProps = state => ({
  office: state.office,
  offices: state.offices
});

export const mapDispatchToProps = dispatch => ({
  clearOffice: () => dispatch(clearOffice())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Member);
