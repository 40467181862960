import {
  NEWSLETTERS_RECEIVED,
  NEWSLETTERS_REQUEST_FAILED,
  NEWSLETTERS_REQUEST_FINISHED,
  NEWSLETTERS_REQUESTED
} from './types';

import api from '../../utils/api';

export const getNewslettersRequested = () => ({
  type: NEWSLETTERS_REQUESTED,
  payload: null
});

export const getNewslettersSucced = newsletters => ({
  type: NEWSLETTERS_RECEIVED,
  payload: { newsletters }
});

export const getNewslettersFailed = er => ({
  type: NEWSLETTERS_REQUEST_FAILED,
  payload: { error: er }
});

export const getNewslettersFinished = () => ({
  type: NEWSLETTERS_REQUEST_FINISHED,
  payload: null
});

export const getNewsletters = () => async dispatch => {
  dispatch(getNewslettersRequested());
  try {
    const { data: newsletters } = await api.getNewsletters();
    dispatch(getNewslettersSucced(newsletters));
  } catch (error) {
    dispatch(getNewslettersFailed(error.response.data));
  } finally {
    dispatch(getNewslettersFinished());
  }
};
