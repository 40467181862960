import React from 'react';
import styled from 'styled-components';

const th = prop => ({ theme }) => theme[prop];

const Title = styled.span`
  font-weight: 300;
  color: ${th('color')};
  font-size: ${th('fontSizeMedium')};
`;

const Box = styled.div`
  position: absolute;
  line-height: 1;
  z-index: 999;
  color: ${th('colorGrayLight')};
  font-size: ${th('fontSizeSmaller')};
  padding: ${th('sizeSmall')} ${th('sizeSmaller')};
  text-align: center;
  border-radius: ${th('radius')};
  background-color: ${th('colorWhite')};
  border: ${th('line')} solid ${th('colorGrayLight')};
  transform: translate(-50%, -550%);
  :before {
    content: '';
    position: absolute;
    top: calc(100% - 2px);
    left: 50%;
    transform: translateX(-50%);
    border-left: ${th('sizeSmall')} solid transparent;
    border-right: ${th('sizeSmall')} solid transparent;
    border-top: ${th('sizeSmall')} solid ${th('colorWhite')};
    margin-top: 0 - round(${th('sizeSmall')} / 2.5);
    z-index: 100;
    width: 0;
    height: 0;
  }
  :after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-left: ${th('sizeSmall')} solid transparent;
    border-right: ${th('sizeSmall')} solid transparent;
    border-top: ${th('sizeSmall')} solid ${th('colorGrayLight')};
    margin-top: 0 - round(${th('sizeSmall')} / 2.5);
    z-index: 99;
    width: 0;
    height: 0;
  }
`;

export const ScrollBar = styled.div`
  height: 350px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${th('color')};
    border-radius: 100px;
    height: 50%;
  }
  img {
    display: block;
    max-width: 100%;
  }
  a {
    text-decoration: none;
    margin-bottom: ${th('size')};
  }
  h2 {
    color: ${th('color')};
    font-weight: bolder;
  }
  :focus,
  :hover {
    text-decoration: none;
  }
`;

export const OuterWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 50%;
`;

export const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Tooltip = ({ name, text, number = 0, ...props }) => {
  return (
    <Box {...props}>
      {name === 'Luxembourg OPI' && <Title>Luxembourg OPI, Benelux BOIP</Title>}
      {name === 'Belgium IPObel' && <Title>Belgium IPObel, Benelux BOIP</Title>}
      {name !== 'Belgium IPObel' && name !== 'Luxembourg OPI' && (
        <Title>{name}</Title>
      )}
    </Box>
  );
};
