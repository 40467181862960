import React from 'react';
import { Notification,Content } from 'lucentum';
import { FormattedHTMLMessage } from 'react-intl';
import {MembersWidgetBlock} from "./styles";

class WarningMessage extends React.Component {
  state = { show: true };
  render = () => (
    <Content>
      {this.state.show && <Notification warning onClose={() => this.setState({show: false})}>
        <MembersWidgetBlock>
          <FormattedHTMLMessage id="portlet.members.timeline.warning" />
        </MembersWidgetBlock>
      </Notification>}
    </Content>
  );
}

export default WarningMessage;




