import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Block, Button, Spinner, H1 } from 'lucentum';

import ErrorMessage from '../error-message';
import AdvancedCard from '../advanced-card';
import { CoursesBlock, BlockLink } from './styles';
import { BlockTitle } from '../../commonStyles/homeTooltipStyles';
import {
  Course as CoursePropType,
  Reducer as ReducerPropType
} from '../../propTypes';

const Courses = ({ featuredCourses, coursesURL, querying, error }) => (
  <Block>
    <BlockTitle>
      <H1>
        <FormattedMessage id="portlet.section.courses" />
      </H1>
    </BlockTitle>
    {querying && (
      <Block center>
        <Spinner show row size={3} />
      </Block>
    )}
    {!querying && error && <ErrorMessage />}
    {!querying && featuredCourses && (
      <CoursesBlock marginTop={0.86} justify>
        {featuredCourses.map((featuredCourse, i) => (
          <Block width47of100 key={i} className="featured-course">
            <AdvancedCard {...featuredCourse} />
          </Block>
        ))}
        {featuredCourses.length % 2 === 0 && <Block width47of100 />}
        <BlockLink right width47of100>
          {coursesURL && (
            <Button
              className="all-courses-button"
              link
              onClick={() => window.open(coursesURL)}
            >
              <FormattedMessage id="portlet.action.courses.show_all" />
            </Button>
          )}
        </BlockLink>
      </CoursesBlock>
    )}
  </Block>
);

Courses.propTypes = {
  ...ReducerPropType({
    featuredCourses: PropTypes.arrayOf(CoursePropType)
  }).isRequired
};

export default Courses;
