import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  StyledBlock,
  StyledButton,
  StyledBlockInput,
  StyledForm
} from './styles';
import { Block, H3, Icon, Link } from 'lucentum';
import TextField from '../../textField';

export default ({
  className,
  handleSubmit,
  email,
  setAsFocused,
  saveEmail,
  inputHasBeenFocused,
  isSubmitting,
  ...props
}) => (
  <Block className={className} padding>
    <StyledForm onSubmit={handleSubmit} noValidate>
      <StyledBlock>
        <H3>
          <FormattedMessage id="portlet.newsletter.title" />
        </H3>
        <Icon send />
      </StyledBlock>
      <StyledBlock>
        <StyledBlockInput>
          <FormattedMessage id="portlet.newsletter.input.placeholder">
            {text => (
              <TextField
                className="newsletter__input"
                type="email"
                defaultValue={email}
                onBlur={setAsFocused}
                onChange={({ target: { value } }) => saveEmail(value)}
                placeholder={text}
                showClear
                showError={inputHasBeenFocused}
                required={inputHasBeenFocused}
                errorMessage={
                  <FormattedMessage id="portlet.newsletter.input.error" />
                }
              />
            )}
          </FormattedMessage>
        </StyledBlockInput>
        <StyledButton
          className="newsletter__submit-button"
          type="submit"
          width1Of3
          primary
          disabled={isSubmitting}
        >
          <FormattedMessage id="portlet.newsletter.signup" />
        </StyledButton>
      </StyledBlock>
      <Block alignContents="right">
        <Link style={{ color: 'white' }} to="#/newsletters">
          <FormattedMessage id="portlet.action.newsletter.show_all" />
        </Link>
      </Block>
    </StyledForm>
  </Block>
);
