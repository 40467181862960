import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { News, Block, Spinner, Link, H1 } from 'lucentum';

import ErrorMessage from '../error-message';
import { BlockTitle } from '../../commonStyles/homeTooltipStyles';
import {
  News as NewsPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { fromUnixToLocalString } from '../../utils/functions';

const sortByDate = news => news.sort((a, b) => b.detail - a.detail);

const shouldShowSpinner = querying => querying;

const shouldShowErrorMessage = (querying, error) =>
  !shouldShowSpinner(querying) && error;

const shouldShowNewsList = (querying, news) => !querying && news;

function buildNewsList(sortedNews) {
  return sortedNews.map(currentNews => ({
    ...currentNews,
    detail: fromUnixToLocalString(currentNews.detail),
    text: currentNews.shortDescription
  }));
}

const NewsWidget = ({ news, querying, error }) => {
  const sortedNews = querying || error || !news ? [] : sortByDate(news);
  return (
    <Block>
      <BlockTitle>
        <H1>
          <FormattedMessage id="portlet.section.news" />
        </H1>
      </BlockTitle>
      {shouldShowSpinner(querying) && (
        <Block center>
          <Spinner show row size={3} />
        </Block>
      )}
      {shouldShowErrorMessage(querying, error) && <ErrorMessage />}
      {shouldShowNewsList(querying, news) && (
        <Block marginTop={0.86}>
          <News src={buildNewsList(sortedNews)} />
          <Block right>
            <Link to="#/news">
              <FormattedMessage id="portlet.action.news.show_all" />
            </Link>
          </Block>
        </Block>
      )}
    </Block>
  );
};

NewsWidget.propTypes = {
  ...ReducerPropType({
    news: PropTypes.arrayOf(NewsPropType)
  }).isRequired
};

export default NewsWidget;
