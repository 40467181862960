import React, { Component } from 'react';
import { Block } from 'lucentum';

import ModalData from './modal';
import modalsCurtain from './modals.json';
import { H3, Ul, Li, Link } from './styles';
import { ModalVideo } from '../../commonStyles/videoModalStyles';

export const RenderSections = ({ sections, onLinkClick }) => (
  <div>
    {sections.map(({ title, links = [] }) => (
      <Block key={`section-${title}`} margin widthMD3of10>
        <H3>{title}</H3>
        <Ul>
          {links.map(currentModal => (
            <Li key={`section-${title}-${currentModal.title}`}>
              <Link onClick={() => onLinkClick(currentModal)}>
                {currentModal.title}
              </Link>
            </Li>
          ))}
        </Ul>
      </Block>
    ))}
  </div>
);

class Initiatives extends Component {
  state = { currentModal: null };

  onLinkClick = selectedInitiative =>
    selectedInitiative.longDescription &&
    this.setState({ currentModal: selectedInitiative });

  render = () => (
    <Block title="Initiatives Index" expanded>
      <Block style={{ display: 'flex', flexFlow: 'wrap' }} justify>
        <Block>
          <RenderSections
            sections={modalsCurtain}
            onLinkClick={this.onLinkClick}
          />
          <ModalVideo
            title={(this.state.currentModal || {}).title}
            isOpen={!!this.state.currentModal}
            onRequestClose={() => this.setState({ currentModal: null })}
          >
            <ModalData {...this.state.currentModal} />
          </ModalVideo>
        </Block>
      </Block>
    </Block>
  );
}

export default Initiatives;
