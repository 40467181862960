import React from 'react';
import PropTypes from 'prop-types';

import Page from '../../components/page';
import PracticeComponent from '../../components/practice';

import {
  Practice as PracticePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { FormattedMessage } from 'react-intl';
import { Content } from 'lucentum';

const Practice = ({ practice, languages, active, clearPractice }) => (
  <Page active={active} languages={languages}>
    <Content>
      <Breadcrumbs
        src={[
          { text: <FormattedMessage id="portlet.section.home" />, url: '#/' },
          {
            text: <FormattedMessage id="portlet.section.practices" />,
            url: '#/practices'
          },
          {
            text: '',
            url: '#/practices'
          }
        ]}
      />
    </Content>
    <PracticeComponent
      practice={practice.practice}
      querying={practice.querying}
      error={practice.error}
      clearPractice={clearPractice}
    />
  </Page>
);

Practice.propTypes = {
  practice: ReducerPropType({ practices: PropTypes.arrayOf(PracticePropType) })
    .isRequired,
  active: PropTypes.string
};

export default Practice;
