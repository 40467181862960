import React from 'react';
import Slider from "react-slick";
import {  Link } from 'lucentum';
import leftArrow from '../../assets/imgs/left-arrow.png'
import rightArrow from '../../assets/imgs/right-arrow.png'

import { BlockStyled, BlockOpacity } from './styles';

class HeroCarouselMobile extends React.Component {

  renderArrows = () => {
    return (
      <div className="slider-arrow">
        
        <img
          width="15px"
          src={leftArrow} 
          alt="left-arrow-slider" 
          className="arrow-btn prev"
          onClick={() => this.slider.slickPrev()}
        />
        <img
          width="15px"
          src={rightArrow} 
          alt="left-arrow-slider" 
          className="arrow-btn next"
          onClick={() => this.slider.slickNext()}
        />
      </div>
    );
  };
  
  render() {
    const { sliderData } = this.props;
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      className: 'slides'
    };
  
  return (
    <div style={{ position: "relative" }}>
      <Slider 
        ref={c => (this.slider = c)}
        {...settings}
      > 
          {
            sliderData.map((item, index) => {
              return(
                <BlockStyled key={index}  middle>
                  <img src={item.imageMobileUrl !== '' ? item.imageMobileUrl : item.imageUrl} alt={item.title} />
                  <BlockOpacity>
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                    <Link to={item.url}>Read More...</Link>
                  </BlockOpacity>
                </BlockStyled>
              )
            })
          }
      </Slider>
      {this.renderArrows()}
    </div>
    );
 }

}

export default HeroCarouselMobile;
