import styled from 'styled-components';

import { Block, Button, H2, Image } from 'lucentum';
import sizes from '../page/sizes';

export const calculatePaddingButtons = currentFontSize => {
  const paddingButtons = {
    'font.size.small': '7px 14px',
    'font.size.medium': '7px 14px',
    'font.size.large': '7px 14px',
    'font.size.extralarge': '7px 9px'
  };
  const size = sizes.find(({ size }) => size === currentFontSize);
  return size ? paddingButtons[size.value] : paddingButtons['font.size.medium'];
};

export const TopStyled = styled(Block)`
  background-color: ${({ theme }) => theme.color};
`;

export const TitleStyled = styled(H2)`
  color: ${({ theme }) => theme.colorWhite};
  display: block;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;
  > span {
    display: block;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto;
  }
`;

export const ImageStyled = styled(Image)`
  max-width: 100%;
`;

export const FlexBlock = styled(Block)`
  ${({ flex }) => flex && `flex: ${flex}`};
`;

export const MenuMobileStyled = styled(Block)`
  background-color: ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.colorWhite};
  overflow: auto;
  z-index: 1000;

  nav {
    flex-direction: column;
    text-align: right;

    a {
      color: white;
      font-size: ${({ theme }) => theme.fontSizeMedium};
      font-weight: ${({ theme }) => theme.fontWeight};
      margin: ${({ theme }) => theme.sizeSmaller} 0;
      text-decoration: none;
    }
  }
`;

export const CloseStyled = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizeDouble};
  padding: 0;
`;

export const BackgroundStyled = styled.div`
  background-color: #808080c9;
  bottom: 0;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

export const DesktopTopHeader = styled(Block)`
  max-width: 960px;
  margin: auto;
  float: none;
  button {
    min-width: max-content;
    line-height: normal;
    padding: ${({ currentFontSize }) =>
      calculatePaddingButtons(currentFontSize)}
  .user-menu__user-full-name button {
    max-width: 28rem;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const DesktopLogo = styled(Image).attrs(() => ({ big: true }))`
  width: 200px;
  height: auto;
`;

export const HeaderMobile = styled(Block)`
  position: fixed;
  z-index: 400;
`;

export const HeaderBlock = styled(Block)`
  display: flex;
  align-items: center;
`;
