import React from 'react';
import PropTypes from 'prop-types';

import Page from '../../components/page';
import MemberComponent from '../../components/member';

import {
  Office as OfficePropType,
  Language as LanguagePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { FormattedMessage } from 'react-intl';
import { Content } from 'lucentum';

const Member = ({ office, offices, active, clearOffice }) => (
  <Page active={active}>
    <Content>
      <Breadcrumbs
        src={[
          { text: <FormattedMessage id="portlet.section.home" />, url: '#/' },
          {
            text: <FormattedMessage id="portlet.section.members" />,
            url: '#/members'
          },
          {
            text: '',
            url: '#/members'
          }
        ]}
      />
    </Content>
    <MemberComponent
      office={office.office}
      querying={office.querying}
      error={office.error}
      offices={offices}
      clearOffice={clearOffice}
    />
  </Page>
);

Member.propTypes = {
  languages: PropTypes.arrayOf(LanguagePropType),
  offices: ReducerPropType({ offices: PropTypes.arrayOf(OfficePropType) })
    .isRequired,
  office: ReducerPropType({ office: OfficePropType }).isRequired
};

export default Member;
