import api from '../../utils/api';
import Cookies from 'js-cookie';
import { getHeroSucceed, getHeroFailed } from '../hero/action-creators';
import { getHeroCarouselSucceed, getHeroCarouselFailed } from '../heroCarousel/action-creators';
import {
  getFeaturedToolsSucceed,
  getFeaturedToolsFailed
} from '../featuredTools/action-creators';
import {
  getFeaturedPracticesSucceed,
  getFeaturedPracticesFailed
} from '../featuredPractices/action-creators';
import {
  getFeaturedEventsSucceed,
  getFeaturedEventsFailed
} from '../featuredEvents/action-creators';
import {
  getFeaturedNewsSucceed,
  getFeaturedNewsFailed
} from '../featuredNews/action-creators';
import {
  getFeaturedNewsletterSucceed,
  getFeaturedNewsletterFailed
} from '../featuredNewsletter/action-creators';

import {
  getFeaturedCoursesSucceed,
  getFeaturedCoursesFailed
} from '../featuredCourses/action-creators';

import {
  getMembersSucceed,
  getMembersFailed
} from '../members/action-creators';

import {
  HOME_DATA_REQUESTED,
  HOME_DATA_RECEIVED,
  HOME_DATA_REQUEST_FAILED,
  HOME_DATA_REQUEST_FINISHED,
  TOGGLE_NEWSLETTER_WIDGET
} from './types';

export const getHomeDataRequested = () => ({
  type: HOME_DATA_REQUESTED,
  payload: {}
});

export const getHomeDataSuceed = homeData => ({
  type: HOME_DATA_RECEIVED,
  payload: { homeData }
});

export const getHomeDataFailed = error => ({
  type: HOME_DATA_REQUEST_FAILED,
  payload: { error }
});

export const getHomeDataFinished = () => ({
  type: HOME_DATA_REQUEST_FINISHED,
  payload: {}
});

export const toggleNewsletterWidget = showLastNewsletter => ({
  type: TOGGLE_NEWSLETTER_WIDGET,
  payload: { showLastNewsletter }
});

export const toggleNewsletterWidgetType = () => async dispatch => {
  let shouldShowTheLastNewsletter = Cookies.get('showLastNewsLetter');

  let showLastNewsletter;

  if (shouldShowTheLastNewsletter === 'false') {
    showLastNewsletter = true;
  } else if (shouldShowTheLastNewsletter === 'true') {
    showLastNewsletter = false;
  }

  !showLastNewsletter
    ? Cookies.set('showLastNewsLetter', false)
    : Cookies.set('showLastNewsLetter', true);

  await dispatch(toggleNewsletterWidget(showLastNewsletter));
};

export const getHomeData = () => async dispatch => {
  dispatch(getHomeDataRequested());
  try {
    const { data: homeData } = await api.getHomeData();
    console.log('data', homeData)
    dispatch(toggleNewsletterWidgetType());
    dispatch(getHeroSucceed(homeData.hero));
    dispatch(getHeroCarouselSucceed(homeData.featuredBanners));
    dispatch(getFeaturedToolsSucceed(homeData.featuredTools));
    dispatch(getFeaturedPracticesSucceed(homeData.featuredPractices));
    dispatch(getFeaturedEventsSucceed(homeData.featuredEvents));
    dispatch(getFeaturedNewsSucceed(homeData.featuredNews));
    dispatch(getFeaturedNewsletterSucceed(homeData.featuredNewsletter));
    dispatch(
      getFeaturedCoursesSucceed(homeData.featuredCourses, homeData.coursesURL)
    );
/*     if(homeData.members && homeData.members.countries && Array.isArray(homeData.members.countries)) {
      const secondGrOffice = {
        active: false,
        belongs: ["EU", "EUR"],
        code: "GR",
        countryId: 25,
        image: "/static/img/cfgateway/publicwebsite/GR%20GGE.png",
        joined: 1551888189000,
        name: "Greece GGE",
        number: 2197,
        officeId: 111055,
        short: "OBI",
        updated: 1551888189000,
      }
      homeData.members.countries.push(secondGrOffice)
    } */
    dispatch(getMembersSucceed(homeData.members));
    dispatch(getHomeDataSuceed());
  } catch (error) {
    dispatch(getHomeDataFailed(error.response.data));
    dispatch(getHeroFailed(error.response.data));
    dispatch(getHeroCarouselFailed(error.response.data));
    dispatch(getFeaturedToolsFailed(error.response.data));
    dispatch(getFeaturedPracticesFailed(error.response.data));
    dispatch(getFeaturedEventsFailed(error.response.data));
    dispatch(getFeaturedNewsFailed(error.response.data));
    dispatch(getFeaturedNewsletterFailed(error.response.data));
    dispatch(getFeaturedCoursesFailed(error.response.data));
    dispatch(getMembersFailed(error.response.data));
  } finally {
    dispatch(getHomeDataFinished());
  }
};
