import styled from 'styled-components';
import { Block as BlockLucentum, H2 } from 'lucentum';

import { them } from '../../commonStyles';

export const ContentBlock = styled(BlockLucentum)`
  @media (${them('breakpointMaxMD')}) {
    padding-top: 0px;
  }
`;

export const NewsBlock = styled(BlockLucentum)`
  width: 100%;
  @media (${them('breakpointMaxMD')}) {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    margin-top: 15px;
  }
`;

export const StyledDate = styled(BlockLucentum)`
  color: ${them('colorGrayLight')};
  @media (${them('breakpointMaxMD')}) {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
`;

export const StyledTitle = styled(H2)`
  font-weight: bold;
  margin-bottom: 1.9375rem;
  @media (${them('breakpointMaxMD')}) {
    font-size: ${({ theme }) => theme.fontSizeMedium};
    margin: 0rem;
  }
`;

export const ImageBlock = styled(BlockLucentum)`
  width: 31.91489%;
  @media (${them('breakpointMaxMD')}) {
    width: 100%;
    padding: 0px;
    margin-bottom: 10px;
    display: flex;
    align-self: center;
  }

  > img {
    width: 100%;
  }
`;
export const NewContentBlock = styled(BlockLucentum)`
  @media (${them('breakpointMaxMD')}) {
    width: 100%;
  }
`;
