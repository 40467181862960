import PropTypes from 'prop-types';

export default PropTypes.shape({
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(PropTypes.number.isRequired),
      zoom: PropTypes.number.isRequired
    })
  ).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      number: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      short: PropTypes.string.isRequired,
      joined: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      updated: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      image: PropTypes.string.isRequired,
      belongs: PropTypes.arrayOf(PropTypes.string.isRequired)
    })
  ).isRequired
});
