import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Block, Spinner, Link, H3 } from 'lucentum';

import { Icon } from '..';
import { P, Span, DetailedNewsContainer } from './styles';
import { Strong } from '../../commonStyles';
import ErrorMessage from '../error-message';
import {
  Event as EventPropType,
  News as NewsPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { fromUnixToLocalString } from '../../utils/functions';

const icon = audio => (audio ? <Icon audio /> : <Icon video />);

export const formatDateRanges = (beginDateMili, endDateMili) => {
  const beginDate = moment(beginDateMili);
  const endDate = moment(endDateMili);
  return beginDate.year() === endDate.year() &&
    beginDate.month() === endDate.month()
    ? `${beginDate.date()}-${endDate.date()}/${endDate.month() +
        1}/${endDate.year()}`
    : `${fromUnixToLocalString(beginDate)} - ${fromUnixToLocalString(endDate)}`;
};

const DetailedEventDates = ({ beginDate, endDate = null }) => (
  <Span>
    {endDate && beginDate !== endDate
      ? `${formatDateRanges(beginDate, endDate)}`
      : fromUnixToLocalString(beginDate)}
  </Span>
);

const Title = ({ href, audio, video, title }) => (
  <H3>
    <Link to={href}>
      {(audio || video) && icon(audio)}
      <Strong>{title}</Strong>
    </Link>
  </H3>
);

const DetailedNewsItem = ({
  mobile,
  news,
  date,
  beginDate,
  endDate,
  href,
  audio,
  video,
  title,
  text,
  shortDescription
}) => (
  <Block column marginBottom>
    {mobile &&
      (news ? (
        <Span>{fromUnixToLocalString(date)}</Span>
      ) : (
        <DetailedEventDates beginDate={beginDate} endDate={endDate} />
      ))}
    {!mobile && (
      <Span>
        {news ? (
          fromUnixToLocalString(date)
        ) : (
          <DetailedEventDates beginDate={beginDate} endDate={endDate} />
        )}
      </Span>
    )}
    <Title href={href} audio={audio} video={video} title={title} />
    {!news && <Span>{text}</Span>}
    {!mobile && <P>{shortDescription}</P>}
  </Block>
);

const DetailedNews = ({
  src,
  className,
  querying,
  error,
  mobile,
  news = false
}) => (
  <Block className={className}>
    {querying && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {!querying && error && <ErrorMessage />}
    {!querying &&
      src &&
      (src.length === 0 ? (
        <P>No results.</P>
      ) : (
        <DetailedNewsContainer paddingLeft marginBottom>
          {src.map(
            (
              {
                audio,
                title,
                video,
                text,
                date,
                shortDescription,
                href,
                beginDate,
                endDate
              },
              key
            ) => (
              <DetailedNewsItem
                key={key}
                mobile={mobile}
                news={news}
                date={date}
                beginDate={beginDate}
                endDate={endDate}
                href={href}
                audio={audio}
                video={video}
                title={title}
                text={text}
                shortDescription={shortDescription}
              />
            )
          )}
        </DetailedNewsContainer>
      ))}
  </Block>
);

DetailedNews.propTypes = {
  ...ReducerPropType({
    src: PropTypes.arrayOf(PropTypes.oneOfType([EventPropType, NewsPropType]))
      .isRequired
  }).isRequired,
  news: PropTypes.bool,
  className: PropTypes.string
};

export default DetailedNews;
