import {
  PAGINATED_EVENTS_RECEIVED,
  PAGINATED_EVENTS_REQUEST_FAILED,
  PAGINATED_EVENTS_REQUEST_FINISHED,
  PAGINATED_EVENTS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  paginatedEvents: null,
  error: null,
  numEvents: null,
  currentPage: 1
};

export const paginatedEventsRequested = state => ({ ...state, querying: true });

export const paginatedEventsReceived = (state, action) => ({
  ...state,
  paginatedEvents: action.payload.events.map(eventsItem => ({
    ...eventsItem,
    detail: eventsItem.date,
    text: eventsItem.location,
    href: `#/events/${eventsItem.eventId}`
  })),
  numEvents: action.payload.numEvents,
  currentPage: action.payload.currentPage,
  error: null
});

export const paginatedEventsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  paginatedEvents: null,
  numEvents: null,
  currentPage: 1
});

const paginatedEventsRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [PAGINATED_EVENTS_REQUESTED]: paginatedEventsRequested,
  [PAGINATED_EVENTS_RECEIVED]: paginatedEventsReceived,
  [PAGINATED_EVENTS_REQUEST_FAILED]: paginatedEventsRequestFailed,
  [PAGINATED_EVENTS_REQUEST_FINISHED]: paginatedEventsRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
