import {
  FEATURED_PRACTICES_RECEIVED,
  FEATURED_PRACTICES_REQUEST_FAILED,
  FEATURED_PRACTICES_REQUEST_FINISHED,
  FEATURED_PRACTICES_REQUESTED,
  FEATURED_PRACTICES_PIN_PRACTICE
} from './types';

export const parseIds = practices =>
  practices.map(practice => ({ ...practice, id: practice.practiceId }));

const INITIAL_STATE = {
  querying: false,
  featuredPractices: null,
  error: null
};
export const featuredPracticesRequested = state => ({
  ...state,
  querying: true
});

export const featuredPracticesReceived = (state, action) => ({
  ...state,
  featuredPractices: parseIds(action.payload.featuredPractices),
  error: null
});

export const featuredPracticesRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  featuredPractices: null
});
export const featuredPracticesRequestFinished = state => ({
  ...state,
  querying: false
});

export const pinPractice = (state, { payload: { practiceId } }) => ({
  ...state,
  featuredPractices: Array.isArray(state.featuredPractices)
    ? state.featuredPractices.map(practice => ({
        ...practice,
        favorite: !!(practice.practiceId === practiceId)
          ? !practice.favorite
          : practice.favorite
      }))
    : state.featuredPractices
});

const reducersMap = {
  [FEATURED_PRACTICES_REQUESTED]: featuredPracticesRequested,
  [FEATURED_PRACTICES_RECEIVED]: featuredPracticesReceived,
  [FEATURED_PRACTICES_REQUEST_FAILED]: featuredPracticesRequestFailed,
  [FEATURED_PRACTICES_REQUEST_FINISHED]: featuredPracticesRequestFinished,
  [FEATURED_PRACTICES_PIN_PRACTICE]: pinPractice
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
