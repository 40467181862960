import React from 'react';

import { Icon } from 'lucentum';
import { Button } from './styles';
import { FormattedMessage } from 'react-intl';

export default ({ onResize, fontSizes }) => (
  <Button
    onDropdown={e => onResize && onResize(e)}
    primary
    title={<Icon font-resize />}
    type="dropdown"
    className="font-size-chooser"
  >
    {/*{(fontSizes || []).map(({ value, size }) => (*/}
      {/*<Button title={value} style = {{fontSize:size}}/>*/}
    {/*))}*/}
    {(fontSizes || []).map(({ value, size }) => (
      <Button key={size}  size={size} style = {{fontSize:size}}><FormattedMessage id={value}/></Button>
    ))}

  </Button>
);
