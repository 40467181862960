import axios from 'axios';
import config from '../config';

class Labels {
  constructor() {
    this.http = axios;
  }

  getLabels = (languageCode = 'en_GB') =>
    this.http.get(`${config.NETWORK_ENDPOINT}delegate/publicwebsitelabels`, {
      params: { languageId: languageCode }
    });
}

export default new Labels();
