import React from 'react';

import { TextAreaStyled, ErrorMessage } from './styles';

export default ({ errorMessage, showError, ...props }) => (
  <TextAreaStyled>
    <textarea {...props} />
    {errorMessage && showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </TextAreaStyled>
);
