import moment from '../../libs/moment';
import { updateIntl } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';
import { TrackingService } from '../../utils/MatomoService'
import {
  GET_MATOMO_PROPERTIES_FAILED,
  GET_MATOMO_PROPERTIES_FINISHED,
  GET_MATOMO_PROPERTIES_REQUESTED,
  GET_MATOMO_PROPERTIES_RECEIVED
} from './types';

import API from '../../utils/api';

const getMatomoPropertiesRequested = () => ({
  type: GET_MATOMO_PROPERTIES_REQUESTED,
  payload: null
});

const getMatomoPropertiesFailed = error => ({
  type: GET_MATOMO_PROPERTIES_FAILED,
  payload: { error }
});

/* const getMatomoPropertiesFinished = () => ({
  type: GET_MATOMO_PROPERTIES_FINISHED,
  payload: null
});
 */
const matomoPropertiesReceived = matomoObject => ({
    type: GET_MATOMO_PROPERTIES_RECEIVED,
    payload: {matomoObject}
})

export const getMatomoProperties = () => async dispatch => {
    dispatch(getMatomoPropertiesRequested());
    try {

      const { data: matomoObject } = await API.getMatomoProperties();
      /* TrackingService.init(matomoObject) */
      //API.setGlobalParameter({ matomoProperties: matomoObject });
      dispatch(matomoPropertiesReceived(matomoObject.trim()));
    } catch (error) {
       dispatch(getMatomoPropertiesFailed(error.response.data));

      // PREPROD
      //TrackingService.init({
      //  cookieDomain: "*.tmdn.org",
      //  disabled: false,
      //  siteId: 29,
      //  trackerURL: "//nonprod-stats.test.oami.eu/"})

      // TEST + INTADP
      //TrackingService.init({
      //  cookieDomain: "*.tmdn.org",
      //  disabled: false,
      //  siteId: 11,
      //  trackerURL: "//nonprod-stats.test.oami.eu/"})

      // PROD
      TrackingService.init({
        cookieDomain: "*.tmdn.org",
        disabled: false,
        siteId: 12,
        trackerURL: "//stats.euipo.europa.eu/"})


      /* API.setGlobalParameter({ matomoProperties: {
          cookieDomain: "*.tmdn.org",
          disabled: false,
          siteId: 11,
          trackerURL: "//nonprod-stats.test.oami.eu/"}
      }); */
/*         TrackingService.init({
            cookieDomain: "*.f5extdmztestf01.tmdn.org",
            disabled: false,
            siteId: 43,
            trackerURL: "//nonprod-stats.test.oami.eu/"}  */
 /*        TrackingService.init({
            cookieDomain: "*",
            disabled: false,
            siteId: 1,
            trackerURL: "https://local.matomo.cloud/"}
        ) */
       /*  TrackingService.init({
          cookieDomain: "*.tmdn.org",
            disabled: false,
            siteId: 11,
            trackerURL: "//nonprod-stats.test.oami.eu/"}
        ) */
    }
  /*   finally {
       dispatch(getMatomoPropertiesFinished());
      API.setGlobalParameter({ matomoProperties: 'hola'})

    } */
  };
