import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Search from './component';
import { getSearchResults } from '../../redux/search/action-creators';
export const mapStateToProps = state => ({
  searchResults: state.searchResults,
  pages: Math.ceil(
    state.searchResults.numResults / Search.SEARCH_RESULTS_PER_PAGE
  ),
  currentPage: state.searchResults.currentPage
});

export const mapDispatchToProps = dispatch => ({
  changeToPage: (searchTerm, searchType, page) =>
    dispatch(getSearchResults(searchTerm, searchType, page)),
  getSearchResults: (searchTerm, searchType) =>
    dispatch(getSearchResults(searchTerm, searchType))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
);
