import styled from 'styled-components';
import { Block } from 'lucentum';

export const HtmlBlock = styled(Block)`
  line-height: calc(${({ theme }) => theme.size} * 1.7);

  @media (${({ theme }) => theme.breakpointMaxMD}) {
    padding: 0;
  }

  table {
    border: 1px solid;

    td {
      border: 1px solid black;
      border-collapse: collapse;
      padding: calc(${({ theme }) => theme.fontSize} / 2);
    }
  }
`;
