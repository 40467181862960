import React from 'react';
import { Button, Block } from 'lucentum';
import { FormattedMessage } from 'react-intl';
import { BlockStyled } from './styles';
import Menu from './menu';
import { clampText } from '../../utils/functions';

const titleUserMenu = ({ user: { firstName, middleName, lastName } }) =>
  clampText(`${firstName} ${middleName} ${lastName}`, 11);

export default ({
  src,
  signInTestId,
  signUpTestId,
  onSignIn,
  onSignUp,
  textSignIn,
  textSignUp,
  ...props
}) =>
  src ? (
    <BlockStyled {...props}>
      {src.adminMenu && (
        <Menu
          src={src.adminMenu}
          title={<FormattedMessage id="portlet.userMenu.admin" />}
        />
      )}
      {src.mySitesMenu && (
        <Menu
          src={src.mySitesMenu}
          title={<FormattedMessage id="portlet.userMenu.mySites" />}
        />
      )}
      {src.userMenu && (
        <Block className="tooltip-user">
          <span className="tooltip-user__message">
            {src.user.firstName} {src.user.middleName} {src.user.lastName}
          </span>
          <Menu
            className="user-menu__user-full-name"
            src={src.userMenu}
            title={titleUserMenu(src)}
          />
        </Block>
      )}
    </BlockStyled>
  ) : (
    <BlockStyled {...props}>
      <Button
        data-test-id={signInTestId}
        primary
        onClick={onSignIn}
        className="login__login-button"
      >
        {textSignIn}
      </Button>
      <Button
        data-test-id={signUpTestId}
        primary
        onClick={onSignUp}
        className="login__register-button"
      >
        {textSignUp}
      </Button>
    </BlockStyled>
  );
