import {
  FEATURED_TOOLS_RECEIVED,
  FEATURED_TOOLS_REQUEST_FAILED,
  FEATURED_TOOLS_REQUEST_FINISHED,
  FEATURED_TOOLS_REQUESTED,
  FEATURED_TOOLS_PIN_TOOL_SUCCEED,
  FEATURED_TOOLS_PIN_TOOL_FAILED
} from './types';
import api from '../../utils/api';

export const getFeaturedToolsRequested = () => ({
  type: FEATURED_TOOLS_REQUESTED,
  payload: null
});

export const getFeaturedToolsSucceed = featuredTools => ({
  type: FEATURED_TOOLS_RECEIVED,
  payload: { featuredTools }
});

export const getFeaturedToolsFailed = er => ({
  type: FEATURED_TOOLS_REQUEST_FAILED,
  payload: { error: er }
});

export const getFeaturedToolsFinished = () => ({
  type: FEATURED_TOOLS_REQUEST_FINISHED,
  payload: null
});

export const getFeaturedTools = () => async dispatch => {
  dispatch(getFeaturedToolsRequested());
  try {
    const { data: featuredTools } = await api.getFeaturedTools();
    dispatch(getFeaturedToolsSucceed(featuredTools));
  } catch (error) {
    dispatch(getFeaturedToolsFailed(error.response.data));
  } finally {
    dispatch(getFeaturedToolsFinished());
  }
};

export const pinToolSucceed = toolId => ({
  type: FEATURED_TOOLS_PIN_TOOL_SUCCEED,
  payload: { toolId }
});

export const pinToolFailed = error => ({
  type: FEATURED_TOOLS_PIN_TOOL_FAILED,
  payload: { error }
});
