import { connect } from 'react-redux';

import Tools from './component';
import { pinTool } from '../../redux/tool/action-creators';
import {
  getTools,
  createCookieState as createToolsCookieState,
  changeCookieState
} from '../../redux/tools/action-creators';

export const mapStateToProps = state => ({
  tools: state.tools,
  cookieState: state.tools.cookieState,
  isUserLoged: !!state.user.object
});

export const mapDispatchToProps = dispatch => ({
  changeCookie: () => dispatch(changeCookieState()),
  pinTool: id => dispatch(pinTool(id)),
  getTools: () => dispatch(getTools()),
  createCookie: () => dispatch(createToolsCookieState())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tools);
