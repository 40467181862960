import styled from 'styled-components';
import { Block } from 'lucentum';

import { them } from '../../commonStyles';

export const Background = styled(Block)`
  background-color: ${them('color')};
  background-image: ${({ imgMobile }) => `url(${imgMobile})`};
  background-position: 325% 75%;
  background-repeat: no-repeat;
  background-size: 85%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  @media (${them('breakpointLG')}) {
    background-color: ${them('colorGrayAccent')};
    background-image: ${({ img }) => `url(${img})`};
    background-position: 100% 70%;
    background-size: 20%;
  }
`;

export const BlockStyled = styled(Block)`
  background-color: ${them('colorGrayAccent')};
  height: 230px;
  position: relative;
  text-align: center;
  img {
    width: calc(100% - 40px);
    object-fit: cover;
    height: inherit;
    margin-left: 40px;
  }
`;

export const BlockOpacity = styled(Block)`
  background-color: rgba(210, 210, 210, 1);
  width: 220px;
  height: 190px;
  position: absolute;
  display: flex;
  color: white;
  width: 240px;
  height: 200px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  top: 20px;
  color: black;
  h3 {
    font-size: 1.2rem;
    margin: 0 0 7px 0;
    text-align: left;
    line-height: 1em;
    font-size: 1em;
  }
  a:link, a:active {
    position: absolute;
    right: 15px;
    bottom: 15px;
    border: 1px solid rgb(47, 80, 154);
    padding: 4px 10px;
    font-size: 12px;
    text-decoration: none;
    &:hover {
      color: white;
      background-color: rgb(159, 177, 193);
      outline: none;
    }
  }

  p {
    text-align: left;
    font-size: 12px;

  }
 
`;
