import {
  NEWSLETTERS_RECEIVED,
  NEWSLETTERS_REQUEST_FAILED,
  NEWSLETTERS_REQUEST_FINISHED,
  NEWSLETTERS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  newsletters: null,
  error: null
};

export const newslettersRequested = state => ({ ...state, querying: true });

export const newslettersReceived = (state, action) => ({
  ...state,
  newsletters: action.payload.newsletters,
  error: null
});

export const newslettersRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  newsletters: null
});

export const newslettersRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [NEWSLETTERS_REQUESTED]: newslettersRequested,
  [NEWSLETTERS_RECEIVED]: newslettersReceived,
  [NEWSLETTERS_REQUEST_FAILED]: newslettersRequestFailed,
  [NEWSLETTERS_REQUEST_FINISHED]: newslettersRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
