import React from 'react';
import PropTypes from 'prop-types';
import Page from '../../components/page';
import { Ecp as EcpComponent } from '../../components/';
import {
  Ecp as EcpProptype,
  Reducer as ReducerPropType
} from '../../propTypes';
import Breadcrumbs from '../../components/breadcrumbs/component';
import { FormattedMessage } from 'react-intl';
import { Content } from 'lucentum';

const Ecp = ({ ecp, active, clearEcp }) => (
  <Page active={active}>
    <Content>
      <Breadcrumbs
        src={[
          { text: <FormattedMessage id="portlet.section.home" />, url: '#/' },
          {
            text: <FormattedMessage id="portlet.section.ecps" />,
            url: '#/ecps'
          },
          {
            text: '',
            url: '#/ecps'
          }
        ]}
      />
    </Content>
    <EcpComponent
      ecp={ecp.ecp}
      querying={ecp.querying}
      error={ecp.error}
      clearEcp={clearEcp}
    />
  </Page>
);

Ecp.propTypes = {
  ecp: ReducerPropType({
    ecp: EcpProptype
  }).isRequired,
  active: PropTypes.string
};

export default Ecp;
