import PropTypes from 'prop-types';

export default PropTypes.shape({
  markAsFocused: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  field: PropTypes.node.isRequired,
  type: PropTypes.string,
  src: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  handleSelect: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired
});
