import React from 'react';
import { Select } from 'lucentum';
import { SelectContainer } from './styles';

export default ({
  src = [],
  errorMessage,
  onChange,
  value,
  defaultValue,
  ...props
}) => (
  <SelectContainer hasError={!!errorMessage}>
    <Select
      src={src}
      onChange={onChange}
      value={value || defaultValue}
      errorMessage={errorMessage}
      backspaceRemoves={false}
      onBlurResetsInput={true}
      deleteRemoves={false}
      {...props}
    />
  </SelectContainer>
);
