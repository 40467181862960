import styled from 'styled-components';
import { Block } from 'lucentum';

import { them } from '../../commonStyles';

export const Background = styled(Block)`
  background-color: ${them('color')};
  background-image: ${({ imgMobile }) => `url(${imgMobile})`};
  background-position: 325% 75%;
  background-repeat: no-repeat;
  background-size: 85%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  @media (${them('breakpointLG')}) {
    background-color: ${them('colorGrayAccent')};
    background-image: ${({ img }) => `url(${img})`};
    background-position: 100% 70%;
    background-size: 20%;
  }
`;

export const BlockStyled = styled(Block)`
  background-color: ${them('colorGrayAccent')};
  height: calc(${them('size')} * 14);
  position: relative;
  text-align: center;

  & > div:last-child {
    z-index: 105;
  }

  @media (${them('breakpointLG')}) {
    height: calc(${them('size')} * 9);
  }
`;
