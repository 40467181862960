import {
  HERO_CAROUSEL_REQUEST_SUCCEED,
  HERO_CAROUSEL_REQUEST_FAILED,
  HERO_CAROUSEL_REQUEST_FINISHED,
  HERO_CAROUSEL_REQUEST_STARTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  heroCarousel: null,
  error: null
};

export const getHeroCarouselRequested = state => ({
  ...state,
  querying: true
});

export const getHeroCarouselReceived = (state, action) => ({
  ...state,
  heroCarousel: action.payload.heroCarousel,
  error: null
});

export const getHeroCarouselRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  getHeroCarousel: null
});

const getHeroCarouselRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [HERO_CAROUSEL_REQUEST_STARTED]: getHeroCarouselRequested,
  [HERO_CAROUSEL_REQUEST_SUCCEED]: getHeroCarouselReceived,
  [HERO_CAROUSEL_REQUEST_FAILED]: getHeroCarouselRequestFailed,
  [HERO_CAROUSEL_REQUEST_FINISHED]: getHeroCarouselRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
