import {
  FEATURED_COURSES_RECEIVED,
  FEATURED_COURSES_REQUEST_FAILED,
  FEATURED_COURSES_REQUEST_FINISHED,
  FEATURED_COURSES_REQUESTED
} from './types';
import api from '../../utils/api';

export const getFeaturedCoursesRequested = () => ({
  type: FEATURED_COURSES_REQUESTED,
  payload: null
});

export const getFeaturedCoursesSucceed = (
  featuredCourses,
  coursesURL = ''
) => ({
  type: FEATURED_COURSES_RECEIVED,
  payload: { featuredCourses, coursesURL }
});

export const getFeaturedCoursesFailed = er => ({
  type: FEATURED_COURSES_REQUEST_FAILED,
  payload: { error: er }
});

export const getFeaturedCoursesFinished = () => ({
  type: FEATURED_COURSES_REQUEST_FINISHED,
  payload: null
});

export const getFeaturedCourses = () => async dispatch => {
  dispatch(getFeaturedCoursesRequested());
  try {
    const { data: featuredCourses } = await api.getFeaturedCourses();
    dispatch(getFeaturedCoursesSucceed(featuredCourses));
  } catch (error) {
    dispatch(getFeaturedCoursesFailed(error.response.data));
  } finally {
    dispatch(getFeaturedCoursesFinished());
  }
};
