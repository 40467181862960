import React , { Component }  from 'react';
import PropTypes from 'prop-types';
import { P, Content, Block, Spinner, Fieldset, TextField,Notification } from 'lucentum';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { OfficeLink } from '../member/officesIndex/component';
import ErrorMessage from '../error-message';

import WarningMessage from './warning-message';

import {
  Office as OfficePropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { StyledTitle } from '../../commonStyles';

import {
  MembersList,
  BlockResponsive,
  CategoryBlock,
  MembersWidget
} from './styles';

import {ERROR_MESSAGE} from "../error-message/component";
import {ErrorTextField} from "../textField/styles";

export const buildOfficesCategoryTree = offices =>
  offices
    .sort((a, b) => a.title.localeCompare(b.title))
    .reduce(
      (prev, office) =>
        !prev[office.category]
          ? { ...prev, [office.category]: [office] }
          : { ...prev, [office.category]: [...prev[office.category], office] },
      {}
    );

export const getCategoryTitle = name =>
  name === 'EUIPOs' ? (
    <FormattedMessage id="portlet.members.euipos" > 
    {(txt) => (
      <span style={{'word-break': 'break-word'}}>
        {txt}
      </span>
    )}
  </FormattedMessage>
      
  ) : name === 'UserAssociation' ? (
    <FormattedMessage id="portlet.members.userassociation" > 
    {(txt) => (
      <span style={{'word-break': 'break-word'}}>
        {txt}
      </span>
    )}
  </FormattedMessage>
  ) : name === 'OtherMembers' ?(
    <FormattedMessage id="portlet.members.othermembers" > 
    {(txt) => (
      <span style={{'word-break': 'break-word'}}>
        {txt}
      </span>
    )}
  </FormattedMessage>
  ) : (
    //<FormattedMessage id="portlet.members.noneuipos" />
    {/*<FormattedMessage id="Non-European IPOs" />*/}
  );

const shouldShowErrorMessage = (querying, error) => !querying && error;

const shouldShowOfficeList = (querying, offices) => !querying && offices;

export const CategoryTree = ({ officeName, categorizedOffices }) => (
  <div>
    <CategoryBlock
      className={officeName}
      paddingRight={2}
      title={getCategoryTitle(officeName)}
      width1of3
      hiddenXS
      visibleLG
    >
      {(categorizedOffices[officeName] || []).map((office, j) => (
        <Block key={j}>
          <OfficeLink {...office} />
        </Block>

        ))}
    </CategoryBlock>
    <MembersList
      visibleXS
      hiddenLG
      className={officeName}
      title={getCategoryTitle(officeName)}
    >
      {(categorizedOffices[officeName] || []).map((office, j) => (
        <Block key={j}>
          <OfficeLink {...office} />
        </Block>
      ))}
    </MembersList>
  </div>
);

const Members = ({ offices, querying, error }) => {

  const categorizedOffices =
    querying || error || !offices ? [] : buildOfficesCategoryTree(offices);

  return (
    <Content>
      {querying && (
        <Block center>
          <Spinner show row size={10} />
        </Block>
      )}
      {shouldShowErrorMessage(querying, error) && <ErrorMessage />}
      {shouldShowOfficeList(querying, offices) && (
        <MembersWidget>
          <StyledTitle>
            <FormattedMessage id="portlet.members.title" />
          </StyledTitle>

          {/*<WarningMessage />*/}

          <BlockResponsive marginTop={2}>
            <P>
              <FormattedHTMLMessage id="porlet.members.body" />
            </P>
          </BlockResponsive>
          <BlockResponsive className="block-members">
            {['EUIPOs', 'UserAssociation', 'OtherMembers'].map(officeName => (
              <CategoryTree
                key={officeName}
                officeName={officeName}
                categorizedOffices={categorizedOffices}
              />
            ))}
          </BlockResponsive>
        </MembersWidget>
      )}
    </Content>
  );
};

Members.propTypes = ReducerPropType({
  offices: PropTypes.arrayOf(OfficePropType)
}).isRequired;

export default Members;
