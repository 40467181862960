import {
  EVENTS_FILTERS_RECEIVED,
  EVENTS_FILTERS_REQUEST_FAILED,
  EVENTS_FILTERS_REQUEST_FINISHED,
  EVENTS_FILTERS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  filters: null,
  error: null
};

export const eventsFiltersRequested = state => ({ ...state, querying: true });

export const eventsFiltersReceived = (state, action) => ({
  ...state,
  filters: action.payload.filters,
  error: null
});

export const eventsFiltersRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  filters: null
});

export const eventsFiltersRequestFinished = state => ({
  ...state,
  querying: false
});

const reducersMap = {
  [EVENTS_FILTERS_REQUESTED]: eventsFiltersRequested,
  [EVENTS_FILTERS_RECEIVED]: eventsFiltersReceived,
  [EVENTS_FILTERS_REQUEST_FAILED]: eventsFiltersRequestFailed,
  [EVENTS_FILTERS_REQUEST_FINISHED]: eventsFiltersRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
