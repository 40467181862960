import moment from 'moment';
import {
  ECP_RECEIVED,
  ECP_REQUEST_FAILED,
  ECP_REQUEST_FINISHED,
  ECP_REQUESTED,
  PIN_ECP_REQUESTED,
  PIN_ECP_RECEIVED,
  PIN_ECP_FAILED,
  PIN_ECP_FINISHED
} from './types';

import api from '../../utils/api';
import {
  pinEcpSucceed as pinEcpAtEcpsReducer,
  pinEcpFailed as pinEcpFailedAtEcpsReducer
} from '../ecps/action-creators';
import {
  pinEcpSucceed as pinEcpAtFeaturedEcpsReducer,
  pinEcpFailed as pinEcpFailedAtFeaturedEcpsReducer
} from '../featuredEcps/action-creators';
import { buildMilestone } from '../shared';

const buildPeriod = year => ({ type: 'Period', label: year });

const buildYears = ecp =>
  ecp.implementations.reduce((prev, item) => {
    const year = moment(item.date).year();
    const period = buildPeriod(year);
    if (!prev.length) {
      prev = [period];
    } else if (prev[prev.length - 1].label > year) {
      prev = [...prev, period];
    }
    return prev;
  }, []);

export const parseEcpImplementations = ecp => {
  ecp.implementations = ecp.implementations
    .map((item, index) => ({ item, index }))
    .sort((a, b) =>
      b.item.date - a.item.date === 0
        ? a.index - b.index
        : b.item.date - a.item.date
    )
    .map(({ item }) => item);
  return buildYears(ecp).reduce((prev, item) => {
    const year = item.label;
    const implementations = ecp.implementations.filter(
      ({ date }) => moment(date).year() === year
    );
    const milestones = implementations.map(item => buildMilestone(item));
    return [...prev, ...milestones, item];
  }, []);
};

export const getEcpRequested = () => ({
  type: ECP_REQUESTED,
  payload: null
});

export const getEcpSucceed = ecp => ({
  type: ECP_RECEIVED,
  payload: { ecp }
});

export const getEcpFailed = er => ({
  type: ECP_REQUEST_FAILED,
  payload: { error: er }
});

export const getEcpFinished = () => ({
  type: ECP_REQUEST_FINISHED,
  payload: null
});

export const pinEcpRequested = () => ({
  type: PIN_ECP_REQUESTED,
  payload: null
});
export const pinEcpSucceed = () => ({
  type: PIN_ECP_RECEIVED,
  payload: null
});
export const pinEcpFailed = er => ({
  type: PIN_ECP_FAILED,
  payload: { error: er }
});
export const pinEcpFinished = () => ({
  type: PIN_ECP_FINISHED,
  payload: null
});

export const getEcp = ecpId => async dispatch => {
  dispatch(getEcpRequested());
  try {
    const { data: ecp } = await api.getEcp(ecpId);
    ecp.timeline = Array.isArray(ecp.implementations)
      ? parseEcpImplementations(ecp)
      : [];
    dispatch(getEcpSucceed(ecp));
  } catch (error) {
    dispatch(getEcpFailed(error.response.data));
  } finally {
    dispatch(getEcpFinished());
  }
};

export const pinEcp = ecpId => async dispatch => {
  dispatch(pinEcpRequested());
  try {
    await api.pinEcp(ecpId);
    dispatch(pinEcpSucceed());
    dispatch(pinEcpAtEcpsReducer(ecpId));
    dispatch(pinEcpAtFeaturedEcpsReducer(ecpId));
  } catch (error) {
    dispatch(pinEcpFailed(error.response.data));
    dispatch(pinEcpFailedAtEcpsReducer(error.response.data));
    dispatch(pinEcpFailedAtFeaturedEcpsReducer(error.response.data));
  } finally {
    dispatch(pinEcpFinished());
  }
};
