import moment from '../../libs/moment';
import { updateIntl } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';

import {
  GET_LABELS_FAILED,
  GET_LABELS_FINISHED,
  GET_LABELS_REQUESTED
} from './types';

import labelsApi from '../../utils/labels';

    const getLabelsRequested = () => ({
  type: GET_LABELS_REQUESTED,
  payload: null
});

const getLabelsFailed = error => ({
  type: GET_LABELS_FAILED,
  payload: { error }
});

const getLabelsFinished = () => ({
  type: GET_LABELS_FINISHED,
  payload: null
});

export const getLabels = () => async (dispatch, getState) => {
  dispatch(getLabelsRequested());

  const activeLanguage = getState().languages.activeLanguageCode.substr(0, 2);
  import(`react-intl/locale-data/${activeLanguage}`).then(async locale => {
    try {
      addLocaleData(locale.default);
      //moment.locale(activeLanguage);
      const { data: properties } = await labelsApi.getLabels(activeLanguage);
      const languageLabels = properties.split('\n').reduce(
        (prev, currentLine) => ({
          ...prev,
          [currentLine.split('=', 2)[0]]: currentLine.split('=', 2)[1]
        }),
        {}
      );
      const sortedLanguageLabels = Object.keys(languageLabels)
        .sort()
        .reduce(
          (prev, currentLabel) => ({
            ...prev,
            [currentLabel]: languageLabels[currentLabel]
          }),
          {}
        );
      dispatch(
        updateIntl({
          locale: activeLanguage,
          messages: sortedLanguageLabels
        })
      );
    } catch (error) {
      dispatch(getLabelsFailed(error));
    } finally {
      dispatch(getLabelsFinished());
    }
  });
};
