import {
  FEATURED_EVENTS_REQUEST_SUCCEED,
  FEATURED_EVENTS_REQUEST_FAILED,
  FEATURED_EVENTS_REQUEST_FINISHED,
  FEATURED_EVENTS_REQUEST_STARTED
} from './types';
import api from '../../utils/api';

export const getFeaturedEventsRequested = () => ({
  type: FEATURED_EVENTS_REQUEST_STARTED,
  payload: null
});

export const getFeaturedEventsSucceed = featuredEvents => ({
  type: FEATURED_EVENTS_REQUEST_SUCCEED,
  payload: { featuredEvents }
});

export const getFeaturedEventsFailed = er => ({
  type: FEATURED_EVENTS_REQUEST_FAILED,
  payload: { error: er }
});

export const getFeaturedEventsFinished = () => ({
  type: FEATURED_EVENTS_REQUEST_FINISHED,
  payload: null
});

export const getFeaturedEvents = () => async dispatch => {
  dispatch(getFeaturedEventsRequested());
  try {
    const { data: featuredEvents } = await api.getFeaturedEvents();
    dispatch(getFeaturedEventsSucceed(featuredEvents));
  } catch (error) {
    dispatch(getFeaturedEventsFailed(error.response.data));
  } finally {
    dispatch(getFeaturedEventsFinished());
  }
};
