import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Block, Button, Form } from 'lucentum';
import TextField from '../textField';
import {
  StyledH2,
  StyledIcon,
  BlockStyled,
  BlockInputStyled,
  BlockIconStyled,
  MainBlockStyled
} from './styles';
import newsletterHoc from '../newsletter/hoc/component';

export const Newsletter = ({
  className,
  handleSubmit,
  email,
  inputHasBeenFocused,
  setAsFocused,
  saveEmail
}) => (
  <Block className={className} padding={2} paddingTop>
    <Form onSubmit={handleSubmit}>
      <MainBlockStyled>
        <BlockStyled>
          <Block paddingBottom={0.5}>
            <StyledH2>
              <FormattedMessage id="portlet.newsletter.title" />
            </StyledH2>
          </Block>
          <BlockInputStyled>
            <FormattedMessage id="portlet.newsletter.input.placeholder">
              {text => (
                <TextField
                  className="newsletter__input"
                  type="email"
                  defaultValue={email}
                  onBlur={setAsFocused}
                  onChange={saveEmail}
                  placeholder={text}
                  showError={inputHasBeenFocused}
                  showClear
                  required={inputHasBeenFocused}
                  errorMessage={
                    <FormattedMessage id="portlet.newsletter.input.error" />
                  }
                />
              )}
            </FormattedMessage>
            <Button
              className="newsletter__submit-button"
              type="submit"
              width1Of3
              primary
            >
              <FormattedMessage id="portlet.newsletter.signup" />
            </Button>
          </BlockInputStyled>
        </BlockStyled>
        <BlockIconStyled>
          <StyledIcon send />
        </BlockIconStyled>
      </MainBlockStyled>
    </Form>
  </Block>
);

export const StyledNewsletter = styled(Newsletter)`
  background-color: #9eafc1;
  border-radius: 0.15rem;
`;

export default newsletterHoc(StyledNewsletter);
