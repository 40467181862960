import React from 'react';

import { Period } from './styles';
import Label from './label';

export default ({ label, ...props }) => (
  <Period center width3of3 marginTop {...props}>
    <Label year text={label} />
  </Period>
);
