import React from 'react';
import camel from 'lodash.camelcase';
import { Icon, Button } from 'lucentum';

import { Textfield, Wrapper, ErrorTextField } from './styles';

export default ({
  children,
  errorMessage,
  icon,
  onClear,
  showClear = false,
  showError = false,
  type = 'text',
  value,
  ...props
}) => (
  <Textfield>
    <input
      defaultValue={value || children}
      type={type}
      value={value}
      {...props}
    />
    {(icon || onClear) && (
      <Wrapper showClear={showClear} middle center>
        {onClear && <Button onClick={onClear} borderless icon="multiply" />}
        {icon && <Icon {...{ [camel(icon)]: true }} />}
      </Wrapper>
    )}
    {errorMessage && showError && <ErrorTextField>{errorMessage}</ErrorTextField>}
  </Textfield>
);
