import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Block, Content, Spinner } from 'lucentum';
import Pagination from '../../components/pagination';
import { FormattedMessage } from 'react-intl';
import { StyledTitle } from '../../commonStyles/';
import { MobileFilterMenu } from '../news/component';

import {
  Page,
  DetailedNews,
  FiltersMenu,
  buildItemFilter
} from '../../components';
import { ButtonStyles, BlockStyled, StyledDetailedNews } from './styles';
import {
  Event as EventPropType,
  EventsFilters as EventsFiltersPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { DEFAULT_EVENTS_FILTERS } from '../../utils/constants';
import { BreadcrumbsWrapper } from '../news/component';

const buildFilters = ({ offices, topics, webinar }, chosenOptions) => [
  {
    ...buildItemFilter(
      offices,
      <FormattedMessage id="portlet.action.filter_member" />,
      'offices',
      chosenOptions
    )
  },
  {
    ...buildItemFilter(
      topics,
      <FormattedMessage id="portlet.action.filter_topic" />,
      'topics',
      chosenOptions
    )
  },
  {
    ...buildItemFilter(
      webinar,
      <FormattedMessage id="portlet.action.filter_webinar" />,
      'webinar',
      chosenOptions
    )
  }
];

class Events extends Component {
  state = {
    isOpen: false,
    filters: DEFAULT_EVENTS_FILTERS,
    appliedFilters: DEFAULT_EVENTS_FILTERS
  };

  onPageChange = (e, link) => {
    e.preventDefault();
    const { getPaginatedEvents, currentPage } = this.props;
    link === 'right'
      ? getPaginatedEvents(currentPage + 1, this.state.filters)
      : link === 'left'
      ? getPaginatedEvents(currentPage - 1, this.state.filters)
      : link === 'first'
      ? getPaginatedEvents(1, this.state.filters)
      : getPaginatedEvents(this.props.pages, this.state.filters);
  };

  resetFilters = () => {
    this.setState(({ filters }) => ({
      filters: Object.keys(filters).reduce(
        (acc, field) => ({ ...acc, [field]: [] }),
        {}
      )
    }));
  };

  onMobileMenuSubmit = () => {
    const { filters } = this.state;
    this.applyFilters(filters);
    this.setState(() => ({ isOpen: false }));
  };

  onMobileMenuRequestClose = () =>
    this.setState(({ appliedFilters }) => ({
      isOpen: false,
      filters: appliedFilters
    }));

  onFiltersChange = direct => filters => {
    this.setState(
      () => ({ filters }),
      () => direct && this.applyFilters(filters)
    );
  };

  applyFilters = filters => {
    this.setState(
      () => ({
        appliedFilters: filters
      }),
      () => this.props.getPaginatedEvents(1, filters)
    );
  };

  render = () => {
    const { eventsFilters, queryingFilters } = this.props;
    const filters = buildFilters(
      eventsFilters.filters || DEFAULT_EVENTS_FILTERS,
      {
        ...this.state.filters
      }
    );

    return (
      <Page active={this.props.active}>
        <BreadcrumbsWrapper page="events" />
        {queryingFilters ? (
          <Block center>
            <Spinner show row size={10} />
          </Block>
        ) : (
          <Block>
            <Content>
              <Block hiddenXS visibleLG marginBottom>
                <StyledTitle>
                  <FormattedMessage id="portlet.section.events" />
                </StyledTitle>
              </Block>
            </Content>
            <Content>
              <Block hiddenXS visibleLG width1Of3 paddingRight marginTop>
                <Block highlight padding style={{ height: '100%' }}>
                  <FiltersMenu
                    filters={filters}
                    error={eventsFilters.error}
                    onFiltersChange={this.onFiltersChange(true)}
                  />
                </Block>
              </Block>
              <Block hiddenXS visibleLG width3Of3 marginTop>
                <DetailedNews
                  src={this.props.paginatedEvents.paginatedEvents}
                  querying={this.props.paginatedEvents.querying}
                  error={this.props.paginatedEvents.error}
                />
              </Block>
              <BlockStyled hiddenLG visibleXS width3Of3>
                <StyledDetailedNews
                  src={this.props.paginatedEvents.paginatedEvents}
                  querying={this.props.paginatedEvents.querying}
                  error={this.props.paginatedEvents.error}
                  mobile
                />
              </BlockStyled>
            </Content>
            <Content>
              <Block width3Of3 right>
                {this.props.pages >= 2 && (
                  <Pagination
                    className="paginator"
                    onPageChange={this.onPageChange}
                    pageTotalKey={this.props.pages}
                    pageKey={this.props.currentPage}
                  />
                )}
              </Block>
            </Content>
          </Block>
        )}

        <Content>
          <Block visibleXS hiddenLG>
            <Block widthXS1of1 alignContents="center" marginTop={2}>
              <ButtonStyles
                title={
                  <FormattedMessage id="portlet.action.filter_responsive_button" />
                }
                icon="filters"
                onClick={() => this.setState({ isOpen: true })}
              />
              <MobileFilterMenu
                filters={filters}
                menu={() => (
                  <FiltersMenu
                    onFiltersChange={this.onFiltersChange(false)}
                    filters={filters}
                    error={eventsFilters.error}
                  />
                )}
                isOpen={this.state.isOpen}
                onRequestClose={this.onMobileMenuRequestClose}
                onSubmit={this.onMobileMenuSubmit}
                resetFilters={this.resetFilters}
              />
            </Block>
          </Block>
        </Content>
      </Page>
    );
  };

  componentDidMount = () => {
    this.props.getInitialData();
  };
}

Events.propTypes = {
  paginatedEvents: ReducerPropType({
    paginatedEvents: PropTypes.arrayOf(EventPropType)
  }),
  active: PropTypes.string.isRequired,
  eventsFilters: PropTypes.shape({
    filters: EventsFiltersPropType
  }),
  pages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  getPaginatedEvents: PropTypes.func.isRequired
};

export default Events;
