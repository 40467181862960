import {
  MEMBERS_RECEIVED,
  MEMBERS_REQUEST_FAILED,
  MEMBERS_REQUEST_FINISHED,
  MEMBERS_REQUESTED
} from './types';

import api from '../../utils/api';

export const getMembersRequested = () => ({
  type: MEMBERS_REQUESTED,
  payload: null
});

export const getMembersSucceed = members => ({
  type: MEMBERS_RECEIVED,
  payload: { members }
});

export const getMembersFailed = er => ({
  type: MEMBERS_REQUEST_FAILED,
  payload: { error: er }
});

export const getMembersFinished = () => ({
  type: MEMBERS_REQUEST_FINISHED,
  payload: null
});

export const getMembers = () => async dispatch => {
  dispatch(getMembersRequested());
  try {
    const { data: members } = await api.getMembers();
    dispatch(getMembersSucceed(members));
  } catch (error) {
    dispatch(getMembersFailed(error.response.data));
  } finally {
    dispatch(getMembersFinished());
  }
};
