import {
  NEWS_RECEIVED,
  NEWS_REQUEST_FAILED,
  NEWS_REQUEST_FINISHED,
  NEWS_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  news: null,
  error: null,
  currentPage: 1,
  numNews: null
};

export const newsRequested = state => ({ ...state, querying: true });

export const newsReceived = (state, action) => ({
  ...state,
  news: action.payload.news.map(newsItem => ({
    detail: newsItem.date,
    text: newsItem.description,
    href: `#/news/${newsItem.id}`,
    ...newsItem
  })),
  numNews: action.payload.numNews,
  currentPage: action.payload.currentPage,
  error: null
});

export const newsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  news: null,
  currentPage: 1,
  numNews: null
});

const newsRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [NEWS_REQUESTED]: newsRequested,
  [NEWS_RECEIVED]: newsReceived,
  [NEWS_REQUEST_FAILED]: newsRequestFailed,
  [NEWS_REQUEST_FINISHED]: newsRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
