import React, { Component } from 'react';
import { isEmail } from 'validator';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Content, Block, Fieldset, Button, Link, Spinner, P } from 'lucentum';

import { Notification } from '../';
import Field from './field';
import UploadFile from './upload-file';
import { MarginLeftCheckbox, BlockResponsive, BigButton, Form } from './styles';
import { Strong, StyledTitle, BottomBar } from '../../commonStyles/';

class ContactForm extends Component {
  state = {
    formData: {},
    focused: {},
    formSent: false,
    inputFileText: <FormattedMessage id="portlet.label.contact.attach_file" />
  };

  fields = [
    {
      field: 'name',
      title: (
        <FormattedMessage id="portlet.label.contact.name">
          {message => <span>{message + '*'}</span>}
        </FormattedMessage>
      ),
      required: true,
      width1of2: true
    },
    {
      field: 'surname',
      title: (
        <FormattedMessage id="portlet.label.contact.surname">
          {message => <span>{message + '*'}</span>}
        </FormattedMessage>
      ),
      required: true,
      width1of2: true
    },
    {
      field: 'organisation',
      title: <FormattedMessage id="portlet.label.contact.organisation" />,
      required: false
    },
    {
      field: 'email',
      title: (
        <FormattedMessage id="portlet.label.contact.email">
          {message => <span>{message + '*'}</span>}
        </FormattedMessage>
      ),
      required: true,
      type: 'email'
    },
    {
      field: 'topic',
      title: (
        <FormattedMessage id="portlet.label.contact.topic">
          {message => <span>{message + '*'}</span>}
        </FormattedMessage>
      ),
      placeholder: (
        <FormattedMessage id="portlet.label.topic.message"/>
      ),
      required: true,
      type: 'select'
    },
    {
      field: 'message',
      title: (
        <FormattedMessage id="portlet.label.contact.message">
          {message => <span>{message + '*'}</span>}
        </FormattedMessage>
      ),
      required: true,
      type: 'textArea'
    }
  ];

  getSelectOptions = contactUsTopics =>
    contactUsTopics
      .map(({ title, id }) => ({
        text: title,
        key: id
      }))
      .map((item, id) => ({ id, ...item }));

  updateFormField = ({ target: { value, name } }) => {
    this.setState({ formData: { ...this.state.formData, [name]: value } });
  };

  updateFormFile = ({ target: { files, name } }) =>
    this.setState({
      inputFileText: files[0].name,
      formData: { ...this.state.formData, [name]: files[0] }
    });

  handleSelect = ({ key: topic }) => {
    this.setState(() => ({
      formData: { ...this.state.formData, topic }
    }));
  };

  handleCheckbox = ({ target: { checked } }) =>
    this.setState(() => ({
      formData: { ...this.state.formData, checked }
    }));

  hadSelectedTopic = () =>
    !!this.props.contactUsTopics.find(
      ({ id }) => id === this.state.formData.topic
    );

  isValidForm = () => {
    const { name, surname, email, message } = this.state.formData;
    this.fields.map(({ field }) =>
      this.markAsFocused({ target: { name: field } })
    );

    return !!(
      name &&
      surname &&
      this.hadSelectedTopic() &&
      email &&
      message &&
      isEmail(email)
    );
  };

  callSubmitForm = async ev => {
    ev.preventDefault();
    if (!this.props.submitting && this.isValidForm()) {
      await this.props.handleSubmit(this.state.formData);
      this.setState(() => ({ formSent: true }));
    }
  };

  markAsFocused = ({ target: { name } }) => {
    this.setState(({ focused }) => ({ focused: { ...focused, [name]: true } }));
  };

  shouldShowSpinner = () => this.props.querying || this.props.submitting;

  shouldShowSuccessMessage = () =>
    this.state.formSent && !this.props.submitError;

  shouldShowFormFailureMessage = () =>
    !this.state.formSent && this.props.submitError;

  shouldShowForm = () =>
    !this.state.formSent &&
    !this.props.submitError &&
    this.props.contactUsTopics;

  isFieldFilled = (field, type) =>
    type === 'email'
      ? /[^@]+@.+/.test(this.state.formData[field])
      : !!this.state.formData[field];

  render = () => (
    <Content>
      {this.shouldShowSpinner() ? (
        <Block center>
          <Spinner show row size={10} />
        </Block>
      ) : (
        <BlockResponsive paddingTop>
          <Block hiddenXS visibleLG paddingBottom={3}>
            <StyledTitle className="title">
              <FormattedMessage id="portlet.label.contact.title" />
            </StyledTitle>
          </Block>
          {this.shouldShowSuccessMessage() && (
            <Block>
              <Notification success>
                <FormattedMessage id="portlet.notification.success_message" />
              </Notification>
              <Link to="#/">
                <P>
                  <FormattedMessage id="portlet.contact.back_to_homepage" />
                </P>
              </Link>
            </Block>
          )}
          {this.shouldShowFormFailureMessage() && (
            <Block>
              <Notification error>
                <FormattedMessage id="portlet.notification.fail_message" />
              </Notification>
              <Link to="#/">
                <P>
                  <FormattedMessage id="portlet.contact.back_to_homepage" />
                </P>
              </Link>
            </Block>
          )}
          <BlockResponsive paddingTop={2}>
            {this.shouldShowForm() && (
              <Form onSubmit={this.callSubmitForm} noValidate>
                <BlockResponsive paddingLeft={9} paddingRight={9}>
                  {this.fields.map(
                    ({ field, title, required, type, width1of2, placeholder }, i) => {
                      const {
                        getSelectOptions,
                        markAsFocused,
                        updateFormField,
                        handleSelect,
                        props,
                        state: { focused }
                      } = this;
                      return (
                        <BlockResponsive
                          width1of2={width1of2}
                          paddingLeft={3}
                          paddingRight={3}
                          marginBottom
                          key={i}
                        >
                          <Field
                            markAsFocused={markAsFocused}
                            title={title}
                            required={required}
                            field={field}
                            updateFormField={updateFormField}
                            handleSelect={handleSelect}
                            focused={focused[field]}
                            filled={this.isFieldFilled(field, type)}
                            type={type}
                            src={getSelectOptions(props.contactUsTopics)}
                            placeholder={placeholder}
                          />
                        </BlockResponsive>
                      );
                    }
                  )}
                  <BlockResponsive paddingLeft={3} paddingRight={3}>
                    <Fieldset
                      label={
                        <Block hiddenXS visibleLG>
                          <Strong>
                            <FormattedMessage id="portlet.label.contact.attach_file" />
                          </Strong>
                        </Block>
                      }
                    >
                      <UploadFile handleUpload={this.updateFormFile} />
                    </Fieldset>
                    <Fieldset>
                      <MarginLeftCheckbox
                        name="copy"
                        label={
                          <FormattedMessage id="portlet.action.contact.send_copy_email" />
                        }
                        onChange={this.handleCheckbox}
                      />
                    </Fieldset>
                    <Block wrapper textRight hiddenXS visibleLG>
                      <Button
                        className="form--submit"
                        primary
                        disabled={this.props.submitting}
                      >
                        <FormattedMessage id="common.submit" />
                      </Button>
                    </Block>
                  </BlockResponsive>
                  <BottomBar
                    hiddenLG
                    middle
                    center
                    paddingTop={2}
                    paddingBottom={2}
                  >
                    <BigButton
                      className="form--submit--mob"
                      primary
                      disabled={this.props.submitting}
                    >
                      <Strong>
                        <FormattedMessage id="portlet.action.form.submit" />
                      </Strong>
                    </BigButton>
                  </BottomBar>
                </BlockResponsive>
              </Form>
            )}
          </BlockResponsive>
        </BlockResponsive>
      )}
    </Content>
  );
}

ContactForm.propTypes = {
  querying: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  submitError: PropTypes.bool
};

export default ContactForm;
