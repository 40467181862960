import React from 'react';
import PropTypes from 'prop-types';
import { Content, Block, Spinner } from 'lucentum';

import ErrorMessage from '../error-message';
import Newsletter from './newsletter';
import { FormattedMessage } from 'react-intl';
import { CardStyled, BlockNewsStyled } from './styles';
import { StyledTitle } from '../../commonStyles';
import {
  Newsletter as NewsletterPropType,
  Reducer as ReducerPropType
} from '../../propTypes';

const NewsletterPage = ({
  newsletters,
  submitToNewsletter,
  subscribedNewsletter,
  querying,
  error
}) => (
  <Content>
    {querying && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {!querying && error && <ErrorMessage />}
    {!querying && newsletters && (
      <Block>
        <Block marginTop marginBottom={3}>
          <StyledTitle>
            <FormattedMessage id="portlet.section.newsletters" />
          </StyledTitle>
        </Block>
        {!subscribedNewsletter.subscribed && (
          <Block>
            <Newsletter registerFunction={submitToNewsletter} />
          </Block>
        )}
        <BlockNewsStyled marginTop>
          {newsletters.map(({ id, title, downloadUrl }) => (
            <CardStyled
              marginBottom={2}
              title={title}
              downloadUrl={downloadUrl}
              key={`card-${id}`}
              id={id}
            />
          ))}
        </BlockNewsStyled>
      </Block>
    )}
  </Content>
);

NewsletterPage.propTypes = {
  ...ReducerPropType({
    newsletters: PropTypes.arrayOf(NewsletterPropType)
  }).isRequired,
  submitToNewsletter: PropTypes.func.isRequired,
  subscribedNewsletter: ReducerPropType({
    subscribed: PropTypes.bool
  }).isRequired
};

export default NewsletterPage;
