import styled from 'styled-components';
import { H3 as H3L, Link as L, P as Paragraph } from 'lucentum';

export const P = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.sizeSmall};
`;

export const Strong = styled.strong`
  font-weight: bold;
`;

export const H3 = styled(H3L)`
  background-color: ${({ theme }) => theme.h3CurtainBgColor};
  padding: ${({ theme }) => theme.sizeSmall};
  padding-left: ${({ theme }) => theme.sizeLarge};
`;

export const Ul = styled.ul`
  list-style: none;
  padding-left: ${({ theme }) => theme.sizeSmall};
  font-size: ${({ theme }) => theme.size};
  margin-top: ${({ theme }) => theme.sizeSmall};
`;

export const Li = styled.li`
  line-height: 1.5;
  font-size: ${({ theme }) => theme.fontSize};
  border-bottom: 1px solid rgba(100, 100, 100, 0.2);
  &:before {
    content: '•';
    padding-right: ${({ theme }) => theme.sizeSmall};
    color: ${({ theme }) => theme.colorGrayLight};
  }
`;

export const Link = styled(L)`
  color: ${({ theme }) => theme.fontColor};
`;
