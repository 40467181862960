import {
  FEATURED_ECPS_RECEIVED,
  FEATURED_ECPS_REQUEST_FAILED,
  FEATURED_ECPS_REQUEST_FINISHED,
  FEATURED_ECPS_REQUESTED,
  FEATURED_ECPS_PIN_ECP_SUCCEED,
  FEATURED_ECPS_PIN_ECP_FAILED
} from './types';
import api from '../../utils/api';

export const getFeaturedEcpsRequested = () => ({
  type: FEATURED_ECPS_REQUESTED,
  payload: null
});

export const getFeaturedEcpsSucceed = featuredEcps => ({
  type: FEATURED_ECPS_RECEIVED,
  payload: { featuredEcps }
});

export const getFeaturedEcpsFailed = er => ({
  type: FEATURED_ECPS_REQUEST_FAILED,
  payload: { error: er }
});

export const getFeaturedEcpsFinished = () => ({
  type: FEATURED_ECPS_REQUEST_FINISHED,
  payload: null
});

export const getFeaturedEcps = () => async dispatch => {
  dispatch(getFeaturedEcpsRequested());
  try {
    const { data: featuredEcps } = await api.getFeaturedEcps();
    dispatch(getFeaturedEcpsSucceed(featuredEcps));
  } catch (error) {
    dispatch(getFeaturedEcpsFailed(error.response.data));
  } finally {
    dispatch(getFeaturedEcpsFinished());
  }
};

export const pinEcpSucceed = ecpId => ({
  type: FEATURED_ECPS_PIN_ECP_SUCCEED,
  payload: { ecpId }
});

export const pinEcpFailed = error => ({
  type: FEATURED_ECPS_PIN_ECP_FAILED,
  payload: { error }
});
