import {
  FEATURED_NEWS_REQUEST_SUCCEED,
  FEATURED_NEWS_REQUEST_FAILED,
  FEATURED_NEWS_REQUEST_FINISHED,
  FEATURED_NEWS_REQUEST_STARTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  featuredNews: null,
  error: null
};

export const featuredNewsRequested = state => ({ ...state, querying: true });

export const featuredNewsReceived = (state, action) => ({
  ...state,
  featuredNews: action.payload.featuredNews.map(newsItem => ({
    detail: newsItem.date,
    text: newsItem.description,
    href: `#/news/${newsItem.id}`,
    ...newsItem
  })),
  error: null
});

export const featuredNewsRequestFailed = (state, action) => ({
  ...state,
  error: action.payload.error,
  featuredNews: null
});

const featuredNewsRequestFinished = state => ({ ...state, querying: false });

const reducersMap = {
  [FEATURED_NEWS_REQUEST_STARTED]: featuredNewsRequested,
  [FEATURED_NEWS_REQUEST_SUCCEED]: featuredNewsReceived,
  [FEATURED_NEWS_REQUEST_FAILED]: featuredNewsRequestFailed,
  [FEATURED_NEWS_REQUEST_FINISHED]: featuredNewsRequestFinished
};

export default (state = INITIAL_STATE, action) =>
  reducersMap[action.type] ? reducersMap[action.type](state, action) : state;
