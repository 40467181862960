import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  practiceId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  commonCommunicationSource: PropTypes.string.isRequired,
  commonCommunicationTitle: PropTypes.string.isRequired,
  dateApproved: PropTypes.number.isRequired,
  contactEmail: PropTypes.string,
  contactPhone: PropTypes.string,
  category: PropTypes.string.isRequired,
  implementations: PropTypes.array
});
