import React from 'react';
import { Block, Button, P } from 'lucentum';
import { Strong } from '../../commonStyles';
import Label from './label';
import Period from './period';
import Milestone from './milestone';
import { Timeline, Titles, Image, Text, LabelLegend } from './styles';
import { FormattedMessage } from 'react-intl';

const goTo = href => e => {
  e.preventDefault();
  window.location.href = href;
};

const openInNewWindow = href => e => {
  e.preventDefault();
  window.open(href);
};

export const RenderTimeline = ({ src, office, members }) => {
  console.log('office', office)
  console.log('src', src)
  let zigzag = false;
  return (
    <Block>
      {src.map(
        ({ type, right, image, title, button, text, moreInfo, toolId, date, background, ...props }, key) => {

          if (type === 'Period') {
            return <Period key={key} {...props} />
          } else {
            zigzag = !zigzag;
            return (

              <Milestone key={key} right={members ? zigzag : right} primary={members ? right : !right} background={background}>
                <Block paddingBottom>
                  <Block paddingBottom>
                    {image && <Image {...image} />}
                    {image ? (
                      <P>
                        <Strong>{title}</Strong>
                      </P>
                    ) : (
                      <Strong>{title}</Strong>
                    )}
                    <Text>{date}</Text>
                    <Text>{text}</Text>
                  </Block>

                </Block>
                <Block right>
                  {button && button.href && (
                    <Button
                      onClick={openInNewWindow(button.href)}
                      className="button--launch"
                    >
                      <FormattedMessage id={button.title} />
                    </Button>
                  )}
                  {moreInfo && (
                    <Button
                      onClick={goTo(moreInfo.href)}
                      borderless
                      className="button--moreInfo"
                    >
                      <FormattedMessage id={moreInfo.title} />
                    </Button>
                  )}
                </Block>
              </Milestone>
            )
          }
        }

      )}
    </Block>
  )
};


export default ({ src, office, children, className, seriesLeft, seriesRight, seriesCenter }) => (
  <Block column className={className}>
    <Titles widthXS10of12 widthMD3of3 justify padding marginBottom>
      <Block hiddenXS={!seriesLeft} visibleMD>
        {seriesLeft && (
          <Label primary left text={seriesLeft} className="left--label" members={seriesCenter} />
        )}
      </Block>
      <Block hiddenXS={!seriesCenter} visibleMD >
        {seriesCenter && (
          <Label center text={seriesCenter} className="center--label" members={seriesCenter} />
        )}
      </Block>
      <Block hiddenXS={!seriesRight} visibleMD >
        {seriesRight && (
          <Label right text={seriesRight} className="right--label" members={seriesCenter} />
        )}
      </Block>
    </Titles>

    <Timeline>{src ? <RenderTimeline members={seriesCenter} src={src} office={office} /> : children} </Timeline>
  </Block>
);
