import React, { Component } from 'react';
import { Block, Content } from 'lucentum';
import PropTypes from 'prop-types';
import {
  SearchResult as SearchResultPropType,
  Reducer as ReducerPropType
} from '../../propTypes';
import { PageSearch, Page } from '../../components/';
import Pagination from '../../components/pagination';
import { DEFAULT_TYPE_SEARCH } from '../../utils/constants';

class Search extends Component {
  onPageChange = (e, link) => {
    e.preventDefault();
    const {
      changeToPage,
      currentPage,
      pages,
      match: {
        params: { searchTerm, searchType = DEFAULT_TYPE_SEARCH }
      }
    } = this.props;

    if (link === 'right') {
      changeToPage(searchTerm, searchType, currentPage + 1);
    } else if (link === 'left') {
      changeToPage(searchTerm, searchType, currentPage - 1);
    } else if (link === 'first') {
      changeToPage(searchTerm, searchType, 1);
    } else {
      changeToPage(searchTerm, searchType, pages);
    }
  };

  search = (searchTerm, searchType) => {
    window.location.href = `#/search/${searchType}/${encodeURIComponent(
      searchTerm
    )}`;
    this.props.getSearchResults(searchTerm, searchType);
  };

  static SEARCH_RESULTS_PER_PAGE = 5;

  render() {
    return (
      <Page active={this.props.active}>
        <Content>
          <PageSearch
            searchResults={this.props.searchResults.searchResults}
            numResults={this.props.searchResults.numResults}
            querying={this.props.searchResults.querying}
            error={this.props.searchResults.error}
            searchTerm={this.props.match.params.searchTerm}
            searchType={this.props.match.params.searchType}
            getSearchResults={this.search}
            currentPage={this.props.currentPage}
            numPages={this.props.pages}
          />
        </Content>
        <Content />
        {this.props.pages >= 2 && !this.props.searchResults.querying ? (
          <Content>
            <Block width3of3 marginBottom={2} marginRight right>
              <Pagination
                className="paginator"
                onPageChange={this.onPageChange}
                pageTotalKey={this.props.pages}
                pageKey={this.props.currentPage}
              />
            </Block>
          </Content>
        ) : null}
      </Page>
    );
  }
}

Search.propTypes = {
  searchResults: ReducerPropType({
    searchResults: PropTypes.arrayOf(SearchResultPropType)
  }),
  getSearchResults: PropTypes.func.isRequired,
  changeToPage: PropTypes.func.isRequired
};

export default Search;
