import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link } from 'lucentum';

import PaginationStyled from './styles';

const LINK_FIRST = 'first';
const LINK_LAST = 'last';
const LINK_LEFT = 'left';
const LINK_RIGHT = 'right';

const isFirstPage = page => page === 1;
const isLastPage = (page, totalPages) => page === totalPages;

const Pagination = ({ onPageChange, pageKey, pageTotalKey, ...props }) => (
  <PaginationStyled {...props}>
    <div>
      <Link
        href="#"
        disabled={isFirstPage(pageKey)}
        onClick={e => onPageChange(e, LINK_FIRST)}
      >
        <Icon chevron-left />
        <Icon chevron-left />
      </Link>
      <Link
        href="#"
        disabled={isFirstPage(pageKey)}
        onClick={e => onPageChange(e, LINK_LEFT)}
      >
        <Icon chevron-left />
      </Link>
    </div>
    <span>
      {pageKey} / {pageTotalKey}
    </span>
    <div>
      <Link
        href="#"
        disabled={isLastPage(pageKey, pageTotalKey)}
        onClick={e => onPageChange(e, LINK_RIGHT)}
      >
        <Icon chevron-right />
      </Link>
      <Link
        href="#"
        disabled={isLastPage(pageKey, pageTotalKey)}
        onClick={e => onPageChange(e, LINK_LAST)}
      >
        <Icon chevron-right />
        <Icon chevron-right />
      </Link>
    </div>
  </PaginationStyled>
);
Pagination.propTypes = {
  className: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  pageKey: PropTypes.number.isRequired,
  pageTotalKey: PropTypes.number.isRequired
};
export default Pagination;
