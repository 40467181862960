import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { HashRouter as Router } from 'react-router-dom';
import { Spinner, Content } from 'lucentum';

import AppStyles from './styles';
import store from '../../redux/store';
import { getOffice } from '../../redux/office/action-creators';
import { getOffices } from '../../redux/offices/action-creators';
import { getTool } from '../../redux/tool/action-creators';
import { getEcp } from '../../redux/ecp/action-creators';
import { getEvent } from '../../redux/event/action-creators';
import { getNewsItem } from '../../redux/newsItem/action-creators';
import { getPractice } from '../../redux/practice/action-creators';
import {
  HomePage,
  EventsPage,
  NewsPage,
  NewsItemPage,
  NewslettersPage,
  ContactPage,
  MembersPage,
  MemberPage,
  EventPage,
  ToolsPage,
  EcpsPage,
  PracticesPage,
  PracticePage,
  ToolPage,
  EcpPage,
  SearchPage,
  CommonPracticesPage,
  CurtainPage
} from '../../pages';
import ErrorMessage from '../../components/error-message';
import injectActivePage from '../inject-active-page/';
import { DEFAULT_LANGUAGE } from '../../utils/constants';

export default class extends Component {
  hasFetchedAppData = () =>
    this.props.authToken &&
    !this.props.gettingLabels &&
    !this.props.gettingActiveLanguage &&
    !this.props.changingLanguage;

  shouldFetchAppData = prevProps =>
    (!this.props.authToken &&
      prevProps.activeLanguage !== this.props.activeLanguage) ||
    prevProps.activeLanguage !== this.props.activeLanguage;

  render = () =>
    !this.hasFetchedAppData() ? (
      <Spinner show />
    ) : this.props.fetchDataFailed ? (
      <AppStyles>
        <Router basename="/" slash>
          <Switch>
            <Route path="/curtain" component={CurtainPage} />
            <Route
              path="*"
              render={() => (
                <Content>
                  <ErrorMessage />
                </Content>
              )}
            />
          </Switch>
        </Router>
      </AppStyles>
    ) : (
      <AppStyles>
        <Router basename="/" slash>
          <Switch>
            <Route
              path="/"
              exact
              render={({ match, history }) => {
                const EnhancedHome = injectActivePage(HomePage)(match)(history);
                return <EnhancedHome />;
              }}
            />
            <Route
              path="/events"
              exact
              render={({ match, history }) => {
                const EnhancedEventsPage = injectActivePage(EventsPage)(match)(history);
                return <EnhancedEventsPage />;
              }}
            />
            <Route
              path="/events/:id"
              exact
              render={({ match, history }) => {
                const EnhancedEventPage = injectActivePage(EventPage)(match)(history);
                const eventId = match.params.id;
                store.dispatch(getEvent(eventId));
                return <EnhancedEventPage />;
              }}
            />
            <Route
              path="/news"
              exact
              render={({ match, history }) => {
                const EnhancedNewsPage = injectActivePage(NewsPage)(match)(history);
                return <EnhancedNewsPage />;
              }}
            />
            <Route
              path="/news/:id"
              exact
              render={({ match, history }) => {
                const EnhancedNewsItemPage = injectActivePage(NewsItemPage)(
                  match
                )(history);
                const newsItemId = match.params.id;
                store.dispatch(getNewsItem(newsItemId));
                return <EnhancedNewsItemPage newsId={match.params.id} />;
              }}
            />
            <Route
              path="/tools"
              exact
              render={({ match, history }) => {
                const EnhancedToolsPage = injectActivePage(ToolsPage)(match)(history);
                return <EnhancedToolsPage />;
              }}
            />
            <Route
              path="/tools/:id"
              exact
              render={({ match, history }) => {
                const EnhancedToolPage = injectActivePage(ToolPage)(match)(history);
                const toolId = match.params.id;
                store.dispatch(getTool(toolId));
                return <EnhancedToolPage />;
              }}
            />
            <Route
              path="/ecps"
              exact
              render={({ match, history }) => {
                const EnhancedEcpsPage = injectActivePage(EcpsPage)(match)(history);
                return <EnhancedEcpsPage />;
              }}
            />
            <Route
              path="/ecps/:id"
              exact
              render={({ match, history }) => {
                const EnhancedEcpPage = injectActivePage(EcpPage)(match)(history);
                const ecpId = match.params.id;
                store.dispatch(getEcp(ecpId));
                return <EnhancedEcpPage />;
              }}
            />
            <Route
              path="/contact"
              exact
              render={({ match, history }) => {
                const EnhancedContactPage = injectActivePage(ContactPage)(
                  match
                )(history);
                return <EnhancedContactPage />;
              }}
            />
            <Route
              path="/members"
              exact
              render={({ match, history }) => {
                const EnhancedMembersPage = injectActivePage(MembersPage)(
                  match
                )(history);
                return <EnhancedMembersPage />;
              }}
            />
            <Route
              path="/members/:id"
              exact
              render={({ match, history }) => {
                const EnhancedMemberPage = injectActivePage(MemberPage)(match)(history);
                const officeId = match.params.id;
                store.dispatch(getOffices());
                store.dispatch(getOffice(officeId));
                return <EnhancedMemberPage />;
              }}
            />
            <Route
              path="/newsletters"
              exact
              render={({ match, history }) => {
                const EnhancedNewsletterPage = injectActivePage(
                  NewslettersPage
                )(match)(history);
                return <EnhancedNewsletterPage />;
              }}
            />
            <Route
              path="/search/:searchType?/:searchTerm?"
              render={({ match, history }) => {
                const EnhancedSearchPage = injectActivePage(SearchPage)(match)(history);
                return <EnhancedSearchPage />;
              }}
            />
            <Route
              path="/practices"
              exact
              render={({ match, history }) => {
                const EnhancedPracticesPage = injectActivePage(PracticesPage)(
                  match
                )(history);
                return <EnhancedPracticesPage />;
              }}
            />
            <Route
              path="/practices/:id"
              exact
              render={({ match, history }) => {
                const EnhancedPracticePage = injectActivePage(PracticePage)(
                  match
                )(history);
                const practiceId = match.params.id;
                store.dispatch(getPractice(practiceId));
                return <EnhancedPracticePage />;
              }}
            />
            <Route
              path="/common-practices"
              exact
              render={({ match, history }) => {
                const EnhancedCommonPracticesPage = injectActivePage(
                  CommonPracticesPage
                )(match)(history);
                return <EnhancedCommonPracticesPage />;
              }}
            />
            <Route path="/curtain" component={CurtainPage} />
            <Route path="*" component={CurtainPage} />
          </Switch>
        </Router>
      </AppStyles>
    );

  static propTypes = {
    gettingLabels: PropTypes.bool.isRequired,
    changingLanguage: PropTypes.bool.isRequired,
    gettingActiveLanguage: PropTypes.bool.isRequired,
    activeLanguage: PropTypes.string,
    getLabels: PropTypes.func.isRequired,
    getLoggedUser: PropTypes.func.isRequired,
    getLanguages: PropTypes.func.isRequired,
   getMatomoProperties: PropTypes.func.isRequired,
  };

  static defaultPropTypes = {
    activeLanguage: DEFAULT_LANGUAGE
  };

  componentDidMount() {
    this.props.applyFontSizeFromCookie();
  }

  componentDidUpdate(prevProps) {
    if (this.shouldFetchAppData(prevProps)) {
      this.props.getLabels();
      this.props.getLanguages();
      this.props.getLoggedUser();
       this.props.loadCookieLawState();
      this.props.getMatomoProperties();
    }
  }
}
