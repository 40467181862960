import styled from 'styled-components';
import cookie from 'js-cookie';
import { Block, Card, Image as ImageLucentum, P } from 'lucentum';
import sizes from '../page/sizes';
const subProp = main => sub => obj => obj[main][sub];
const them = subProp('theme');

const background = ({ primary, theme }) =>
  primary ? theme.colorLighter : theme.color;

const backgroundMembers = ({ right, center, left, theme }) =>
  right ? theme.colorLighter : center ? '#e5f7f9' : left ? theme.colorGrayAccent : background;

const border = ({ primary, theme }) =>
  `15px solid ${primary ? theme.colorLighter : theme.colorGrayAccent}`;

const calculateMaxheight = () => {
  const fontSize = cookie.get('fontSize');
  const cardHeights = {
    'font.size.small': '59px',
    'font.size.medium': '68px',
    'font.size.large': '74px',
    'font.size.extralarge': '80px'
  };
  const size = sizes.find(({ size }) => size === fontSize);
  return size ? cardHeights[size.value] : cardHeights['font.size.medium'];
};

export const Label = styled.div`
  border-radius: ${({ theme }) => theme.lineThick};
  color: ${({ primary, right, members, theme }) =>
    members || primary || right ? theme.color : theme.colorWhite};
  display: inline-block;
  flex: 1;
  text-align: center;
  z-index: 99;

  strong {
    padding: ${({ theme }) => `${theme.sizeSmall} ${theme.sizeDouble}`};
    background-color: ${({ members, right, center, left, theme }) =>
    members ? backgroundMembers : right ? theme.colorGrayAccent : background};
     
  }

  @media (${them('breakpointMaxMD')}) {
    ${({ year }) => year && 'margin-left: -85%;'};
    strong {
      font-size: ${({ theme }) => theme.fontSize};
      padding: ${({ theme }) => theme.sizeSmall};
      ${({ year, theme }) =>
    year &&
    `
            background-color: ${theme.colorWhite};
            color: ${theme.color};
        `};
    }
  }
`;

export const Titles = styled(Block)`
  text-align: center;
  strong {
    border-radius: ${({ theme }) => theme.lineThick};
  }
  @media (${them('breakpointMaxMD')}) {
    align-self: flex-end;
  }
`;

export const Circle = styled.div`

  background-color: ${({ primary, background, theme }) =>
    background ? background : primary ? theme.colorLight : theme.color};
  border-radius: 50%;
  border: 5px solid ${({ theme }) => theme.colorWhite};
  height: 1rem;
  margin-right: -0.9rem;
  margin-top: -0.85rem;
  position: absolute;
  right: 50%;
  top: 50%;
  width: 1rem;
  z-index: 100;

  @media (${them('breakpointMaxMD')}) {
    right: 90%;
  }
`;

export const Milestone = styled(Block)`
  position: relative;

  @media (${them('breakpointMaxMD')}) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledCard = styled(Card)`
  ${({ primary, theme }) =>
    primary && `background-color: ${theme.colorLighter}`};
 ${({ background }) =>
    background && `background-color: ${background}`};
  &:before {
    border-top: 15px solid ${({ theme }) => theme.colorTransparent};
    border-bottom: 15px solid ${({ theme }) => theme.colorTransparent};
    border-right: ${({ rightCard }) => (rightCard ? border : 0)};
    border-left: ${({ rightCard }) => (rightCard ? 0 : border)};
    content: '';
    margin-top: -15px;
    position: absolute;
    ${({ rightCard }) => rightCard && 'left: -15px'};
    right: ${({ rightCard }) => (rightCard ? 'initial' : '-15px')};
    top: 50%;
    z-index: 99;
  }
  &:after {
    background-color: ${({ theme }) => theme.colorTransparent};
    box-shadow: 2px 2px 5px rgba(160, 160, 159, 0.4);
    content: '';
    height: 20px;
    margin-top: -10px;
    position: absolute;
    ${({ rightCard }) => rightCard && 'left: -11px'};
    top: 50%;
    transform: rotate(45deg);
    width: 20px;
    z-index: -1;
  }
  @media (${them('breakpointMaxMD')}) {
    &:before {
      border-right: ${border};
      border-left: 0;
      content: '';
      left: -15px;
      right: initial;
    }
    &:after {
      box-shadow: unset;
    }
  }
`;

export const Timeline = styled(Block)`
  position: relative;
  padding-top: 5rem;
  &:before {
    background: ${({ theme }) => theme.color};
    content: '';
    height: 100%;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 0;
    width: 4px;

    @media (${them('breakpointMaxMD')}) {
      left: 10%;
    }
  }
  &:after {
    border-bottom: 25px solid ${({ theme }) => theme.color};
    border-left: solid 15px ${({ theme }) => theme.colorTransparent};
    border-right: solid 15px ${({ theme }) => theme.colorTransparent};
    content: '';
    left: 50%;
    margin-left: -15px;
    position: absolute;
    top: -5px;
    z-index: 99;

    @media (${them('breakpointMaxMD')}) {
      left: 10%;
    }
  }
`;

export const Image = styled(ImageLucentum)`
  width: auto;
  height: 26px;
`;

export const Period = styled(Block)`
  margin: ${({ theme }) => theme.size} 0;
  &:last-child {
    margin-bottom: 0;
  }
  strong {
    border-radius: 2px;
    padding: 0.3rem 2rem;
  }
  @media (${them('breakpointMaxMD')}) {
    padding-left: ${({ theme }) => theme.size};
  }
`;

export const Text = styled(P)`
  margin-top: 10px;
  color: ${({ theme }) => theme.colorGray};
  max-height: ${() => calculateMaxheight()};
  overflow: hidden;
`;
