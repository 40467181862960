import moment from 'moment';
import {
  TOOL_RECEIVED,
  TOOL_REQUEST_FAILED,
  TOOL_REQUEST_FINISHED,
  TOOL_REQUESTED,
  PIN_TOOL_REQUESTED,
  PIN_TOOL_RECEIVED,
  PIN_TOOL_FAILED,
  PIN_TOOL_FINISHED
} from './types';

import api from '../../utils/api';
import {
  pinToolSucceed as pinToolAtToolsReducer,
  pinToolFailed as pinToolFailedAtToolsReducer
} from '../tools/action-creators';
import {
  pinToolSucceed as pinToolAtFeaturedToolsReducer,
  pinToolFailed as pinToolFailedAtFeaturedToolsReducer
} from '../featuredTools/action-creators';
import { buildMilestone } from '../shared';

const buildPeriod = year => ({ type: 'Period', label: year });

const buildYears = tool =>
  tool.implementations.reduce((prev, item) => {
    const year = moment(item.date).year();
    const period = buildPeriod(year);
    if (!prev.length) {
      prev = [period];
    } else if (prev[prev.length - 1].label > year) {
      prev = [...prev, period];
    }
    return prev;
  }, []);

export const parseToolImplementations = tool => {
  tool.implementations = tool.implementations
    .map((item, index) => ({ item, index }))
    .sort((a, b) =>
      b.item.date - a.item.date === 0
        ? a.index - b.index
        : b.item.date - a.item.date
    )
    .map(({ item }) => item);
  return buildYears(tool).reduce((prev, item) => {
    const year = item.label;
    const implementations = tool.implementations.filter(
      ({ date }) => moment(date).year() === year
    );
    const milestones = implementations.map(item => buildMilestone(item));
    return [...prev, ...milestones, item];
  }, []);
};

export const getToolRequested = () => ({
  type: TOOL_REQUESTED,
  payload: null
});

export const getToolSucceed = tool => ({
  type: TOOL_RECEIVED,
  payload: { tool }
});

export const getToolFailed = er => ({
  type: TOOL_REQUEST_FAILED,
  payload: { error: er }
});

export const getToolFinished = () => ({
  type: TOOL_REQUEST_FINISHED,
  payload: null
});

export const pinToolRequested = () => ({
  type: PIN_TOOL_REQUESTED,
  payload: null
});
export const pinToolSucceed = () => ({
  type: PIN_TOOL_RECEIVED,
  payload: null
});
export const pinToolFailed = er => ({
  type: PIN_TOOL_FAILED,
  payload: { error: er }
});
export const pinToolFinished = () => ({
  type: PIN_TOOL_FINISHED,
  payload: null
});

export const getTool = toolId => async dispatch => {
  dispatch(getToolRequested());
  try {
    const { data: tool } = await api.getTool(toolId);
    tool.timeline = Array.isArray(tool.implementations)
      ? parseToolImplementations(tool)
      : [];
    dispatch(getToolSucceed(tool));
  } catch (error) {
    dispatch(getToolFailed(error.response.data));
  } finally {
    dispatch(getToolFinished());
  }
};

export const pinTool = toolId => async dispatch => {
  dispatch(pinToolRequested());
  try {
    await api.pinTool(toolId);
    dispatch(pinToolSucceed());
    dispatch(pinToolAtToolsReducer(toolId));
    dispatch(pinToolAtFeaturedToolsReducer(toolId));
  } catch (error) {
    dispatch(pinToolFailed(error.response.data));
    dispatch(pinToolFailedAtToolsReducer(error.response.data));
    dispatch(pinToolFailedAtFeaturedToolsReducer(error.response.data));
  } finally {
    dispatch(pinToolFinished());
  }
};
