import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string,
  downloadUrl: PropTypes.string,
  type: PropTypes.string.isRequired,
  typeId: PropTypes.number.isRequired
});
