import React from 'react';

import { Block, Button, Link } from 'lucentum';
import { LanguageMobile, Search, LoginMobile, CopyrightMobile } from '../';
import {
  BackgroundStyled,
  CloseStyled,
  MenuMobileStyled,
  TitleStyled,
  TopStyled,
  ImageStyled,
  FlexBlock,
  HeaderMobile
} from './styles';

export default ({
  activeSearch,
  children,
  currentLang,
  currentPage,
  language,
  languageSelectTestId,
  login,
  logoAlt,
  onChangeSearch,
  onClickSearch,
  onEnterChange,
  onHidden,
  onLanguage,
  onShow,
  showMenu,
  showSearch,
  ...props
}) => (
  <HeaderMobile hiddenLG>
    <TopStyled middle padding={0.7} justify>
      <FlexBlock flex={1} marginRight={0.5}>
        <Link to="#/">
          <ImageStyled src={logoAlt} big />
        </Link>
      </FlexBlock>
      <FlexBlock flex={2} width0of1 center>
        <TitleStyled>{currentPage}</TitleStyled>
      </FlexBlock>
      <Button onClick={onShow} primary icon="menu" />
    </TopStyled>
    <BackgroundStyled show={showMenu}>
      <MenuMobileStyled justify width4of5 paddingLeft={2} paddingTop={2}>
        <Block paddingRight column>
          <Block middle>
            <Block width11of12 middle right>
              {language && (
                <LanguageMobile
                  currentLang={currentLang}
                  hidden={activeSearch}
                  onLanguage={onLanguage}
                  primary
                  src={language}
                  testId={languageSelectTestId}
                />
              )}
              {showSearch && (
                <Search
                  activeSearch={activeSearch}
                  marginRight
                  onChangeSearch={onChangeSearch}
                  onClickSearch={onClickSearch}
                  onEnterChange={onEnterChange}
                />
              )}
            </Block>
            <Block right width1of12>
              <CloseStyled onClick={onHidden} primary icon="multiply" />
            </Block>
          </Block>
          <Block paddingTop paddingRight={3}>
            {children}
            <LoginMobile src={login} {...props} />
            <CopyrightMobile />
          </Block>
        </Block>
      </MenuMobileStyled>
    </BackgroundStyled>
  </HeaderMobile>
);
