import cookie from 'js-cookie';
import {
  SET_COOKIES_LAW_COOKIE_SUCCEED,
  SHOW_COOKIE_LAW_BANNER
} from './types';
import api from '../../utils/api';
import { DEFAULT_COOKIES_BANNER } from '../../utils/constants';


export const setCookieLawSucceed = () => ({
  type: SET_COOKIES_LAW_COOKIE_SUCCEED,
  payload: null
});



export const showCookieLawBanner = cookiesBanner => ({
  type: SHOW_COOKIE_LAW_BANNER,
  payload: {
    cookiesBanner
  }
});

export const loadCookieLawState = () => async (dispatch, getState) => {
  let cookieData = null;
  
  try {
    const { data } = await api.getCookiesBanner(
      getState().languages.activeLanguageCode.substr(0, 2)
    );
    if (!data) {
      cookieData = DEFAULT_COOKIES_BANNER;
    } else {
      cookieData = data.cookiesBanner;
    }
  } catch (error) {
    cookieData = DEFAULT_COOKIES_BANNER;
    
  } finally {
    if (cookie.get(cookieData.cookieName)) {
      dispatch(setCookieLawSucceed());
    } else {
      dispatch(showCookieLawBanner(cookieData));
    }
  }
 
};

export const setCookieLawCookie = () => (dispatch, getState) => {
  const { cookieName} =  getState().cookies;
   let  cookieValue = "read";
  const expireDate = new Date();


  expireDate.setDate(expireDate.getDate() +180);
  
  cookie.set(cookieName, cookieValue, {
    expires: expireDate
  });
  
  dispatch(setCookieLawSucceed());
  
};


export const setCookieLawCookieFalse = () => (dispatch, getState) => {
  const {cookieName} = getState().cookies;
  let  cookieValue  = "reject";
  const expireDate = new Date();
 
  expireDate.setDate(expireDate.getDate() +180);
   cookie.set(cookieName, cookieValue,{
    expires: expireDate
  });
  

  dispatch(setCookieLawSucceed());

};
