import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

export const buildMilestone = milestone =>
  milestone.projectId
    ? {
        type: 'Milestone',
        right: false,
        title: milestone.title,
        date: milestone.dates,
        text: milestone.descriptionOfProject
      }
    : {
        type: 'Milestone',
        right: true,
        title: milestone.office.title,
        date: moment(milestone.date).format('DD/MM/YYYY'),
        text: milestone.descriptionOfImplementation,
        moreInfo: {
          title: <FormattedMessage id="portlet.action.more_info" />,
          href:
            milestone.office.officeId &&
            `#/members/${milestone.office.officeId}`
        }
      };
