import styled from 'styled-components';
import { Block } from 'lucentum';

export const CoursesBlock = styled(Block)`
  flex-wrap: wrap;
`;

export const BlockLink = styled(Block)`
  align-items: baseline;
`;

export const CoursesWidget = styled(Block)`
  float: none;
  margin: 0;
  margin-right: 3em;
`;
