import styled from 'styled-components';

import { Block as B, Link as L, Icon as I, Button as Bu, P } from 'lucentum';

export const Block = styled(B)`
  position: relative;
  width: auto;
`;

export const Link = styled(L)`
  display: inline-block;
  white-space: nowrap;
  width: calc(33% - ${({ theme }) => theme.sizeSmall} / 3);
  &:hover {
    text-decoration: none;
  }
`;

export const Select = styled.select`
  appearance: none;
  background-color: ${({ theme }) => theme.colorTransparent};
  border: 0;
  color: ${({ theme }) => theme.colorWhite};
  position: relative;
`;

export const Icon = styled(I)`
  pointer-events: none;
  position: absolute;
  right: 0;
`;

export const Button = styled(Bu)`
  .language-chooser {
    min-width: 400px;
    padding: ${({ theme }) => theme.sizeSmall};
    top: 38px;
  }
`;

export const LanguageContainer = styled(P)`
  padding: ${({ theme }) => theme.sizeSmall};
  color: ${({ theme }) => theme.colorGray};
  box-sizing: border-box;
  border-left: 3px solid transparent;

  :hover {
    background-color: ${({ theme }) => theme.colorGrayAccent};
    border-left: 3px solid ${({ theme }) => theme.color};
  }
`;
