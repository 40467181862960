import { connect } from 'react-redux';
import { getOffices } from '../../redux/offices/action-creators';
import { getHero } from '../../redux/hero/action-creators';
import Members from './component';

export const mapStateToProps = state => ({
  offices: state.offices,
  hero: state.hero
});

export const mapDispatchToProps = dispatch => ({
  getOffices: () => dispatch(getOffices()),
  getHero: () => dispatch(getHero())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Members);
