import React from 'react';
import PropTypes from 'prop-types';
import { Content, Block, Image, Spinner, Button } from 'lucentum';
import { FormattedMessage } from 'react-intl';

import Timeline from '../timeline';
import ErrorMessage from '../error-message';
import AdvancedCard from '../advanced-card';
import HtmlDisplay from '../html-display';
import {
  ResponsiveTitle,
  BlockResponsive,
  BlockTimeline
} from '../../commonStyles';
import {
  Practice as PracticePropType,
  Reducer as ReducerPropType
} from '../../propTypes';

const hasSomeTimelineItems = timeline =>
  !!(Array.isArray(timeline) && timeline.length);

const shouldShowSpinner = querying => querying;

const shouldShowErrorMessage = (querying, error) => !querying && error;

const shouldShowPractice = (querying, practice) => !querying && practice;

const LogoOrTitle = ({ practice }) => (
  <Block hiddenLG>
    {practice.logo ? (
      <Image src={practice.logo} />
    ) : (
      <ResponsiveTitle>{practice.title}</ResponsiveTitle>
    )}
  </Block>
);

const Practice = ({ practice, querying, error }) => (
  <Content>
    {shouldShowSpinner(querying) && (
      <Block center>
        <Spinner show row size={10} />
      </Block>
    )}
    {shouldShowErrorMessage(querying, error) && <ErrorMessage />}
    {shouldShowPractice(querying, practice) && (
      <Block>
        <BlockResponsive paddingRight={2} justify bottom>
          <LogoOrTitle practice={practice} />
          <Block hiddenXS visibleLG marginBottom={2}>
            <ResponsiveTitle>{practice.title}</ResponsiveTitle>
          </Block>
          <Block right hiddenLG>
            <Button
              className="button-practice-dowload"
              onClick={() => window.open(practice.commonCommunicationSource)}
            >
              <FormattedMessage id="portlet.action.download" />
            </Button>
          </Block>
        </BlockResponsive>
        <Block>
          <BlockResponsive paddingRight={2} widthLG3of5>
            <HtmlDisplay>{practice.longDescription}</HtmlDisplay>
          </BlockResponsive>
          <BlockResponsive widthLG2of5 hiddenXS visibleLG>
            {practice.commonCommunicationSource && (
              <AdvancedCard
                id={practice.id}
                // title={practice.title}
                title={practice.commonCommunicationTitle}
                commonCommunicationSource={practice.commonCommunicationSource}
                className="heightAuto"
              />
            )}
            {practice.documents &&
              practice.documents.map(({ title, downloadUrl }, i) => (
                <AdvancedCard
                  downloadUrl={downloadUrl}
                  title={title}
                  key={`practice--document--${i}`}
                  id={i}
                  className="heightAuto"
                />
              ))}
            {Array.isArray(practice.videos) &&
              practice.videos.map((video, i) => (
                <Block marginTop={4} key={`tool--video--${i}`}>
                  <iframe
                    src={video.source}
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen
                    title={`${i}-${video.source}`}
                  />
                </Block>
              ))}
          </BlockResponsive>
          <BlockTimeline marginTop={2} marginBottom={2}>
            {hasSomeTimelineItems(practice.timeline) && (
              <Timeline
                seriesLeft={
                  <FormattedMessage id="portlet.action.timelines.projects" />
                }
                seriesRight={
                  <FormattedMessage id="portlet.text.timelines.implementations" />
                }
                src={practice.timeline}
              />
            )}
          </BlockTimeline>
        </Block>
      </Block>
    )}
  </Content>
);
Practice.propTypes = {
  ...ReducerPropType({ practices: PropTypes.arrayOf(PracticePropType) })
    .isRequired
};

export default Practice;
