import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import {
  StyledH2,
  StyledIcon,
  StyledLink,
  StyledButton,
  StyledBlockButton,
  StyledBlockIcon,
  StyledBlock
} from './styles';
import { Block } from 'lucentum';

export default ({ className, featuredNewsletter }) => (
  <Block className={className} padding>
    <Block>
      <StyledH2>
        <FormattedHTMLMessage
          id="portlet.newsletter.new"
          values={{ number: featuredNewsletter.title }}
        />
      </StyledH2>
    </Block>
    <StyledBlock widthSM1of2 right marginTop>
      <StyledBlockIcon widthSM1of2>
        <StyledIcon send />
      </StyledBlockIcon>
      <StyledBlockButton widthSM1of2>
        <StyledButton
          primary
          onClick={() => window.open(featuredNewsletter.downloadUrl)}
        >
          <FormattedMessage id="portlet.newsletter.download" />
        </StyledButton>
        <StyledLink to="#/newsletters">
          <FormattedMessage id="portlet.action.newsletter.show_all" />
        </StyledLink>
      </StyledBlockButton>
    </StyledBlock>
  </Block>
);
