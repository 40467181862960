import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavigationMenu } from './styles';

import Link from '../navlink';

const RegularLink = ({ page, active, type }) => (
  <Link
    to={`#/${page}`}
    active={active === `/${page}` && type !== 'vertical'}
    activeVertical={type === 'vertical' && active === `/${page}`}
    className="navigation--members"
  >
    <FormattedMessage id={`portlet.section.${page}` } />
  </Link>
);

export default ({ active, type, user }) => (
  <NavigationMenu >
    <Link
      to="#/"
      active={active === '/' && type !== 'vertical'}
      activeVertical={type === 'vertical' && active === '/'}
      hiddenXS
      className="navigation--home"
    >
      <FormattedMessage id="portlet.section.home" />
    </Link>
    <RegularLink page="ecps" active={active} type={type} />
    <RegularLink page="members" active={active} type={type} />
    <RegularLink page="tools" active={active} type={type} />
    <Link bold="true"
      to="#/practices"
      active={
        type !== 'vertical' &&
        ['/practices', '/common-practices'].includes(active)
      }
      activeVertical={
        type === 'vertical' &&
        ['/practices', '/common-practices'].includes(active)
      }
      className="navigation--practices"
    >
      <FormattedMessage id="portlet.section.practices" />
    </Link>
    <RegularLink page="events" active={active} type={type} />
    <RegularLink page="news" active={active} type={type} />
    <Link
        to="/network/international-cooperation"
        active={active === '/network/international-cooperation' && type !== 'vertical'}
        activeVertical={type === 'vertical' && active === '/network/international-cooperation'}
        className="navigation--international-cooperation"
    >
      <FormattedMessage id="menu.link.international-cooperation" />
    </Link>
    {user && (
      <Link
        to="/network/my-network"
        active={active === '/network/my-network' && type !== 'vertical'}
        activeVertical={type === 'vertical' && active === '/network/my-network'}
        className="navigation--my-network"
      >
        <FormattedMessage id="my-euipn.title" />
      </Link>
    )}
    {/*<RegularLink page="contact us" active={active} type={type} />*/}
    {/*{user && (*/}
      {/*<Link*/}
        {/*to="/network/my-network"*/}
        {/*active={active === '/network/my-network' && type !== 'vertical'}*/}
        {/*activeVertical={type === 'vertical' && active === '/network/my-network'}*/}
        {/*className="navigation--my-network"*/}
      {/*>*/}
        {/*My Network*/}
      {/*</Link>*/}
    {/*)}*/}
  </NavigationMenu>
);
