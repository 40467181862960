import PropTypes from 'prop-types';
import { Office as OfficePropType } from './';

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
  logo: PropTypes.string,
  url: PropTypes.string,
  contactEmail: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  offices: PropTypes.arrayOf(OfficePropType),
  projects: PropTypes.array,
  videos: PropTypes.array
});
