import {
  COMMON_PRACTICES_RECEIVED,
  COMMON_PRACTICES_REQUEST_FAILED,
  COMMON_PRACTICES_REQUEST_FINISHED,
  COMMON_PRACTICES_REQUESTED
} from './types';

const INITIAL_STATE = {
  querying: false,
  categories: null,
  error: null
};

const reducerMap = {
  [COMMON_PRACTICES_REQUESTED]: state => ({ ...state, querying: true }),
  [COMMON_PRACTICES_RECEIVED]: (state, action) => ({
    ...state,
    categories: action.payload.categories
  }),
  [COMMON_PRACTICES_REQUEST_FAILED]: (state, action) => ({
    ...state,
    error: action.payload.error
  }),
  [COMMON_PRACTICES_REQUEST_FINISHED]: state => ({ ...state, querying: false })
};

export default (state = INITIAL_STATE, action) =>
  reducerMap[action.type] ? reducerMap[action.type](state, action) : state;
