import styled from 'styled-components';
import { Modal } from 'lucentum';

export const ModalVideo = styled(Modal)`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizeSmaller};
  > div {
    border-bottom: none;
  }

  div:last-child button {
    display: none;
  }

  iframe {
    display: ${({ loadingVideo }) => (loadingVideo ? 'none' : 'block')};
  }
`;
