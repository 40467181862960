import React from 'react';
import { Link, Button, LanguageContainer } from './styles';
import { DEFAULT_LANGUAGE_TITLE } from '../../utils/constants';

export const upperCaseFirst = str =>
  str.substr(0, 1).toLocaleUpperCase() + str.substr(1);

export const buildTitle = (languages, currentLang, defaultLang) => {
  const foundLanguage = languages.find(x => x.code === currentLang);
  return foundLanguage ? foundLanguage.title : defaultLang;
};

export default ({
  src = [],
  onLanguage,
  currentLang = 'en_GB',
  languages = [{ code: 'en_GB', lang: 'English', title: 'English' }],
  ...props
}) => (
  <Button
    {...props}
    onDropdown={e => onLanguage && onLanguage(e)}
    title={upperCaseFirst(
      buildTitle(languages, currentLang, DEFAULT_LANGUAGE_TITLE)
    )}
    type="dropdown"
    className="language-chooser"
  >
    {src.map(({ lang, code, title }) => (
      <Link key={code} title={title} className={`lang-${code}`}>
        <LanguageContainer>
          {upperCaseFirst(lang).split('(')[0]}
        </LanguageContainer>
      </Link>
    ))}
  </Button>
);
