import { connect } from 'react-redux';

import Home from './component';
import { getHomeData } from '../../redux/home/action-creators';
import { pinTool } from '../../redux/tool/action-creators';
import { pinPractice } from '../../redux/practice/action-creators';
import { pinEcp } from '../../redux/ecp/action-creators';
import { subscribeNewsletter } from '../../redux/subscribeToNewsletter/action-creators';

export const mapStateToProps = state => ({
  hero: state.hero.hero,
  heroCarousel: state.heroCarousel.heroCarousel,
  featuredEvents: state.featuredEvents,
  featuredNews: state.featuredNews,
  featuredCourses: state.featuredCourses,
  featuredTools: state.featuredTools,
  featuredEcps: state.featuredEcps,
  members: state.members,
  featuredPractices: state.featuredPractices,
  tools: state.tools,
  ecps: state.ecps,
  subscribedNewsletter: state.subscribedNewsletter,
  userQuerying: state.user.querying,
  isUserLoged: !!state.user.object,
  gettingHomeData: state.homeData.querying,
  showLastNewsletter: state.homeData.showLastNewsletter,
  featuredNewsletter: state.featuredNewsletter
});

export const mapDispatchToProps = dispatch => ({
  getHomeData: () => dispatch(getHomeData()),
  pinTool: id => dispatch(pinTool(id)),
  pinEcp: id => dispatch(pinEcp(id)),
  pinPractice: id => dispatch(pinPractice(id)),
  handleSubscribeNewsletter: email => dispatch(subscribeNewsletter(email))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
