import Styled from 'styled-components';

import { Icon, Block } from 'lucentum';
import sizes from '../page/sizes.json';

export const calculateIconMaxWidth = currentFontSize => {
  const maxWidth = {
    'font.size.small': '100%',
    'font.size.medium': '150px',
    'font.size.large': '100px',
    'font.size.extralarge': '80px'
  };
  const size = sizes.find(({ size }) => size === currentFontSize);
  return size ? maxWidth[size.value] : maxWidth['font.size.medium'];
};

export const InputStyled = Styled.input`
    background-color: ${({ theme }) => theme.colorTransparent};
    border-color: ${({ theme }) => theme.colorTransparent};
    border-bottom: ${({ theme }) => `solid ${theme.line} ${theme.colorWhite}`};
    color: ${({ theme }) => theme.colorWhite}
    opacity: ${({ search }) => (search ? '1' : '0')};
    outline:0;
    transition: opacity, width 0.3s  ease-in-out;
    width: ${({ search }) => (search ? '100%' : '0%')};
`;

export const IconStyled = Styled(Icon)`
    color: ${({ theme }) => theme.colorWhite};
    cursor: pointer;
`;

export const StyledBlockSearch = Styled(Block)`
max-width: ${({ currentFontSize }) => calculateIconMaxWidth(currentFontSize)}
`;
