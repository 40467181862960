import moment from 'moment/moment';

const buildPeriod = year => ({ type: 'Period', label: year });

export const buildNotEuipoMilestone = milestone => ({
  type: 'Milestone',
  right: true,
  title: milestone.project.title,
  date: moment(milestone.date).format("DD/MM/YYYY"),
  ...(milestone.project && {
    ...(milestone.project.practice && {
      moreInfo: {
        title: 'More Info',
        href: `#/practices/${milestone.project.practice.practiceId}`
      }
    }),
    ...(milestone.project.tool && {
      moreInfo: {
        title: 'More Info',
        href: `#/tools/${milestone.project.tool.toolId}`
      }
    })
  })
});

export const getYearFrom = dateRange => dateRange.split('-')[0];

const orderByDate = (a, b) => getYearFrom(b.dates) - getYearFrom(a.dates);

const buildYears = office =>
  office.workingGroupParticipation.reduce((prev, item) => {
    const year = getYearFrom(item.dates);
    const period = buildPeriod(year);
    if (!prev.length) {
      prev = [period];
    } else if (prev[prev.length - 1].label > year) {
      prev = [...prev, period];
    }
    return prev;
  }, []);

export const parseNotEuipoImplementations = office => {
  office.workingGroupParticipation.sort(orderByDate);
  return buildYears(office).reduce((prev, item) => {
    const year = item.label;
    const workingGroupParticipations = office.workingGroupParticipation.filter(
      workingGroupParticipation =>
        getYearFrom(workingGroupParticipation.dates) === year
    );
    const milestones = workingGroupParticipations.map(
      workingGroupParticipation =>
        buildNotEuipoMilestone(workingGroupParticipation)
    );
    return [...prev, ...milestones, item];
  }, []);
};
